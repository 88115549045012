import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
	ProgressBar,
	DropdownButton,
	Dropdown,
	Row,
	Col,
} from 'react-bootstrap';

import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { TECHDROPDOWNITEMS } from '../../constants/AppConstants';
import Card from '../../common-components/Card/Card';
import DashboardService from '../../services/dashboardService';
import './Styles.css';

export function TopFive(props) {
	const { title, quarter, year } = props;
	const [viewMore, setViewMore] = useState(false);
	const [tech, setTech] = useState(TECHDROPDOWNITEMS[0]);
	const [dataList, setDataList] = useState({
		allList: [],
		topFive: [],
		noData: false,
	});
	const viewMoreHandler = () => {
		setViewMore(!viewMore);
	};

	const selectHandler = (key, event) => {
		setTech({ status: key, label: event.target.textContent });
	};

	const ifQuarter = {
		domain: tech?.label,
		quarter: {
			fromDate: quarter?.fromDate,
			tillDate: quarter?.tillDate,
		},
	};

	const ifYear = {
		domain: tech?.label,
		year: year?.year,
	};
	const DashboardServices = new DashboardService();
	const getEmployeesLeaderBoardInfoTechWise = async () => {
		try {
			const response =
				await DashboardServices.getEmployeesLeaderboardInfoTechwise(
					quarter.label.length ? ifQuarter : ifYear
				);
			const availableList = (response?.data || []).map((item) => ({
				_id: item.trainee_id,
				trainee_name: item.trainee_name,
				avgScore: item.avgScore === null ? 0 : Number(item.avgScore.toFixed(1)),
			}));

			setDataList((prevState) => ({
				...prevState,
				allList: availableList,
				topFive: availableList.slice(0, 5),
				noData: availableList.length === 0,
			}));
		} catch (error) {
			toast.error('Error fetching employees leaderboard info:', error);
		} finally {
			setViewMore(false);
		}
	};

	useEffect(() => {
		getEmployeesLeaderBoardInfoTechWise();
	}, [quarter, tech, tech?.label]);

	const mappingContent = (item, i) => (
		<div key={i}>
			<Row>
				<Col className="pt-1">
					<small>{item?.trainee_name}</small>
					<div className="m-0">
						<ProgressBar
							animated
							className=""
							variant="success"
							now={item?.avgScore}
							label={`${item?.avgScore}%`}
						/>
					</div>
				</Col>
			</Row>
		</div>
	);
	const viewMoreStyles = {
		width: '97.5%',
		height: '231px',
		overflow: 'scroll',
		overflowX: 'hidden',
	};
	const ViewByDefault = {
		width: '94.3%',
		height: '231px',
	};
	return (
		<Card className="w-100 topFiveCard">
			<div className="d-inline header d-flex justify-content-around">
				<div className="subHeader text-start">
					<h3 className="">{title}</h3>
				</div>
				<div style={{ width: '150px' }} className="mb-1">
					<DropdownButton
						onSelect={selectHandler}
						title={tech?.label}
						className="mt-1"
						// style={{ width: "150px" }}
					>
						{TECHDROPDOWNITEMS?.map((key, i) => (
							<Dropdown.Item eventKey={key.status} key={i}>
								{key.label}
							</Dropdown.Item>
						))}
					</DropdownButton>{' '}
				</div>
			</div>
			{!dataList?.noData ? (
				<div
					className="px-4 m-1"
					style={viewMore ? viewMoreStyles : ViewByDefault}
				>
					{viewMore
						? dataList.allList.map((item, i) => mappingContent(item, i))
						: dataList.topFive.map((item, i) => mappingContent(item, i))}
				</div>
			) : (
				<h6 className="text-center mt-5 mx-auto"> No Data Found </h6>
			)}

			{!dataList?.noData && (
				<div className="d-flex justify-content-end">
					<button className="viewMoreBtn btnStyle " onClick={viewMoreHandler}>
						{!viewMore ? (
							<>
								View More
								<BiChevronDown
									className="viewMoreBtnIcon"
									width="20"
									height="30"
								/>
							</>
						) : (
							<>
								View Less
								<BiChevronUp
									className="viewMoreBtnIcon"
									width="20"
									height="30"
								/>
							</>
						)}
					</button>
				</div>
			)}
		</Card>
	);
}
