/* eslint-disable no-unused-expressions */
/* eslint-disable no-lonely-if */
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import ReactDatePicker from 'react-datepicker';
import { FaCalendarAlt, FaTrash } from 'react-icons/fa';
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router';
import BatchService from '../../services/batchService';
import EmployeeServices from '../../services/employeeService';
import AssessmentService from '../../services/assessmentService';
import ProgramServices from '../../services/programServices';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import { ASSESSMENTS, BUTTONS, userRoles } from '../../constants/AppConstants';
import Card from '../../common-components/Card/Card';
import { formDataConversion, mapData } from '../../utils/helper';
import './Styles.css';

export const AssessmentForm = ({ userRole }) => {
	const [breadCrumbBarState, setBreadCrumbBarState] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [selectedAssessmentId, setSelectedAssessmentId] = useState('');
	const [invalidLink, setInvalidLink] = useState();
	const [options, setOptions] = useState({
		programsOptions: [],
		employeeOptions: [],
		adminBatchList: [],
		trainerBatchList: [],
		assessmentOptions: ['By Weekly', 'Final'],
	});
	const [formState, setFormState] = useState({
		name: {
			value: '',
			error: false,
			errorMsg: '',
		},
		details: {
			value: '',
			error: false,
			errorMsg: '',
		},
		createdBy: {
			value: '',
			error: false,
			errorMsg: '',
		},
		topics: {
			value: '',
			error: false,
			errorMsg: '',
		},
		type: {
			value: '',
			error: false,
			errorMsg: '',
		},
		programId: {
			value: '',
			error: false,
			errorMsg: '',
		},
		batchId: {
			value: '',
			error: false,
			errorMsg: '',
		},
		deadline: {
			value: '',
			error: false,
			errorMsg: '',
		},
		startDate: {
			value: '',
			error: false,
			errorMsg: '',
		},
		references: [
			{
				value: [],
				error: false,
				errorMsg: '',
			},
		],
		fileUpload: {
			value: [],
			error: false,
			errorMsg: '',
		},
	});
	const location = useLocation();
	const navigate = useNavigate();
	const programService = new ProgramServices();
	const batchService = new BatchService();
	const employeeService = new EmployeeServices();
	const assessmentService = new AssessmentService();

	const getAllPrograms = () => programService.getAllPrograms();
	const getAllEmployees = () => employeeService.getAllEmployees();
	const getAllBatchesForAdmin = () => batchService.getAllBatches();
	const [ReferenceAndFileErrorMsg, setReferenceAndFileErrorMsg] =
		useState(false);
	const fetchData = async () => {
		try {
			const [programsResponse, employeesResponse, adminBatchesResponse] =
				await Promise.all([
					getAllPrograms(),
					getAllEmployees(),
					userRoles.admin === userRole && getAllBatchesForAdmin(),
				]);
			const formattedPrograms = programsResponse.data?.length
				? mapData(programsResponse.data)
				: [];
			const employeeOptions = employeesResponse.data?.length
				? mapData(employeesResponse.data)
				: [];
			const adminBatchList = adminBatchesResponse.data?.length
				? mapData(adminBatchesResponse.data)
				: [];
			setOptions((prevOptions) => ({
				...prevOptions,
				programsOptions: formattedPrograms,
				employeeOptions: employeeOptions,
				adminBatchList: adminBatchList,
			}));
		} catch (error) {
			toast.error('Error:', error?.details);
		}
	};

	const getAllBatchesForTrainer = async () => {
		try {
			const userId = localStorage.getItem('userId');
			const response = await assessmentService.getAllTrainerBatches(userId);
			const responseData = response.data;
			setOptions((prevOptions) => ({
				...prevOptions,
				trainerBatchList: responseData?.length ? mapData(responseData) : [],
			}));
		} catch (error) {
			toast.error('Error:', error?.details);
		}
	};

	useEffect(() => {
		fetchData();
		if (userRoles.trainer === userRole) {
			getAllBatchesForTrainer();
		}
	}, []);

	const updateField = (name, key, value) => {
		const copy = { ...formState[key] };
		const error = !value?.toString()?.length;
		const errorMsg = value?.toString()?.length ? '' : `${name} is required`;
		return { ...copy, value, error, errorMsg };
	};

	const handleChange = (event) => {
		const { id, value, name } = event.target;
		setFormState((prevState) => ({
			...prevState,
			[id]: updateField(name, id, value),
		}));
	};
	const uploadHandler = (event) => {
		const { id, name } = event.target;
		const file = event.target.files[0];
		const value = [{ file, fileName: file.name }];
		setFormState((prev) => ({
			...prev,
			fileUpload: updateField(name, id, value),
		}));
		!file
			? setReferenceAndFileErrorMsg(true)
			: setReferenceAndFileErrorMsg(false);
	};
	const inputRef = useRef();
	const resetFileInput = (event) => {
		const { id, name } = event.target;
		inputRef.current.value = null;
		setFormState((prev) => ({
			...prev,
			fileUpload: updateField(name, id, []),
		}));
		!formState?.references[0]?.value?.length
			? setReferenceAndFileErrorMsg(true)
			: '';
	};
	// ONCHANGE START DATES AND END DATES
	const dateHandler = (name, dateType, date) => {
		switch (dateType) {
			case 'deadline': {
				setFormState((prevState) => ({
					...prevState,
					deadline: updateField('Deadline', 'deadline', date),
				}));
				break;
			}
			case 'startDate': {
				setFormState((prevState) => ({
					...prevState,
					startDate: updateField('Start Date', 'startDate', date),
				}));
				break;
			}
			default:
				break;
		}
	};

	const referencesHandler = (index, value) => {
		const updatedReferences = [...formState.references];
		updatedReferences[index] = {
			value: value,
			error: false,
			errorMsg: '',
		};
		const updatedFormState = {
			...formState,
			references: updatedReferences,
		};
		setFormState(updatedFormState);
		!value?.length && !formState.fileUpload.value.length
			? setReferenceAndFileErrorMsg(true)
			: setReferenceAndFileErrorMsg(false);
	};

	const addReference = () => {
		const updatedReferences = [...formState.references];
		updatedReferences.push({
			value: '',
			error: false,
			errorMsg: '',
		});
		setFormState((prevState) => ({
			...prevState,
			references: updatedReferences,
		}));
	};

	const removeSelectedReference = (index) => {
		const updatedReference = [...formState.references];
		updatedReference.splice(index, 1);
		setFormState((prevState) => ({
			...prevState,
			references: updatedReference,
		}));
	};

	const onValidation = () => {
		let isValid = true;
		const updatedFormState = { ...formState };
		// Validate
		Object.keys(updatedFormState).forEach((key) => {
			// Exclude specific keys
			if (key !== 'references' && key !== 'fileUpload') {
				updatedFormState[key] = updateField(
					ASSESSMENTS?.addAssessmentForm[key],
					key,
					updatedFormState[key].value
				);
				if (updatedFormState[key]?.error) isValid = false;
			} else {
				const { references, fileUpload } = formState;
				if (!references?.[0]?.value?.length && !fileUpload?.value?.length) {
					setReferenceAndFileErrorMsg(true);
					isValid = false;
				} else {
					const x = references?.map((el) => {
						if (el.value.length) {
							try {
								/* eslint-disable no-new */
								new URL(el.value);
								isValid = true;
							} catch (error) {
								isValid = false;
								return el;
							}
						}
						return isValid;
					});
					setInvalidLink(x);
				}
			}
		});
		setFormState(updatedFormState);
		return isValid;
	};

	const generateInitialFormState = (selectedAssessment) => {
		const updatedFormState = { ...formState };
		Object.keys(selectedAssessment).forEach((key) => {
			if (updatedFormState[key]) {
				if (key === 'references' && selectedAssessment[key]?.length !== 0) {
					updatedFormState[key] = selectedAssessment[key].map((link) => ({
						value: link,
						error: false,
						errorMsg: '',
					}));
				} else {
					updatedFormState[key].value = selectedAssessment[key];
				}
			}
		});
		return updatedFormState;
	};

	useEffect(() => {
		if (location.pathname !== '/assessments/addAssessment') {
			setIsEdit(true);
			setBreadCrumbBarState([
				{ name: 'Assessments', route: '/assessments', isLink: true },
				{ name: 'Edit Assessment', route: '', isLink: false },
			]);
			const selectedAssessment = location.state;
			selectedAssessment.fileUpload = selectedAssessment?.fileName
				? [
						{
							file: selectedAssessment.fileUploadUrl,
							fileName: selectedAssessment.fileName,
						},
				  ]
				: [];
			setSelectedAssessmentId(selectedAssessment?._id);
			setFormState(
				generateInitialFormState({
					...selectedAssessment,
				})
			);
		} else {
			setIsEdit(false);
			setBreadCrumbBarState([
				{ name: 'Assessments', route: '/assessments', isLink: true },
				{ name: 'Add Assessment', route: '', isLink: false },
			]);
		}
	}, []);

	const onSubmit = () => {
		const isValid = onValidation();
		const formattedReferences = Array?.from(
			formState?.references,
			(obj) => obj?.value
		);
		const data = {
			name: formState?.name?.value,
			details: formState?.details?.value,
			createdBy: formState?.createdBy?.value,
			topics: formState?.topics?.value,
			type: formState?.type?.value,
			programId: formState?.programId?.value,
			batchId: formState?.batchId?.value,
			startDate: formState?.startDate?.value,
			deadline: formState?.deadline?.value,
			references: !formattedReferences?.length ? [] : formattedReferences,
			file: formState?.fileUpload?.value[0]
				? formState?.fileUpload?.value[0]?.file
				: '',
			fileName: formState?.fileUpload?.value[0]
				? formState?.fileUpload?.value[0]?.fileName
				: '',
		};
		const assessment = formDataConversion(data);
		if (isValid) {
			if (isEdit) {
				assessmentService
					.updateAssessment(assessment, selectedAssessmentId)
					.then((res) => {
						toast.success('The Assessment is Updated Successfully');
						navigate('/assessments');
					})
					.catch((err) => toast.error(err?.details));
			} else {
				assessmentService
					.createAssessment(assessment, formState?.createdBy?.value)
					.then((res) => {
						toast.success(res?.message);
						navigate('/assessments');
					})
					.catch((err) => {
						toast.error(err?.details);
					});
			}
		}
	};

	return (
		<>
			<BreadCrumb data={breadCrumbBarState} />
			<h4 className="headerStyle ms-1">
				{isEdit
					? `${ASSESSMENTS.addAssessmentForm.editAssessment}`
					: `${ASSESSMENTS.addAssessmentForm.addAssessment}`}
			</h4>
			<Card className="py-4 mb-2 px-4">
				<div className="col-12 row">
					<div className="col-12">
						<label className="inputLabel">
							{ASSESSMENTS.addAssessmentForm.name} {''}
							<span className="error">*</span>
						</label>
						<input
							type="text"
							className={`styled-input ${
								formState?.name?.error ? 'error' : ''
							}`}
							placeholder="Enter Name"
							name="Assessment Name"
							id="name"
							value={formState.name.value}
							required
							onChange={handleChange}
						/>
						<p className="errorText">
							{formState.name.error ? formState.name.errorMsg : ''}
						</p>
					</div>
					<div className="col-12 mb-5">
						<label className="inputLabel">
							{ASSESSMENTS.addAssessmentForm.details} {''}
							<span className="error">*</span>
						</label>
						<textarea
							type="text"
							className={`styled-input ${
								formState?.details?.error ? 'error h-100' : 'h-100'
							}`}
							placeholder="Enter Description"
							name="Details"
							id="details"
							value={formState.details.value}
							required
							onChange={handleChange}
						/>
						<p className="errorText">
							{formState.details.error ? formState.details.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-3">
						<label className="inputLabel">
							{ASSESSMENTS.addAssessmentForm.createdBy}
							<span className="error">*</span>
						</label>
						<select
							className={`styled-input ${
								formState?.createdBy?.error
									? 'error form-select'
									: 'form-select'
							}`}
							name="CreatedBy"
							id="createdBy"
							value={formState.createdBy.value}
							onChange={handleChange}
						>
							<>
								<option disabled value={''}>
									Select
								</option>
								{options?.employeeOptions?.length &&
									options?.employeeOptions?.map((data, index) => (
										<option value={data._id} key={index}>
											{data?.name}
										</option>
									))}
							</>
						</select>
						<p className="errorText">
							{formState.createdBy.error ? formState.createdBy.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{ASSESSMENTS.addAssessmentForm.topics} {''}
							<span className="error">*</span>
						</label>
						<input
							type="text"
							className={`styled-input ${
								formState?.topics?.error ? 'error form-select' : 'form-select'
							}`}
							placeholder="Enter Topics"
							name="Topics"
							id="topics"
							value={formState.topics.value}
							required
							onChange={handleChange}
						/>
						<p className="errorText">
							{formState.topics.error ? formState.topics.errorMsg : ''}
						</p>
					</div>
					<div className="col-12 mb-2">
						<label className="inputLabel">
							{ASSESSMENTS.addAssessmentForm.references} {''}
							<span className="error">*</span>
						</label>
						{formState?.references?.map((field, index) => (
							<div className="row" key={index}>
								<div className="col-11 mt-1">
									<input
										type="text"
										className={`styled-input ${
											ReferenceAndFileErrorMsg ? 'error' : ''
										}`}
										placeholder="Enter Reference Links"
										value={field?.value}
										onChange={(e) => referencesHandler(index, e.target.value)}
									/>
								</div>
								<div className="col-1 d-flex justify-content-center align-items-center">
									{(formState?.references?.length === 1 ||
										formState.references.length - 1 === index) && (
										<AiFillPlusCircle
											onClick={addReference}
											className="plusIconStyle"
										/>
									)}
									{formState.references.length - 1 !== index && (
										<AiFillMinusCircle
											onClick={() => removeSelectedReference(index)}
											className="minusIconStyle"
										/>
									)}
								</div>
								{invalidLink?.map((el, i) =>
									el?.value === field?.value ? (
										<p key={i} className="error">
											{'Please provide valid link'}
										</p>
									) : (
										''
									)
								)}
							</div>
						))}
						<div className="col-6 mt-3">
							<div className="d-flex mb-1">
								<input
									className={'chooseFile'}
									type="file"
									ref={inputRef}
									name="FileUpload"
									id="fileUpload"
									accept=".pdf, .png, .docx, .doc"
									onChange={uploadHandler}
								></input>
								<input
									placeholder="Upload File"
									className={`styled-input uploadInputField ${
										ReferenceAndFileErrorMsg
											? 'error form-select'
											: 'form-select'
									}`}
									type="text"
									disabled
									value={formState?.fileUpload?.value[0]?.fileName || ''}
									autoComplete="off"
								></input>
								<FaTrash
									name="FileUpload"
									id="fileUpload"
									className="delete-file_icon"
									onClick={resetFileInput}
								/>
							</div>
						</div>
						<p className="errorText">
							{ReferenceAndFileErrorMsg
								? 'Either References Or File is required'
								: ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{ASSESSMENTS.addAssessmentForm.type} {''}
							<span className="error">*</span>
						</label>
						<select
							className={`styled-input ${
								formState?.type?.error ? 'error form-select' : 'form-select'
							}`}
							id="type"
							name="Type"
							value={formState.type?.value}
							onChange={handleChange}
						>
							<>
								<option disabled value={''}>
									Select
								</option>
								{options?.assessmentOptions.map((data, index) => (
									<option value={data} key={index}>
										{data}
									</option>
								))}
							</>
						</select>
						<p className="errorText">
							{formState.type.error ? formState.type.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-3">
						<label className="inputLabel">
							{ASSESSMENTS.addAssessmentForm.programId}
							<span className="error">*</span>
						</label>
						<select
							className={`styled-input ${
								formState?.programId?.error
									? 'error form-select'
									: 'form-select'
							}`}
							id="programId"
							name="Course"
							value={formState.programId.value}
							onChange={handleChange}
						>
							<>
								<option disabled value={''}>
									Select
								</option>
								{options?.programsOptions?.length &&
									options?.programsOptions?.map((data, index) => (
										<option value={data?._id} key={index}>
											{data?.name}
										</option>
									))}
							</>
						</select>
						<p className="errorText">
							{formState.programId.error ? formState.programId.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-3">
						<label className="inputLabel">
							{ASSESSMENTS.addAssessmentForm.batchId}
							<span className="error">*</span>
						</label>
						<select
							className={`styled-input ${
								formState?.batchId?.error ? 'error form-select' : 'form-select'
							}`}
							id="batchId"
							name="Batch"
							value={formState.batchId.value}
							onChange={handleChange}
						>
							<>
								<option disabled value={''}>
									Select
								</option>
								{(userRoles.trainer === userRole
									? options?.trainerBatchList
									: options?.adminBatchList
								)?.map((data, index) => (
									<option value={data._id} key={index}>
										{data?.name}
									</option>
								))}
							</>
						</select>
						<p className="errorText">
							{formState.batchId.error ? formState.batchId.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{ASSESSMENTS.addAssessmentForm.startDate}
							<span className="error">*</span>
						</label>
						<div className="date-picker-container">
							<ReactDatePicker
								placeholderText="Start Date"
								className={`styled-input  session-date ${
									formState?.startDate?.error ? 'error' : ''
								}`}
								name="Start Date"
								selected={
									formState?.startDate?.value
										? new Date(formState.startDate.value)
										: null
								}
								onChange={(date) =>
									dateHandler('Start Date', 'startDate', date)
								}
								dateFormat="yyyy-MM-dd"
								required
								id="startDate"
								autoComplete="off"
							/>
							<FaCalendarAlt className="calendar-icon" />
						</div>
						<p className="errorText">
							{formState.startDate.error ? formState.startDate.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{ASSESSMENTS.addAssessmentForm.deadline} {''}
							<span className="error">*</span>
						</label>
						<div className="date-picker-container">
							<ReactDatePicker
								placeholderText="Deadline"
								name="Deadline"
								className={`styled-input  session-date ${
									formState?.deadline?.error ? 'error' : ''
								}`}
								selected={
									formState?.deadline?.value
										? new Date(formState.deadline.value)
										: null
								}
								onChange={(date) => dateHandler('Deadline', 'deadline', date)}
								dateFormat="yyyy-MM-dd"
								required
								id="deadline"
								autoComplete="off"
							/>
							<FaCalendarAlt className="calendar-icon" />
						</div>
						<p className="errorText">
							{formState.deadline.error ? formState.deadline.errorMsg : ''}
						</p>
					</div>
				</div>
			</Card>
			<Card className="position-sticky bottom-0">
				<div className="d-flex justify-content-center p-2">
					<button
						type="button"
						className="positiveButton btnStyle"
						onClick={onSubmit}
					>
						{BUTTONS.save}
					</button>
					<button
						type="button"
						className="btnStyle negativeButton ms-4"
						onClick={() => navigate('/assessments')}
					>
						{BUTTONS.cancel}
					</button>
				</div>
			</Card>
		</>
	);
};
