import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { BatchEmployeesTable } from './BatchEmployeesTable';
import { BatchDescription } from './BatchDescription';
import { BreadCrumb } from '../../../common-components/BreadCrumb/BreadCrumb';
import BatchService from '../../../services/batchService';
import AssessmentService from '../../../services/assessmentService';
import BatchAssessmentsProgress from './BatchAssessmentsProgress';
import { userRoles } from '../../../constants/AppConstants';

export const BatchDetailContainer = () => {
	const [state, setState] = useState([]);
	const [traineeDetails, setTraineeDetails] = useState([]);
	const [selectedBatch, setSelectedBatch] = useState();
	const [assessmentPerBatchId, setAssessmentPerBatchId] = useState();
	const [userRole, setUserRole] = useState(localStorage.getItem('role'));
	const location = useLocation();
	const navigate = useNavigate();
	const batchId = location?.state;

	const format = (responseData) => ({
		...responseData,
		traineeIds: responseData.traineeIds.map((item) => ({
			...item,
			doj: moment(item.doj).format('DD-MM-YYYY'),
		})),
	});
	const fetchBatchById = async (id) => {
		try {
			const batchesObj = new BatchService();
			const response = await batchesObj.getBatchById(id);
			const responseData = response.data;
			const formattedData = format(responseData);
			setSelectedBatch(formattedData);
		} catch (error) {
			toast.error('Error fetching batch by ID:', error);
		}
	};

	const fetchAssessmentReportsById = async (id) => {
		try {
			if (![userRoles.employee].includes(userRole)) {
				const assessmentObj = new AssessmentService();
				const response = await assessmentObj.getAllAssessmentReportsById(id);
				const { data } = response;
				const originaldata = data.map((item) => ({
					assessment_name: item.name ? item.name : '',
					date: item.startDate
						? moment(item.startDate).format('DD-MM-YYYY')
						: '',
					EndDate: item.deadline
						? moment(item.deadline).format('DD-MM-YYYY')
						: '',
					marks: item.average ? item.average.toFixed(2) : '0',
				}));
				setAssessmentPerBatchId(originaldata.length ? originaldata : []);
			}
		} catch (error) {
			toast.error('Error fetching assessment reports by ID:', error);
		}
	};
	const getTraineeDetailsByBatchId = (selectedBatchId) => {
		const batchesObj = new BatchService();
		batchesObj
			.getTraineeDetailsByBatchId(selectedBatchId)
			.then((res) => {
				if (res.statusCode === 200 && res?.data?.length) {
					setTraineeDetails([...res.data.map((trainee) => trainee.traineeIds)]);
				}
			})
			.catch((error) => {
				toast.error(error?.message ? error.message : error);
			});
	};
	useEffect(() => {
		fetchBatchById(batchId);
		fetchAssessmentReportsById(batchId);
		if (batchId) {
			getTraineeDetailsByBatchId(batchId);
		}
		setUserRole(localStorage.getItem('role'));
	}, [batchId]);

	useEffect(() => {
		setState([
			{
				name: 'Batches',
				route: '/batches',
				isLink: true,
				id: 1,
			},
			{
				name: selectedBatch?.name,
				route: `/batches/${selectedBatch?.name}`,
				isLink: false,
				id: 2,
			},
		]);
	}, [selectedBatch?.name]);

	const cellClickedListener = (event) => {
		if (event.value) {
			const path = `/employees/employeeProfile/${event.data?._id}`;
			navigate(path, { state: event.data });
		}
	};

	const columns = [
		{
			field: 'name',
			headerName: 'Name',
		},
		{
			field: 'fissionId',
			headerName: 'FL Id',
		},
		{
			field: 'emailId',
			headerName: 'Email Id',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'doj',
			headerName: 'Joining Date',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>{moment(params?.value).format('DD MMM YYYY')}</>
			),
		},
		{
			field: 'experience',
			headerName: 'Experience',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => <>{`${params.value} Years`}</>,
		},
	];

	return (
		<div>
			<BreadCrumb data={state} />
			{/* <h5 className="text-start descriptionMainHeader mb-2 mt-3 ms-1">
				{selectedBatch?.name}
			</h5> */}
			<BatchDescription selectedBatch={selectedBatch || 'No Data Available'} />
			<BatchEmployeesTable
				columnNames={columns}
				employeesList={traineeDetails?.length ? traineeDetails : []}
				category={'Trainee List'}
				cellClickedListener={
					[userRoles.manager, userRoles.admin].includes(userRole) &&
					cellClickedListener
				}
			></BatchEmployeesTable>
			{![userRoles.employee].includes(userRole) ? (
				<BatchAssessmentsProgress assessmentPerBatchId={assessmentPerBatchId} />
			) : (
				''
			)}
		</div>
	);
};
