import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Card from '../../common-components/Card/Card';
import { EMPLOYEESSPECTRUMDROPDOWNITEMS } from '../../constants/AppConstants';
import { DonutChart } from '../../common-components/DonutChart/DonutChart';
import DataTable from '../../common-components/DataTable/DataTable';
import DashboardService from '../../services/dashboardService';
import { formatterForDashboard } from '../../utils/helper';

export function BatchSpectrum(props) {
	const { quarter, year } = props;
	const [pieChart, setPieChart] = useState({
		names: [],
		percentages: [],
		res: [],
		traineeIds: [],
		progressdetails: [],
	});
	const [batchesStatus, setBatchesStatus] = useState(
		Object.values(EMPLOYEESSPECTRUMDROPDOWNITEMS)[0]
	);
	const selectHandler = (key, event) => {
		setBatchesStatus({
			status: key,
			label: event.target.textContent,
		});
	};

	const columns = [
		{
			field: 'batchName',
			headerName: 'Name',
			// cellStyle: { textAlign: "center" },
		},
		{
			field: 'totalTrainee',
			headerName: 'Employees',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'progressdetails',
			headerName: 'Progress',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>{params.value ? `${params.value} % ` : ''} </>
			),
		},
	];
	const DashboardServices = new DashboardService();
	const ifYear = {
		status: batchesStatus?.status,
		year: year?.year,
	};
	const ifQuarter = {
		status: batchesStatus?.status,
		quarter: {
			fromDate: quarter?.fromDate,
			tillDate: quarter?.tillDate,
		},
	};

	const getBatchDetailsByStatus = async () => {
		try {
			const response = await DashboardServices.getBatchesByStatus(
				quarter.label.length ? ifQuarter : ifYear
			);
			const responseData = response?.data || [];
			const [
				namesByBatchesStatus,
				progressDetailsListByBatchesStatus,
				percentagesByBatchesStatus,
				traineeIdsByBatchesStatus,
			] = formatterForDashboard(
				responseData,
				'batchName',
				'progressdetails',
				'traineePercentage',
				'totalTrainee'
			);
			setPieChart({
				res: responseData,
				names: namesByBatchesStatus || [],
				percentages: percentagesByBatchesStatus || [],
				traineeIds: traineeIdsByBatchesStatus || [],
				progressdetails: progressDetailsListByBatchesStatus || [],
			});
		} catch (error) {
			toast.error('Error fetching batch details by status:', error);
		}
	};

	useEffect(() => {
		getBatchDetailsByStatus();
	}, [quarter?.label, batchesStatus?.status, year?.year]);

	const batchSpectrumLegend = {
		show: false,
	};
	return (
		<Card className="w-100 h-50">
			<div className="mb-1 d-inline header d-flex justify-content-between">
				<div className="subHeader text-start">
					<h3 className="">Batches Spectrum</h3>
				</div>
				<DropdownButton
					onSelect={selectHandler}
					title={batchesStatus?.label || batchesStatus}
					style={{ width: '150px' }}
					className="mt-2"
				>
					{EMPLOYEESSPECTRUMDROPDOWNITEMS.map((key, i) => (
						<Dropdown.Item eventKey={key.status} key={i}>
							{key.label}
						</Dropdown.Item>
					))}
				</DropdownButton>
			</div>
			<div className="row">
				<div className="col-6">
					<DonutChart
						names={pieChart?.names?.length ? pieChart.names : []}
						percentages={
							pieChart?.percentages?.length ? pieChart.percentages : []
						}
						type="donut"
						traineeIds={pieChart?.traineeIds?.length ? pieChart.traineeIds : []}
						progressDetailsList={
							pieChart?.progressdetails?.length ? pieChart.progressdetails : []
						}
						donutChartLegend={batchSpectrumLegend}
						title={'Batch Progress : '}
					/>
				</div>
				<div className="col-6">
					<DataTable
						height={240}
						columns={columns}
						rows={pieChart?.res?.length ? pieChart.res : []}
						pagination={false}
						customPagination={false}
					/>
				</div>
			</div>
		</Card>
	);
}
