import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { googleLogout } from '@react-oauth/google';
import Header from '../common-components/header/Header';
import { Sidebar } from '../common-components/sidebar/Sidebar';
import UserContext from '../context/userContext';

export const AuthenticatedLayout = ({ children }) => {
	const { userLoggedIn, setUserLoggedIn, userRole, setUserRole } =
		useContext(UserContext);
	const navigate = useNavigate();
	useEffect(() => {
		if (!JSON.parse(localStorage.getItem('loginnormal'))) {
			if (!JSON.parse(localStorage.getItem('accessToken'))) {
				setUserLoggedIn(false);
				setUserRole('');
				localStorage.clear();
				googleLogout();
				navigate('/');
			}
		}
	}, [userLoggedIn, setUserLoggedIn, navigate, setUserRole]);

	return (
		<>
			<Header />
			<div>
				<div className="col-12 row m-0">
					<div className="col-2 p-0 left-section">
						<Sidebar userProps={userRole} />
					</div>
					<div className="col-10 body-container">
						<div className="px-2 justify-content-center">{children}</div>
					</div>
				</div>
			</div>
		</>
	);
};
