import React from 'react';
import Card from '../../common-components/Card/Card';
import { DonutChart } from '../../common-components/DonutChart/DonutChart';

export function EmployeeSpectrumForYear(props) {
	const { names, percentages, totalTrainee } = props;
	const employeeSpectrumLegend = {
		show: true,
		position: 'right',
		fontWeight: '400',
	};

	return (
		<Card className="w-100 h-100 ">
			<div className="subHeader text-start">
				<h3 className="">Employees Spectrum</h3>
			</div>
			<DonutChart
				names={names?.length ? names : []}
				percentages={percentages?.length ? percentages : []}
				type="donut"
				traineeIds={totalTrainee}
				donutChartLegend={employeeSpectrumLegend}
				isEmployeeSpectrumTooltipContentTrue={true}
				title={'Expertise Percentage : '}
			/>
		</Card>
	);
}
