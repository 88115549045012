import React from 'react';
import ReactApexChart from 'react-apexcharts';

export function BarChart(props) {
	const {
		names,
		seriesData,
		height,
		isDashboard,
		addPercentageForLabel,
		barWidth,
	} = props;
	const DashboardBarChart = {
		series: seriesData,
		options: {
			chart: {
				type: 'bar',
				height: 350,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: barWidth,
					distributed: true,
					// borderRadius: 4,
					dataLabels: {
						position: 'top',
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter: function (val) {
					return addPercentageForLabel ? `${val}%` : val;
				},
				offsetY: -20,
				style: {
					fontSize: '13px',
					colors: ['#000'],
				},
			},
			fill: {
				type: 'gradient',
				colors: ['#c2d2ef'],
				gradient: {
					shade: 'dark',
					type: 'horizontal',
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: [],
				},
			},
			xaxis: {
				categories: names,
				position: 'bottom',
				lines: {
					show: false,
				},
				axisBorder: {
					show: true,
				},
				axisTicks: {
					show: false,
				},
				crosshairs: {
					fill: {
						type: 'gradient',
						gradient: {
							stops: [0, 100],
							opacityFrom: 0.4,
							opacityTo: 0.5,
						},
					},
				},
				tooltip: {
					enabled: false,
				},
			},
			yaxis: {
				axisBorder: {
					show: false,
				},
				lines: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					show: true,
					formatter: function (val) {
						return addPercentageForLabel ? `${val}%` : val;
					},
				},
			},
			animations: {
				enabled: true,
			},
			stroke: {
				colors: ['transparent'],
				width: 3,
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: 'bottom',
						},
						screenX: {
							scroll: true,
						},
					},
				},
			],
			grid: {
				margin: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 20,
				},
			},
		},
	};

	const barChart = {
		series: seriesData,
		options: {
			chart: {
				type: 'bar',
				height: 350,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '45%',
					endingShape: 'rounded',
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ['transparent'],
			},
			xaxis: {
				categories: names,
				position: 'bottom',
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				crosshairs: {
					fill: {
						type: 'gradient',
						gradient: {
							stops: [0, 100],
							opacityFrom: 0.4,
							opacityTo: 0.5,
						},
					},
				},
			},
			grid: {
				margin: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 20,
				},
			},
			yaxis: {
				labels: {
					show: true,
					formatter: function (val) {
						return `${val}%`;
					},
				},
			},
			fill: {
				opacity: 1,
			},
			tooltip: {},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: 'bottom',
						},
						screenX: {
							scroll: true,
						},
					},
				},
			],
			legend: {
				show: true,
				position: 'top',
				showForSingleSeries: true,
				onItemClick: {
					toggleDataSeries: true,
				},
				onItemHover: {
					highlightDataSeries: true,
				},
			},
		},
	};

	return (
		<>
			<div id="chart p-3">
				<ReactApexChart
					options={isDashboard ? DashboardBarChart?.options : barChart?.options}
					series={isDashboard ? DashboardBarChart?.series : barChart?.series}
					type="bar"
					height={height || 350}
				/>
			</div>
		</>
	);
}
