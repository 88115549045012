import React, { useEffect, useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { useLocation } from 'react-router';
import moment from 'moment';
import { Profile } from '../../common-components/Profile/Profile';
import { Skills } from '../../common-components/skills/Skills';
import {
	EMPLOYEE,
	DROPDOWNITEMSFORTRAININGS,
} from '../../constants/AppConstants';
import DataTable from '../../common-components/DataTable/DataTable';
import EmployeeServices from '../../services/employeeService';
import TrainerServices from '../../services/trainerService';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import './Styles.css';

const TrainerProfile = () => {
	const [state, setState] = useState([]);
	const [trainer, setTrainer] = useState({
		selectedTrainer: [],
		trainerAsTrainee: [],
		trainerAsTrainer: [],
	});
	const [traineeBatchStatus, setTraineeBatchStatus] = useState(
		Object.values(DROPDOWNITEMSFORTRAININGS)[0]
	);
	const [trainerBatchStatus, setTrainerBatchStatus] = useState(
		Object.values(DROPDOWNITEMSFORTRAININGS)[0]
	);
	const traineeBatchSelectHandler = (key, event) => {
		setTraineeBatchStatus({ status: key, label: event.target.textContent });
	};
	const trainerBatchSelectHandler = (key, event) => {
		setTrainerBatchStatus({ status: key, label: event.target.textContent });
	};
	const location = useLocation();
	const trainerId = location.state;
	const format = (data) => ({
		...data,
		doj: moment(data.doj).format('MMM Do YYYY'),
	});

	useEffect(() => {
		setState([
			{
				name: 'Trainers',
				route: '/trainers',
				isLink: true,
				id: 1,
			},
			{
				name: trainer?.selectedTrainer?.name,
				route: `/trainers/${trainer?.selectedTrainer?.name}`,
				isLink: false,
				id: 2,
			},
		]);
	}, [trainer?.selectedTrainer?.name]);

	useEffect(() => {
		const obj = new EmployeeServices();
		obj.getEmployeeById(trainerId).then((res) => {
			const responseData = res?.data;
			const formattedTrainer = format(responseData);
			setTrainer((prevState) => ({
				...prevState,
				selectedTrainer: formattedTrainer,
			}));
		});
	}, [trainerId]);

	useEffect(() => {
		const status = {
			status: traineeBatchStatus.status,
		};
		const obj = new TrainerServices();
		obj.trainerAsTrainee(trainerId, status).then((res) => {
			const trainerRes = res.data;
			const formattedTrainer = trainerRes.map((eachTrainer) => ({
				...eachTrainer,
				startDate: moment(eachTrainer.startDate).format('MMM Do YYYY'),
				endDate: moment(eachTrainer.endDate).format('MMM Do YYYY'),
			}));
			setTrainer((prevState) => ({
				...prevState,
				trainerAsTrainee: formattedTrainer.length ? formattedTrainer : [],
			}));
		});
	}, [trainerId, traineeBatchStatus.status]);

	useEffect(() => {
		const status = {
			status: trainerBatchStatus.status,
		};
		const obj = new TrainerServices();
		obj.trainerAsTrainer(trainerId, status).then((res) => {
			const trainerResponse = res.data;
			const formattedTrainer = trainerResponse.map((trainerData) => ({
				...trainerData,
				startDate: moment(trainerData.startDate).format('MMM Do YYYY'),
				endDate: moment(trainerData.endDate).format('MMM Do YYYY'),
			}));
			setTrainer((prevState) => ({
				...prevState,
				trainerAsTrainer: formattedTrainer.length ? formattedTrainer : [],
			}));
		});
	}, [trainerId, trainerBatchStatus.status]);

	const columns = [
		{
			field: 'name',
			headerName: ' Name',
		},
		{
			field: 'startDate',
			headerName: 'Start Date',
		},
		{
			field: 'endDate',
			headerName: 'End Date',
		},
		{
			field: 'progress',
			headerName: 'Progress',
			width: 10,
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					<span>{`${params.value}%`}</span>
				</>
			),
		},
	];
	return (
		<div className="pb-4">
			<BreadCrumb data={state} />
			<h5 className="text-start mb-4">{EMPLOYEE.trainerTitle}</h5>
			<div className="d-flex gap-5">
				<Profile
					employee={
						trainer?.selectedTrainer ? trainer?.selectedTrainer : 'No Data'
					}
				/>
				<div className="size">
					<Skills
						skills={
							trainer?.selectedTrainer?.skills?.length
								? trainer?.selectedTrainer?.skills
								: []
						}
					/>
					<div className="d-flex justify-content-end mt-3">
						<DropdownButton
							style={{ position: 'absolute' }}
							onSelect={traineeBatchSelectHandler}
							title={traineeBatchStatus?.label || traineeBatchStatus}
						>
							{DROPDOWNITEMSFORTRAININGS.map((key, i) => (
								<Dropdown.Item eventKey={key.status} key={i}>
									{key.label}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</div>
					<DataTable
						height={trainer?.trainerAsTrainee?.length ? 5 * 54 : 150}
						columns={columns}
						rows={
							trainer?.trainerAsTrainee?.length ? trainer?.trainerAsTrainee : []
						}
						tableTitle="As Trainee"
						pagination={false}
						enableGlobalSearch={false}
						enableDropdown={true}
						dropdownOptions={['Ongoing Trainings', 'Completed Trainings']}
					/>
					<div className="d-flex justify-content-end mt-3">
						<DropdownButton
							style={{ position: 'absolute' }}
							onSelect={trainerBatchSelectHandler}
							title={trainerBatchStatus?.label || trainerBatchStatus}
						>
							{DROPDOWNITEMSFORTRAININGS.map((key, i) => (
								<Dropdown.Item eventKey={key.status} key={i}>
									{key.label}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</div>
					<DataTable
						height={trainer?.trainerAsTrainer?.length ? 5 * 54 : 150}
						columns={columns}
						rows={
							trainer?.trainerAsTrainer?.length ? trainer?.trainerAsTrainer : []
						}
						tableTitle="As Trainer"
						pagination={false}
						enableGlobalSearch={false}
						enableDropdown={true}
						dropdownOptions={['Ongoing Trainings', 'Completed Trainings']}
					/>
				</div>
			</div>
		</div>
	);
};
export default TrainerProfile;
