import React, { useState, useEffect } from 'react';
import { EmployeeTable } from './EmployeeTable';
import UseSearch from '../../common-components/UseSearch/UseSearch';
import Pagination from '../../common-components/Pagination/Pagination';
import EmployeeServices from '../../services/employeeService';

const Employee = ({ userRole }) => {
	const [employeeList, setEmployeeList] = useState({});
	const { debouncedSearchValue, handleSearch } = UseSearch('', 500);
	const [initialValue, setInitialValue] = useState(true);
	const { pageSize, pageNum, onSize, onPageNum, setPageNum } = Pagination(
		10,
		1
	);

	const getAllEmployees = (searchValue, skip, limit) => {
		const obj = new EmployeeServices();

		obj.getAllEmployees(searchValue, skip, limit).then((response) => {
			const responseData = response?.data;
			const list = responseData?.map((employee) => ({
				...employee,
				experience: `${
					employee?.experience === '1'
						? `${employee?.experience} Year`
						: `${employee?.experience} Years`
				}`,
				considerTech: employee?.considerTech?.map((val) => val.skillName)[0],
				manager: employee?.manager?.name,
			}));
			const { datalength, searchlength } = response || {};
			setEmployeeList({
				list: list?.length ? list : [],
				totalRecords: datalength || 0,
				searchedRecords: searchlength || 0,
			});
		});
	};

	useEffect(() => {
		getAllEmployees(debouncedSearchValue, pageNum, pageSize);
	}, [pageSize, pageNum]);

	useEffect(() => {
		if (debouncedSearchValue !== '' && pageNum !== 1) {
			setPageNum(1);
		} else if (!initialValue) {
			getAllEmployees(debouncedSearchValue, pageNum, pageSize);
		} else {
			setInitialValue(!initialValue);
		}
	}, [debouncedSearchValue]);
	return (
		<>
			<EmployeeTable
				employeeList={employeeList?.list}
				userRole={userRole}
				handleSearch={handleSearch}
				onSize={onSize}
				onPageNum={onPageNum}
				pageSize={pageSize}
				pageNum={pageNum}
				totalNoOfRecords={employeeList?.totalRecords}
				searchedRecords={employeeList?.searchedRecords}
			/>
		</>
	);
};
export default Employee;
