import { useState } from 'react';

const Pagination = (initialPageSize, intialPageNumber) => {
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [pageNum, setPageNum] = useState(intialPageNumber);

	const onSize = (e) => {
		setPageSize(e);
	};

	const onPageNum = (e) => {
		setPageNum(e);
	};

	return { pageSize, pageNum, onSize, onPageNum, setPageNum };
};

export default Pagination;
