import {
	FaChartBar,
	FaUsers,
	FaUserCog,
	FaEdit,
	FaCubes,
	FaBug,
	FaLayerGroup,
} from 'react-icons/fa';

export const NAVBAR = {
	myProfile: 'My profile',
	logOut: 'Log out',
};

export const userRoles = {
	admin: 'Admin',
	manager: 'Manager',
	trainer: 'Trainer',
	trainee: 'Trainee',
	employee: 'Employee',
};

export const SIDEBAR = [
	{
		key: 'dashboard',
		label: 'Dashboard',
		path: '/dashboard',
		allowedRoles: [userRoles.admin, userRoles.manager],
		icon: <FaChartBar className="fs-5" />,
	},
	{
		key: 'employees',
		label: 'Employees',
		path: '/employees',
		allowedRoles: [userRoles.admin, userRoles.manager],
		icon: <FaUsers className="fs-5" />,
	},
	{
		key: 'batches',
		label: 'Batches',
		path: '/batches',
		allowedRoles: [
			userRoles.trainee,
			userRoles.trainer,
			userRoles.admin,
			userRoles.manager,
			userRoles.employee,
		],
		icon: <FaLayerGroup className="fs-5" />,
	},
	{
		key: 'trainers',
		label: 'Trainers',
		path: '/trainers',
		allowedRoles: [userRoles.admin, userRoles.manager],
		icon: <FaUserCog className="fs-5" />,
	},
	{
		key: 'course',
		label: 'Courses',
		path: '/course',
		allowedRoles: [
			userRoles.trainee,
			userRoles.admin,
			userRoles.manager,
			userRoles.employee,
		],
		icon: <FaCubes className="fs-5" />,
	},
	{
		key: 'trainerCourses',
		label: 'Courses',
		path: '/trainerCourses',
		allowedRoles: [userRoles.trainer],
		icon: <FaCubes className="fs-5" />,
	},
	{
		key: 'assessments',
		label: 'Assessments',
		path: '/assessments',
		allowedRoles: [
			userRoles.trainee,
			userRoles.trainer,
			userRoles.admin,
			userRoles.manager,
		],
		icon: <FaEdit className="fs-5" />,
	},
	// {
	// 	key: 'requests',
	// 	label: 'Requests',
	// 	path: '/requests',
	// 	allowedRoles: [userRoles.admin, userRoles.manager],
	// },
	{
		key: 'feedback',
		label: 'Feedback',
		path: '/feedback',
		allowedRoles: [
			userRoles.admin,
			userRoles.manager,
			userRoles.trainer,
			userRoles.trainee,
		],
		icon: <FaBug className="fs-5" />,
	},
];
export const SHAREFEEDBACKTO = {
	slNo: 'Sl.No',
	batchName: 'Batch Name',
	trainee: 'Trainee',
	trainer: 'Trainer',
	manager: 'Manager',
};

export const DROPDOWNITEMS = [
	{ status: 'InProgress', label: 'Ongoing Batches' },
	{ status: 'Completed', label: 'Completed Batches' },
	{ status: 'Acknowledged', label: 'Upcoming Batches' },
];
export const DROPDOWNITEMSEMPLOYEES = [
	{ status: 'InProgress', label: 'Ongoing Batches' },
	{ status: 'Acknowledged', label: 'Upcoming Batches' },
];

export const Roles = [
	{ role: 'Admin', label: 'Admin' },
	{ role: 'Manager', label: 'Manager' },
	{ role: 'Trainer', label: 'Trainer' },
	{ role: 'Trainee', label: 'Trainee' },
	{ role: 'Employee', label: 'Employee' },
];

export const EMPLOYEESSPECTRUMDROPDOWNITEMS = [
	{ status: 'InProgress', label: 'Ongoing' },
	{ status: 'Completed', label: 'Completed' },
	{ status: 'Acknowledged', label: 'Upcoming' },
];
export const COURSESTATUSDROPDOWNITEMS = [
	{ status: 'Active', label: 'Active Courses' },
	{ status: 'Inactive', label: 'In Active Courses' },
];

export const DROPDOWNITEMSFORTRAININGS = [
	{ status: 'InProgress', label: 'Ongoing Trainings' },
	{ status: 'Completed', label: 'Completed Trainings' },
	{ status: 'Acknowledged', label: 'Upcoming Trainings' },
];

export const TECHDROPDOWNITEMS = [
	{ status: 'Frontend', label: 'Frontend' },
	{ status: 'Backend', label: 'Backend' },
	{ status: 'Devops', label: 'Devops' },
	{ status: 'QA', label: 'QA' },
	{ status: 'Data Engineering', label: 'Data Engineering' },
	{ status: 'AI/ML', label: 'AI/ML' },
	{ status: 'IOT', label: 'IOT' },
	{ status: 'Blockchain', label: 'Blockchain' },
];

export const DASHBOARDYEARDROPDOWN = [{ year: 2022 }, { year: 2023 }];
export const BATCHES = {
	batchDuration: {
		startDate: 'Start Date',
		endDate: 'End Date',
	},
	batchesDetails: {
		domain: 'Expertise',
		trainer: 'Trainer',
		progress: {
			title: 'Progress',
			percentage: 55,
		},
	},
	batchesDescription: {
		name: 'Batch Name',
		trainer: 'Trainer',
		domain: 'Expertise',
		startDate: 'Start Date',
		endDate: 'End Date',
		progress: 'Progress',
		description: 'Description',
		skills: 'Skills',
		contributor: 'Contributor',
		course: 'Course',
		batchManager: 'Batch Manager',
	},
	addBatchForm: {
		addBatch: 'Add Batch',
		editBatch: 'Edit Batch',
		batchName: 'Batch Name',
		description: 'Batch Description',
		domain: 'Expertise',
		batch: 'Batch Name',
		batchManager: 'Batch Manager',
		skills: 'Skills',
		status: 'Status',
		startDate: 'Start Date',
		endDate: 'End Date',
		trainerId: 'Trainers',
		traineeIds: 'Trainees',
		programId: 'Course',
	},
};

export const BUTTONS = {
	add: 'Add',
	submit: 'Submit',
	cancel: 'Cancel',
	remove: 'Remove',
	save: 'Save',
};

export const PROGRAMS = {
	programs: 'Courses',
	traineePrograms: 'My Courses',
	programDetails: {
		name: 'Course Name',
		trainer: 'Trainer',
		description: 'Description',
		skills: 'Skills',
		domain: 'Expertise',
		referenceLinks: 'Reference Links',
		comments: 'Comments',
		contributers: 'Contributors',
		duration: 'Course Duration',
		days: 'Days',
		tableOfContents: 'Table of Contents',
		referenceFile: 'Reference File',
	},
	addProgramForm: {
		name: 'Name',
		addProgram: 'Add Course',
		editProgram: 'Edit Course',
		projectId: 'Project Id',
		projectName: 'Project Name',
		description: 'Description',
		skills: 'Skills',
		domain: 'Expertise',
		tableofContent: 'Table of Contents',
		duration: 'Course Duration',
		contributors: 'Contributors',
		trainerId: 'Trainer',
		referenceLink: 'Reference Links',
		comments: 'Comments',
		uploadedFile: 'Program File',
	},
};

export const LOGIN = {
	emailId: 'Email',
	password: 'Password',
};

export const EMPLOYEE = {
	tableHeading: 'Employees',
	skills: 'Skills',
	title: 'Employee Profile',
	trainerTitle: 'Trainer Profile',
	header: 'Status',
	addEmployees: 'Add Employee',
	editEmployee: 'Edit Employee',
	employeeProfile: {
		employeeName: 'Employee Name',
		flId: 'FL Id',
		emailId: 'Email Id',
		designation: 'Designation',
		experience: 'Experience',
		contactNumber: 'Contact Number',
		joiningDate: 'Joining Date',
		manager: 'Reporting Manager',
		courseOrCertification: 'Course/Certification',
		technology: 'Technology',
		domain: 'Expertise',
	},
	addEmployee: {
		name: 'Name',
		emailId: 'Email Id',
		fissionId: 'Fission Id',
		contactNo: 'Contact Number',
		alternateNo: 'Alternate Number',
		gender: 'Gender',
		doj: 'Date Of Joining',
		skills: 'Skills',
		considerTech: 'Considered Tech',
		teamLead: 'Team Lead',
		manager: 'Manager',
		designation: 'Designation',
		status: 'Status',
		shadowWhom: 'Shadow for whom',
		projectOrtPocName: 'Project/Poc Name',
		experience: 'Experience',
		courseOrCertification: 'Course/Certification',
		domain: 'Expertise',
		uploadedFile: 'Employe Profile',
	},
};

export const STATUS = {
	projectName: 'Project Name',
	lead: 'Lead',
	domain: 'Expertise',
	shadowForWhom: 'Shadow For Whom',
	reportingManager: 'Manager',
	bench: 'Bench',
	shadowProject: 'Shadow Project',
	project: 'Project',
	training: 'Trainings',
};

export const TRAINERS = {
	trainers: 'Trainers',
};

export const ASSESSMENTS = {
	assesments: 'Assessments',
	assesmentsDetails: {
		name: 'Name',
		trainer: 'Trainer',
		createdBy: 'Created By',
		description: 'Description',
		topic: 'Topics',
		references: 'References',
		type: 'Type',
		course: 'Course',
		batchId: 'Batch',
		endDate: 'End Date',
		startDate: 'Start Date',
		referenceFile: 'Reference File',
	},
	addAssessmentForm: {
		addAssessment: 'Add Assessment',
		editAssessment: 'Edit Assessment',
		name: 'Assessment Name',
		details: 'Description',
		createdBy: 'Created By',
		topics: 'Topics',
		references: 'References',
		type: 'Type',
		programId: 'Course',
		batchId: 'Batch',
		deadline: 'End Date',
		startDate: 'Start Date',
	},
};

// Feedback Form
export const FEEDBACKFORMVERSIONS = [1, 2, 3, 4, 5];
export const FEEDBACKFORM = {
	formName: 'Form Name is required',
	questions: 'At least one question is required',
	version: 'Version is Required',
	queExisted: 'The Question already Existed',
	success: 'The Feedback Form is Created Successfully...!',
};

export const CREATEFEEDBACKFORM = {
	formName: 'Form Name',
	formVersion: 'Form Version',
	questions: 'Add Question',
	feedbackOn: 'Feedback On',
};

export const CREATEFEEDBACKFORMQUESTIONTABLEHEADER = [
	'Question No',
	'Question Name',
	'Delete',
];

export const FEEDBACKFORMFORWARDTOTABLEHEADER = [
	'Sl.No',
	'Batch Name',
	'Trainer',
	'Trainee',
	'Manager',
];

export const CREATEFEEDBACKFORMFEEDBACKONOPTIONS = [
	{ label: 'Batch', value: 'batch' },
	{ label: 'Trainee', value: 'trainee' },
	{ label: 'Trainer', value: 'trainer' },
	{ label: 'Manager', value: 'manager' },
];

export const MOCK_QUESTIONS = [
	// Batch Questions
	{
		type: 'Batch',
		questions: [
			{
				que_No: 1,
				que: 'Communication skills in the batch',
				error: false,
			},
			{
				que_No: 2,
				que: 'Batch organization and discipline',
				error: false,
			},
			{
				que_No: 3,
				que: 'Batch technical progress',
				error: false,
			},
			{
				que_No: 4,
				que: 'Batch collaboration and teamwork',
				error: false,
			},
			{
				que_No: 5,
				que: 'Batch members feedback on the training',
				error: false,
			},
			// Add more batch questions as needed
		],
	},
	// Trainer Questions
	{
		type: 'Trainer',
		questions: [
			{
				que_No: 1,
				que: 'Trainer teaching effectiveness',
				error: false,
			},
			{
				que_No: 2,
				que: 'Trainer knowledge on the subject',
				error: false,
			},
			{
				que_No: 3,
				que: 'Trainer communication with trainees',
				error: false,
			},
			{
				que_No: 4,
				que: 'Trainer responsiveness to trainee queries',
				error: false,
			},
			{
				que_No: 5,
				que: 'Trainer approachability and support',
				error: false,
			},
			// Add more trainer questions as needed
		],
	},
	// Trainee Questions
	{
		type: 'Trainee',
		questions: [
			{
				que_No: 1,
				que: 'Trainee learning progress',
				error: false,
			},
			{
				que_No: 2,
				que: 'Trainee engagement in the program',
				error: false,
			},
			{
				que_No: 3,
				que: 'Trainee feedback on the course',
				error: false,
			},
			{
				que_No: 4,
				que: 'Trainee satisfaction with the training content',
				error: false,
			},
			{
				que_No: 5,
				que: 'Trainee suggestions for improvement',
				error: false,
			},
			// Add more trainee questions as needed
		],
	},
	// Manager Questions
	{
		type: 'Manager',
		questions: [
			{
				que_No: 1,
				que: 'Manager evaluation of trainees',
				error: false,
			},
			{
				que_No: 2,
				que: 'Manager feedback on the training',
				error: false,
			},
			{
				que_No: 3,
				que: 'Manager suggestions for improvement',
				error: false,
			},
			{
				que_No: 4,
				que: 'Manager assessment of the training outcomes',
				error: false,
			},
			{
				que_No: 5,
				que: 'Manager expectations from the trainees',
				error: false,
			},
			// Add more manager questions as needed
		],
	},
];

export const DASHBOARDQUARTERDROPDOWN = [
	{
		label: 'Jan - Mar (Q1)',
	},
	{
		label: 'Apr - Jun (Q2)',
	},
	{
		label: 'July - Sep (Q3)',
	},
	{
		label: 'Oct - Dec (Q4)',
	},
];
