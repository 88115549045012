import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const ProtectedRoutes = ({ userLoggedIn, userRole }) => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!userLoggedIn) {
			navigate('/', { replace: true });
		}
	}, [userLoggedIn, userRole]);

	return <Outlet />;
};

export default ProtectedRoutes;
