import { makeAPICall } from './api';
import { ApiPath, HttpMethods } from './api/definitions';

class ProgramServices {
	getAllPrograms() {
		return makeAPICall(ApiPath.getAllPrograms, HttpMethods.get, 'auth');
	}

	getAllProgramsByStatus(status, searchValue, skip, limit) {
		return makeAPICall(
			`${ApiPath.getAllProgramsByStatus}/${status}?searchvalue=${searchValue}&skip=${skip}&limit=${limit}`,
			HttpMethods.get,
			'auth'
		);
	}

	getAllTraineeCourses(id) {
		return makeAPICall(
			`${ApiPath.getAllTraineeCourses}/${id}`,
			HttpMethods.get,
			'auth'
		);
	}

	getAllTrainerCourses(id, courseType, searchValue, skip, limit) {
		if (courseType === 'AllCourses') {
			return makeAPICall(
				`${ApiPath.getAllTrainerCourses}/${id}/${courseType}?searchvalue=${searchValue}&skip=${skip}&limit=${limit}`,
				HttpMethods.get,
				'auth'
			);
		}
		return makeAPICall(
			`${ApiPath.getAllTrainerCourses}/${id}/${courseType}`,
			HttpMethods.get,
			'auth'
		);
	}

	getProgramById(id) {
		return makeAPICall(
			`${ApiPath.getAllPrograms}/${id}`,
			HttpMethods.get,
			'auth'
		);
	}

	getAllSkills() {
		return makeAPICall(ApiPath.getAllSkills, HttpMethods.get, 'auth');
	}

	createProgram(data) {
		return makeAPICall(
			ApiPath.createProgram,
			HttpMethods.post,
			'FormData',
			data
		);
	}

	updateProgram(data, id) {
		return makeAPICall(
			`${ApiPath.updateProgram}${id}`,
			HttpMethods.patch,
			'FormData',
			data
		);
	}

	deleteProgram(id) {
		return makeAPICall(
			`${ApiPath.updateProgram}${id}`,
			HttpMethods.delete,
			'auth'
		);
	}

	programSoftDelete(id, data) {
		return makeAPICall(
			`${ApiPath.programSoftDelete}/${id}`,
			HttpMethods.patch,
			'auth',
			data
		);
	}
}

export default ProgramServices;
