import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import moment from 'moment';
import { AssessmentTable } from './AssessmentTable';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import { BarChart } from '../../common-components/BarChart/BarChart';
import Card from '../../common-components/Card/Card';
import BatchService from '../../services/batchService';
import DashboardService from '../../services/dashboardService';
import AssessmentService from '../../services/assessmentService';
import { setTheCurrentYear } from '../../utils/helper';
import {
	userRoles,
	DASHBOARDYEARDROPDOWN,
	TECHDROPDOWNITEMS,
} from '../../constants/AppConstants';
import TraineeAssessmentSubmissionFormModal from './TraineeAssessmentSubmissionFormModal';
import './Styles.css';

export const Assessments = (props) => {
	const [state, setState] = useState([]);
	const [allBatchesForAssessmentFilter, setAllBatchesForAssessmentFilter] =
		useState();
	const [selectedBatch, setSelectedBatch] = useState();
	const [activeAssessmentAvgScore, setActiveAssessmentAvgScore] = useState();
	const [allAssessmentsList, setallAssessmentsList] = useState();
	const [selectedAssessment, setSelectedAssessment] = useState();
	const userId = localStorage.getItem('userId');
	const [activeAssessment, setActiveAssessment] = useState({
		names: [],
		averageScores: [],
		length: null,
	});
	const [allAssessments, setallAssessments] = useState();
	const [selectedBatchAssessments, setSelectedBatchAssessments] = useState([]);
	const [year, setYear] = useState();
	const [tech, setTech] = useState(TECHDROPDOWNITEMS[0]);
	const location = useLocation();
	useEffect(() => {
		setState([
			{
				name: 'Assessments',
				route: '/assessments',
				isLink: false,
			},
		]);
	}, []);

	useEffect(() => {
		if ([userRoles.admin, userRoles.manager].includes(props?.userRole)) {
			setTheCurrentYear(DASHBOARDYEARDROPDOWN, setYear);
		}
	}, [props?.userRole]);

	const getTrainerBatchesForAssessments = () => {
		const assessmentService = new AssessmentService();
		assessmentService.getAllTrainerBatches(userId).then(async (res) => {
			const responseData = await res?.data;
			const batchesList = responseData?.map((item) => ({
				_id: item._id,
				name: item.name,
			}));
			setAllBatchesForAssessmentFilter(batchesList);
			setSelectedBatch(batchesList[0]);
		});
	};
	const getAdminManagerBatchesForAssessments = () => {
		if (year?.year) {
			const batchService = new BatchService();
			const obj = {
				year: year?.year,
				domain: tech.label,
			};
			batchService.getAllBatchesyeardomain(obj).then(async (res) => {
				const responseData = await res?.data;
				const batchesList = responseData?.map((item) => ({
					_id: item._id,
					name: item.name,
				}));
				if (!batchesList.length) {
					setallAssessmentsList([]);
					setSelectedAssessment('No Data');
					setSelectedBatchAssessments([]);
				}
				setAllBatchesForAssessmentFilter(batchesList);
				setSelectedBatch(batchesList[0]);
			});
		}
	};
	const getTraineeBatchesForAssessments = () => {
		const assessmentService = new AssessmentService();
		assessmentService
			.getAllTraineeBatchesInAssessments(userId)
			.then(async (res) => {
				const responseData = await res?.data;
				const batchesList = responseData?.map((item) => ({
					_id: item._id,
					name: item.name,
				}));
				setAllBatchesForAssessmentFilter(batchesList);
				setSelectedBatch(batchesList[0]);
			});
	};

	const batchSelectHandler = (event) => {
		const batch = allBatchesForAssessmentFilter.filter(
			(data) => data.name === event.target.value
		);
		setSelectedBatch(batch[0]);
	};

	const assessmentSelectHandler = (event) => {
		setSelectedAssessment({
			assessmentname: event.target.value,
		});
	};
	const getAsseessmentsByBatchForBarChart = () => {
		if (selectedBatch?._id) {
			const dashboardServices = new DashboardService();
			dashboardServices
				.getAssessmentsByBatch(selectedBatch?._id)
				.then(async (res) => {
					const responseData = await res?.data;
					const availableList = responseData?.map((item) => ({
						_id: item.assement_id,
						assessmentname: item.assesment_name,
					}));
					setallAssessments(responseData);
					setallAssessmentsList(availableList);
					setSelectedAssessment(
						availableList?.length ? availableList[0] : 'No Data'
					);
				});
		}
	};

	const getBarGraphData = () => {
		const barGraphData =
			allAssessments?.length &&
			allAssessments.find(
				(item) => item.assement_id === selectedAssessment?._id
			);
		const names =
			barGraphData?.trainee?.length &&
			barGraphData.trainee.map((item) => item.name);
		const scores =
			barGraphData?.trainee?.length &&
			barGraphData.trainee.map((item) => item.avrage_score);
		setActiveAssessment(() => ({
			names: names?.length ? names : [],
			averageScores: scores?.length ? scores : [],
			length: names?.length,
		}));
	};

	useEffect(() => {
		getAsseessmentsByBatchForBarChart();
	}, [selectedBatch, year?.year]);

	useEffect(() => {
		getBarGraphData();
	}, [allAssessments, selectedAssessment, year?.year]);

	const getAllAssessmentsAvgScores = () => {
		const assessmentService = new AssessmentService();
		assessmentService
			.getAllAssessmentReportsById(selectedBatch?._id)
			.then((response) => {
				const formattedResponse =
					response?.data?.length &&
					response?.data?.map((obj) => ({
						...obj,
						startDate: moment(obj.startDate).format('DD MMM YYYY'),
						deadline: moment(obj.deadline).format('DD MMM YYYY'),
					}));
				setSelectedBatchAssessments(formattedResponse);
			});
	};

	const getTraineeAssessmentScores = () => {
		const assessmentService = new AssessmentService();
		assessmentService
			.getAllTraineeAssessments(userId, selectedBatch?._id)
			.then((response) => {
				const availableList = response?.data?.map((item) => ({
					...item,
					startDate: moment(item.startDate).format('DD MMM YYYY'),
					deadline: moment(item.deadline).format('DD MMM YYYY'),
					submissionStatus:
						item?.submissionData === null
							? 'In Progress'
							: item.submissionData?.submissionStatus,
				}));
				setSelectedBatchAssessments(availableList);
			});
	};

	function fetchAssessmentsBatchesByUserRole(userRole) {
		switch (userRole) {
			case userRoles.admin:
			case userRoles.manager:
				getAdminManagerBatchesForAssessments();
				break;
			case userRoles.trainer:
				getTrainerBatchesForAssessments();
				break;
			// case userRoles.employee:
			// return getTrainerBatchesForAssessments();
			case userRoles.trainee:
				getTraineeBatchesForAssessments();
				break;
			default:
				return Promise.resolve();
		}
	}
	useEffect(() => {
		fetchAssessmentsBatchesByUserRole(props?.userRole);
	}, [tech, year]);

	useEffect(() => {
		if (selectedBatch?._id) {
			if (
				[userRoles.admin, userRoles.manager, userRoles.trainer].includes(
					props?.userRole
				)
			) {
				getAllAssessmentsAvgScores();
			} else if (props.userRole === userRoles.trainee) {
				getTraineeAssessmentScores();
			}
		}
	}, [selectedBatch, year?.year]);

	useEffect(() => {
		// eslint-disable-next-line no-shadow
		const { state } = location;
		if (location?.state) {
			const batch = allBatchesForAssessmentFilter?.filter(
				(e) => e?._id === state?.batchId
			)[0];
			setSelectedBatch({ _id: batch?._id, name: batch?.name });
		}
	}, [location?.state, allBatchesForAssessmentFilter]);

	const onDeleteConfirm = (data) => {
		const assessmentService = new AssessmentService();
		if ([userRoles.admin].includes(props.userRole)) {
			assessmentService.deleteAssessment(data?._id).then((response) => {
				if (response?._id) {
					toast.success('Assesment Deleted Successfully...!');
					getAllAssessmentsAvgScores();
				}
			});
		}
	};
	const fetchdata = () => {
		if (userRoles.trainee === props.userRole) {
			return getTraineeBatchesForAssessments;
		}
		if (
			userRoles.admin === props.userRole ||
			userRoles.manager === props.userRole
		) {
			return getAdminManagerBatchesForAssessments;
		}
		if (userRoles.trainer === props.userRole) {
			return getTrainerBatchesForAssessments;
		}
	};
	const seriesData = [
		{
			name: 'Score',
			data: activeAssessment?.averageScores?.length
				? activeAssessment.averageScores
				: [],
		},
	];

	const getActiveAssessmentAvgScore = (allAssessment, activeAssessmentData) => {
		const activeAssessmentInfo =
			allAssessment?.length &&
			allAssessment.filter((item) => item?._id === activeAssessmentData?._id);
		const assessment = activeAssessmentInfo?.length
			? activeAssessmentInfo[0]
			: null;
		setActiveAssessmentAvgScore(assessment?.average.toFixed(2));
	};

	useEffect(() => {
		getActiveAssessmentAvgScore(selectedBatchAssessments, selectedAssessment);
	}, [selectedAssessment, selectedBatchAssessments]);

	const selectYearDropDown = (e) => {
		setYear({ year: e.target.value });
	};

	const selectDomainDropdown = (e) => {
		setTech({ label: e.target.value });
	};

	return (
		<>
			<BreadCrumb data={state} />
			<div className="col-2 ">
				<h3 className="headerStyle">Assessments</h3>
				<TraineeAssessmentSubmissionFormModal />
			</div>
			<div className="d-flex">
				{[userRoles.admin, userRoles.manager].includes(props?.userRole) ? (
					<>
						<div className="col-3 pe-2">
							<h6 className=" ms-1">Year : </h6>
							<select
								className="styled-input"
								onChange={(e) => selectYearDropDown(e)}
								value={year?.year}
							>
								<>
									{DASHBOARDYEARDROPDOWN?.map((item, index) => (
										<option value={item?.year} key={index}>
											{item?.year}
										</option>
									))}
								</>
							</select>
						</div>
						<div className="col-3  pe-2">
							<h6 className=" ms-1">Expertise : </h6>
							<select
								className="styled-input"
								onChange={(e) => selectDomainDropdown(e)}
							>
								<>
									{TECHDROPDOWNITEMS?.map((item, index) => (
										<option value={item.label} key={index}>
											{item.label}
										</option>
									))}
								</>
							</select>
						</div>
					</>
				) : (
					''
				)}
				<div className="col-3 pe-2">
					<h6 className=" ms-1">Batch : </h6>
					<select
						className="styled-input"
						onChange={(e) => batchSelectHandler(e)}
					>
						<>
							{allBatchesForAssessmentFilter?.length > 0 ? (
								allBatchesForAssessmentFilter.map((item, index) => (
									<option value={item.name} key={index}>
										{item.name}
									</option>
								))
							) : (
								<option> {'No Data'} </option>
							)}
						</>
					</select>
				</div>
				<div className="col-3">
					<h6 className=" ms-1">Assessment :</h6>
					<select
						className="styled-input"
						onChange={(e) => assessmentSelectHandler(e)}
					>
						<>
							{allAssessmentsList?.length ? (
								allAssessmentsList.map((item, index) => (
									<option value={item.assessmentname} key={index}>
										{item.assessmentname}
									</option>
								))
							) : (
								<option> {'No Data'} </option>
							)}
						</>
					</select>
				</div>
			</div>
			{[userRoles.admin, userRoles.manager, userRoles.trainer].includes(
				props?.userRole
			) && (
				<Card className="w-100 mt-2 mb-2 BatchAssessmentsCard ">
					<div className="d-flex justify-content-start">
						<h6 className="p-3 d-flex inputLabel justify-content-center m-0">
							Assessment Average Score :
							<span className="ps-2 ">{activeAssessmentAvgScore || 0} %</span>
						</h6>
					</div>
					{activeAssessment?.names?.length > 1 &&
					activeAssessment?.averageScores?.length > 1 ? (
						<BarChart
							names={
								activeAssessment?.names?.length ? activeAssessment.names : []
							}
							seriesData={seriesData}
							itemsLength={activeAssessment?.length}
							height={280}
							isDashboard={true}
							addPercentageForLabel={true}
							barWidth={activeAssessment?.names?.length <= 3 ? '10' : '25'}
						/>
					) : (
						<h6 className="text-center mt-5 mx-auto">No Records To Show</h6>
					)}
				</Card>
			)}

			<AssessmentTable
				userRole={props.userRole}
				fetch={fetchdata}
				assessmentsList={selectedBatchAssessments}
				onDeleteConfirm={onDeleteConfirm}
				selectedBatch={selectedBatch}
				getTraineeAssessmentScores={getTraineeAssessmentScores}
			/>
		</>
	);
};
