import React from 'react';
import { Modal } from 'react-bootstrap';

function CustomModal(props) {
	const { modalState, closeModalHandler, size, children, modalHeader } = props;
	return (
		<>
			<Modal show={modalState} onHide={closeModalHandler} size={size}>
				<Modal.Header closeButton>
					<Modal.Title className="headerStyle text-center">
						{modalHeader}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>{children}</Modal.Body>
			</Modal>
		</>
	);
}

export default CustomModal;
