import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../common-components/DataTable/DataTable';
import { userRoles } from '../../constants/AppConstants';
import './Styles.css';

export const BatchesTable = ({
	batchList,
	columnNames,
	userRole,
	title,
	handleSearch,
	dropdown,
	onSize,
	onPageNum,
	pageSize,
	pageNum,
	totalNoOfRecords,
	searchedRecords,
	searchValue,
}) => {
	const [batchesList, setBatchesList] = useState(batchList);
	useEffect(() => {
		setBatchesList(batchList);
	}, [batchList]);

	const navigate = useNavigate();
	const cellClickedListener = (event) => {
		if (event.value) {
			const path = `/batches/${'batchDetails'}/${event.data?._id}`;
			navigate(path, { state: event.data._id });
		}
	};

	const handleEvent = () => {
		navigate('/batches/batchesForm');
	};

	let pageTitle;
	if (title && userRole === userRoles.trainer) {
		pageTitle = title;
	} else if (userRole === userRoles.trainee) {
		pageTitle = 'My Batches';
	} else {
		pageTitle = 'Batches';
	}

	const paginationChange = (updatedPageNumber, updatedPageSize) => {
		onSize(updatedPageSize);
		onPageNum(updatedPageNumber);
	};

	return (
		<div className="">
			<DataTable
				addButton={userRole === userRoles.admin}
				addButtonListner={userRole === userRoles.admin ? handleEvent : null}
				height={500}
				columns={columnNames}
				rows={batchesList}
				tableTitle={pageTitle}
				pagination={false}
				customPagination={
					!![userRoles.admin, userRoles.manager, userRoles.trainer].includes(
						userRole
					)
				}
				enableGlobalSearch={
					[userRoles.admin, userRoles.manager].includes(userRole) ||
					title === 'All Batches'
				}
				cellClickedListener={cellClickedListener}
				handleSearch={handleSearch}
				dropdown={dropdown}
				paginationChange={paginationChange}
				currentPage={pageNum}
				size={pageSize}
				totalNoOfRecords={totalNoOfRecords}
				searchedRecords={searchedRecords}
				searchValue={searchValue}
			/>
		</div>
	);
};
