import React from 'react';
import Table from 'react-bootstrap/Table';

const CustomTable = ({ children, header, className }) => (
	<Table className={className} striped bordered hover responsive>
		<thead>
			<tr>{header}</tr>
		</thead>
		<tbody className="container">{children}</tbody>
	</Table>
);

export default CustomTable;
