import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ConfirmationModal } from '../../common-components/ConfirmationModal/ConfirmationModal';
import DataTable from '../../common-components/DataTable/DataTable';
import { userRoles } from '../../constants/AppConstants';
import AssessmentService from '../../services/assessmentService';
import TraineeAssessmentSubmissionFormModal from './TraineeAssessmentSubmissionFormModal';
import TraineeResultSubmissionModal from './TraineeResultSubmissionModal';

export const AssessmentTable = ({
	userRole,
	assessmentsList,
	onDeleteConfirm,
	selectedBatch,
	getTraineeAssessmentScores,
}) => {
	const [addResultDatas, setAddResultDatas] = useState([]);
	const [modal, setModal] = useState(false);
	const [
		traineeAssessmentSubmissionFormModal,
		setTraineeAssessmentSubmissionFormModal,
	] = useState({
		create: false,
		edit: false,
	});

	const [selectedData, setSelectedData] = useState('');
	const [showPopup, setshowPopup] = useState('');
	const [selectedAssessment, setSelectedAssessment] = useState('');
	const [traineeSubmittedAssessmentInfo, setTraineeSubmittedAssessmentInfo] =
		useState();
	const assessmentService = new AssessmentService();

	const navigate = useNavigate();
	const handleEvent = () => {
		const path = `/assessments/${'addAssessment'}`;
		navigate(path);
	};
	const onEdit = (data) => {
		assessmentService.getAssessmentById(data._id).then((res) => {
			const resObj = res.data;
			const path = `/assessments/${resObj?._id}`;
			navigate(path, { state: resObj });
		});
	};
	const onDelete = (data) => {
		if ([userRoles.admin].includes(userRole)) {
			setSelectedData(data);
			setshowPopup(true);
		} else {
			setshowPopup(true);
		}
	};
	const onAddResult = (data) => {
		if (userRole !== userRoles.trainee || userRole === userRoles.trainer) {
			assessmentService.getAssessmentBatchtrainees(data._id).then((res) => {
				const orgdata = res.data.map((e) => ({
					trainee: e.traineeId,
					name: e.traineeName,
					score: e?.res?.score == null ? '' : e.res.score,
					remarks: e?.res ? e.res.remarks : '',
					assesmentname: e?.assesmentname ? e.assesmentname : '',
					assesment: e?.assementid ? e.assementid : '',
					resultId: e?.res ? e.res._id : '',
				}));
				setAddResultDatas(orgdata);
				setModal(true);
			});
		}
	};

	const getTraineeAssessmentSubmittedInfo = (assessmentId) => {
		if (userRole === userRoles.trainee) {
			const userId = localStorage.getItem('userId');
			const data = {
				batchId: selectedBatch?._id,
				traineeId: userId,
				assesmentId: assessmentId,
			};
			assessmentService
				.getTraineeAssessmentSubmittedInfo(data, false)
				.then((res) => {
					const formattedData = res?.data.map((submittedInfo) => {
						const updatedImages = [];

						submittedInfo.images.forEach((image) => {
							const updatedImage = { ...image };
							delete updatedImage._id;
							updatedImages.push(updatedImage);
						});

						return {
							githubUrls: submittedInfo?.githubLinks,
							uploadedFile: updatedImages,
							description: submittedInfo?.description,
						};
					});
					setTraineeSubmittedAssessmentInfo(formattedData[0]);
					setTraineeAssessmentSubmissionFormModal({
						create: false,
						edit: true,
					});
				});
		}
	};

	const traineeAssessmentSubmitModalHandler = (assessmentId) => {
		setSelectedAssessment(assessmentId);
		setTraineeAssessmentSubmissionFormModal({
			create: true,
			edit: false,
		});
	};

	const traineeAssessmentReSubmitModalHandler = (assessmentId) => {
		setSelectedAssessment(assessmentId);
		getTraineeAssessmentSubmittedInfo(assessmentId?._id);
	};

	const columns = [
		{
			field: 'name',
			headerName: 'Name',
			width: undefined,
			minWidth: 120,
			maxWidth: 300,
		},
		{
			field: 'createdBy.name',
			headerName: 'Created By',
		},
		{
			field: 'startDate',
			headerName: 'Start Date',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'deadline',
			headerName: 'End Date',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'type',
			headerName: 'Type',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					<span>{params.value === 'By Weekly' ? 'By Weekly' : 'Final'}</span>
				</>
			),
		},
		{
			field: 'average',
			headerName: [userRoles.trainee].includes(userRole)
				? 'Score'
				: 'Average Score',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					<span>
						{userRoles.trainee === userRole && params.value != null
							? params.value.toFixed(2)
							: `${params.value.toFixed(2)}`}
					</span>
				</>
			),
		},
		...(userRoles.trainee === userRole
			? [
					{
						field: 'submissionStatus',
						headerName: 'Status',
						headerClass: 'my-header-class',
						cellStyle: { textAlign: 'center' },
					},
					{
						field: 'submit',
						headerName: 'Submit',
						headerClass: 'my-header-class',
						cellStyle: { textAlign: 'center' },
						cellRenderer: (params) => {
							const status = params?.data?.submissionStatus;
							const handleClick =
								status === 'Submit' || status === 'Resubmit'
									? traineeAssessmentReSubmitModalHandler
									: traineeAssessmentSubmitModalHandler;

							return (
								<button
									className="btn btn-outline-secondary pt-0 pb-0 me-2"
									onClick={() => handleClick(params?.data)}
								>
									{status === 'In Progress' ? 'Submit' : 'Re Submit'}
								</button>
							);
						},
					},
			  ]
			: []),
		...(userRoles.trainer === userRole
			? [
					{
						field: 'addResult',
						headerName: 'Add Result',
						headerClass: 'my-header-class',
						cellStyle: { textAlign: 'center' },
						cellRenderer: (params) => (
							<button
								className="btn btn-outline-secondary pt-0 pb-0 me-2"
								onClick={() => onAddResult(params?.data)}
							>
								Add Result
							</button>
						),
					},
			  ]
			: []),
		...(userRole === userRoles.admin || userRole === userRoles.trainer
			? [
					{
						field: 'action',
						headerName: 'Action',
						headerClass: 'my-header-class',
						cellStyle: { textAlign: 'center' },
						cellRenderer: (params) => (
							<>
								{[userRoles.trainer, userRoles.admin].includes(userRole) && (
									<FaEdit
										onClick={() => onEdit(params?.data)}
										className="edit-icon"
									/>
								)}

								{[userRoles.trainer, userRoles.admin].includes(userRole) && (
									<FaTrash
										onClick={() => onDelete(params?.data)}
										className="delete-icon ms-2"
									/>
								)}
							</>
						),
					},
			  ]
			: []),
	];

	const handleCellValueChanged = (params) => {
		const { data, colDef } = params;
		setAddResultDatas((prevState) =>
			prevState.map((row) => {
				if (row === data) {
					return { ...row, [colDef.field]: params.newValue };
				}
				return row;
			})
		);
	};

	const cellClickedListener = (event) => {
		if (event.value) {
			assessmentService.getAssessmentById(event.data._id).then((res) => {
				const orgdata = res.data;
				const path = `/assessments/${'assesmentDetails'}/${event.data?._id}`;
				navigate(path, { state: orgdata });
			});
		}
	};

	const onConfirm = () => {
		onDeleteConfirm(selectedData);
		setshowPopup(false);
	};

	const onClosePopup = () => {
		setshowPopup(false);
		setSelectedData('');
	};

	return (
		<div className={userRole === 'Trainee' ? 'mt-2' : ''}>
			<DataTable
				addButton={![userRoles.manager, userRoles.trainee].includes(userRole)}
				addButtonListner={handleEvent}
				height={500}
				rows={assessmentsList}
				columns={columns}
				pagination={false}
				customPagination={false}
				enableGlobalSearch={false}
				cellClickedListener={cellClickedListener}
				handleCellValueChanged={handleCellValueChanged}
			></DataTable>
			<ConfirmationModal
				showPopup={showPopup}
				header={'Delete'}
				body={'Are you sure you want to delete the Assessment'}
				cancel={'Cancel'}
				confirm={'Delete'}
				onConfirm={onConfirm}
				onClosePopup={onClosePopup}
			/>
			<TraineeAssessmentSubmissionFormModal
				modalState={traineeAssessmentSubmissionFormModal}
				selectedAssessment={selectedAssessment}
				updateModalState={setTraineeAssessmentSubmissionFormModal}
				selectedBatchId={selectedBatch?._id}
				traineeSubmittedAssessmentInfoFToResubmit={
					traineeSubmittedAssessmentInfo
				}
				getTraineeAssessmentScores={getTraineeAssessmentScores}
			/>
			{userRoles?.trainer === userRole && modal && (
				<TraineeResultSubmissionModal
					userRole={userRole}
					modalState={modal}
					updateModalState={setModal}
					selectedAssessment={addResultDatas}
					batchId={selectedBatch?._id}
				/>
			)}
		</div>
	);
};
