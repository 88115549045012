import React from 'react';
import DataTable from '../../../common-components/DataTable/DataTable';

export const BatchEmployeesTable = (props) => (
	<div className="mt-2">
		<DataTable
			height={525}
			columns={props.columnNames}
			rows={props.employeesList}
			pagination={true}
			customPagination={false}
			tableTitle={props.category}
			enableGlobalSearch={true}
			cellClickedListener={props.cellClickedListener}
		/>
	</div>
);
