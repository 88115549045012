import { makeAPICall } from './api';
import { ApiPath, HttpMethods } from './api/definitions';

class employeeServices {
	getAllEmployees(searchValue, skip, limit) {
		return makeAPICall(
			`${ApiPath.getAllEmployees}?searchvalue=${searchValue || ''}&skip=${
				skip || ''
			}&limit=${limit || ''}`,
			HttpMethods.get,
			'auth'
		);
	}

	getEmployeeById(id) {
		return makeAPICall(
			`${ApiPath.getAllEmployees}/${id}`,
			HttpMethods.get,
			'auth'
		);
	}

	createEmployee(data) {
		return makeAPICall(
			ApiPath.createEmployee,
			HttpMethods.post,
			'FormData',
			data
		);
	}

	updateEmployee(data, id) {
		return makeAPICall(
			`${ApiPath.updateEmployee}/${id}`,
			HttpMethods.patch,
			'FormData',
			data
		);
	}

	getTrainingsByEmpId(id, data) {
		return makeAPICall(
			`${ApiPath.getTrainingsByEmpId}/${id}`,
			HttpMethods.post,
			'auth',
			data
		);
	}

	getDesignationList() {
		return makeAPICall(ApiPath.getAllDesignation, HttpMethods.get, 'auth');
	}

	getManagerList() {
		return makeAPICall(ApiPath.getAllManagers, HttpMethods.get, 'auth');
	}
}

export default employeeServices;
