import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import AssessmentService from '../../services/assessmentService';
import { BarChart } from '../../common-components/BarChart/BarChart';
import Card from '../../common-components/Card/Card';
import DashboardService from '../../services/dashboardService';
import '../Assessments/Styles.css';
import './Styles.css';

export function DashboardAssessmentsGraph(props) {
	const { quarter, year } = props;
	const [selectedBatch, setSelectedBatch] = useState();
	const [pageCountList, setPageCountList] = useState([]);
	const [pageNumber, setPageNumber] = useState(1);
	const [assessmentsAverageScore, setAssessmentsAverageScore] = useState([]);
	const [allBatches, setAllBatches] = useState([]);
	const [chartData, setChartData] = useState([]);
	const assessmentService = new AssessmentService();
	const paginationButtonClassName = (standardClassName, condition) =>
		`${condition ? standardClassName : `disabled-${standardClassName}`}`;
	const dashboardService = new DashboardService();

	const ifQuarter = {
		quarter: {
			fromDate: quarter?.fromDate,
			tillDate: quarter?.tillDate,
		},
	};
	const ifYear = {
		year: year?.year,
	};

	const employeeNames = (employeeList) =>
		employeeList?.map((employee) => employee?.trainee?.name);

	const assessmentNames = (assessmentNamesList) =>
		assessmentNamesList[0]?.asse?.map((assessment) => assessment?.name);

	const seriesData = (series) =>
		assessmentNames(series)?.map((testName) => ({
			name: testName,
			data: series?.map((emp) =>
				Number(
					emp?.asse?.filter((assessment) => assessment?.name === testName)[0]
						?.score
				)
			),
		}));

	const getAssessmentsDetails = async (batchId, selectedPageNumber) => {
		try {
			const Path = `${batchId}/${selectedPageNumber}`;
			const response = await assessmentService.getBatchCharts(Path);
			setChartData(response?.data?.data);
			const paginationArray = response?.data?.totalPages
				? Array.from(Array(response.data.totalPages).keys()).map(
						(page) => page + 1
				  )
				: [];
			setPageCountList(paginationArray);
			setAssessmentsAverageScore(response?.data?.assessmentaveragescore || []);
		} catch (error) {
			toast.error('Error fetching assessment details:', error);
		}
	};

	const getAllBatches = async () => {
		try {
			const response = await dashboardService.getAllDashboardBatches(
				quarter.label.length ? ifQuarter : ifYear
			);
			const res = response?.res || [];

			setAllBatches(res);
			setSelectedBatch(res[0]);
			setChartData(res.length ? [] : []);
		} catch (error) {
			toast.error('Error fetching all batches:', error);
		}
	};

	useEffect(() => {
		getAllBatches();
	}, [year?.year, quarter?.label]);

	useEffect(() => {
		if (selectedBatch) {
			getAssessmentsDetails(selectedBatch?._id, pageNumber);
		}
	}, [selectedBatch, pageNumber]);

	const selectHandler = (key) => {
		setPageNumber(key);
	};

	const nextPageHandler = () => {
		if (pageNumber < pageCountList.length) {
			setPageNumber(pageNumber + 1);
		}
	};

	const prevPageHandler = () => {
		if (pageNumber - 1 > 0) {
			setPageNumber(pageNumber - 1);
		}
	};

	const selectBatchHandler = async (key, event) => {
		await setPageNumber(1);
		await setSelectedBatch({
			_id: key,
			name: event.target.textContent,
		});
	};

	return (
		<>
			<Card className="w-100 mt-3 BatchAssessmentsCard">
				<div className=" d-flex  justify-content-start">
					<div className="subHeader dashboardGraphHeader text-start">
						<h3 className="text-start">Assessments</h3>
					</div>
					<div className="d-flex pt-2 ml-4 mr-4 justify-content-evenly">
						<h6 className="view-header mt-2">Select Batch :</h6>
						<DropdownButton
							onSelect={selectBatchHandler}
							title={selectedBatch?.name || 'Select'}
						>
							<div>
								{allBatches?.length &&
									allBatches.map((key, i) => (
										<Dropdown.Item eventKey={key?._id} key={i}>
											{key?.name}
										</Dropdown.Item>
									))}
							</div>
						</DropdownButton>
					</div>
					<div className="d-flex pt-2 ms-4  justify-content-evenly">
						{pageCountList?.length ? (
							<>
								<h6 className="view-header ms-5 mt-2 ">View :</h6>
								<DropdownButton
									onSelect={selectHandler}
									title={pageNumber || ''}
									className=""
								>
									{pageCountList.map((count, i) => (
										<Dropdown.Item eventKey={count} key={i}>
											{count}
										</Dropdown.Item>
									))}
								</DropdownButton>
							</>
						) : (
							''
						)}
					</div>
				</div>
				{employeeNames(chartData)?.length && seriesData(chartData)?.length ? (
					<>
						<div className="average-display-container  mx-5">
							<ul className="list-unstyled d-flex justify-content-evenly">
								{assessmentsAverageScore?.assessmentaveragescore?.map(
									(assessment) => (
										<li key={assessmentsAverageScore._id}>
											{assessment?.name} :{' '}
											<strong>{assessment?.averagescoreee?.toFixed(1)}%</strong>
										</li>
									)
								)}
							</ul>
						</div>
						<div className="bar-graph-container">
							<div
								className={paginationButtonClassName(
									'arrow-left',
									pageNumber - 1
								)}
								onClick={() => prevPageHandler()}
							></div>
							<div className="bar-graph">
								<BarChart
									names={employeeNames(chartData)}
									seriesData={seriesData(chartData) || []}
									itemsLength={5}
									height={250}
									isDashboard={false}
									addPercentageForLabel={false}
								/>
							</div>
							<div
								className={paginationButtonClassName(
									'arrow-right',
									pageNumber < pageCountList.length
								)}
								onClick={() => nextPageHandler()}
							></div>
						</div>
					</>
				) : (
					<h6 className="text-center mt-5 mx-auto"> No Records To Match </h6>
				)}
			</Card>
		</>
	);
}
