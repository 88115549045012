import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Modal } from 'react-bootstrap';
import { RxDownload } from 'react-icons/rx';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import Card from '../../common-components/Card/Card';
import { ASSESSMENTS } from '../../constants/AppConstants';
import AssessmentService from '../../services/assessmentService';
import EmployeeServices from '../../services/employeeService';
import BatchService from '../../services/batchService';
import DataTable from '../../common-components/DataTable/DataTable';
import {
	ViewPageRow,
	ViewPageSingleRow,
} from '../../common-components/ViewPageRow/ViewPageRow';

const AssessmentDetails = () => {
	const [state, setState] = useState([]);
	const [selectedAssessment, setSelectedAssessment] = useState();
	const [createdByName, setCreatedByName] = useState([]);
	const [batch, setBatch] = useState([]);
	const [deadline, setDeadline] = useState();
	const [startDate, setStartDate] = useState();
	const [showImage, setShowImage] = useState(false);
	const [fileType, setFiletype] = useState(false);
	const assessmentService = new AssessmentService();
	const employeeServices = new EmployeeServices();
	const batchServices = new BatchService();
	const location = useLocation();
	const orgdata = location?.state;
	const [results, setresults] = useState();
	useEffect(() => {
		if (orgdata?.fileUploadUrl) {
			const urlObj = new URL(orgdata?.fileUploadUrl);
			const pathParts = urlObj.pathname.split('/');
			const fileExtension = pathParts.pop().split('.').pop().toLowerCase();
			if (
				['doc', 'docx', 'pdf', 'txt', 'ppt', 'pptx', 'xls', 'xlsx'].includes(
					fileExtension
				)
			) {
				setFiletype(true);
			}
		}
		setSelectedAssessment(orgdata);
		setDeadline(orgdata.deadline);
		setStartDate(orgdata.startDate);
		assessmentService.getAssessmentBatchtrainees(orgdata._id).then((res) => {
			const formattedRes = res.data.map((e) => ({
				name: e.traineeName,
				score: e?.res?.score == null ? 'No Score' : e.res.score,
				remarks: e?.res ? e.res.remarks : 'No Remarks',
			}));
			setresults(formattedRes);
		});
		employeeServices.getEmployeeById(orgdata.createdBy).then((response) => {
			setCreatedByName(response?.data);
		});
		batchServices.getBatchById(orgdata.batchId).then((response) => {
			setBatch(response?.data);
		});
		setState([
			{ name: 'Assessment', route: '/assessments', isLink: true, id: 1 },
			{ name: selectedAssessment?.name, route: '', isLink: false, id: 2 },
		]);
	}, [
		orgdata._id,
		orgdata.createdBy,
		orgdata.programId,
		orgdata.batchId,
		selectedAssessment?.name,
	]);
	const addResultcoloumn = [
		{
			field: 'name',
			headerName: 'Employee Name',
		},
		{
			field: 'score',
			headerName: 'Results',
			headerClass: 'my-header-class',
			cellStyle: { 'text-align': 'center' },
		},
		{
			field: 'remarks',
			headerName: 'Remarks',
			headerClass: 'my-header-class',
			cellStyle: { 'text-align': 'center' },
		},
	];

	// eslint-disable-next-line no-return-assign
	const openFileModal = () =>
		fileType
			? (window.location.href = selectedAssessment?.fileUploadUrl)
			: setShowImage(true);

	const referencesValue = (
		<>
			{selectedAssessment?.references.map((ref, i) => (
				<a key={i} href={ref} target="blank" className="me-1">
					{ref}
				</a>
			))}
		</>
	);

	const referenceFile = (
		<>
			{selectedAssessment?.fileUploadUrl && (
				<button className="rbt-token-label my-2" onClick={openFileModal}>
					{!fileType ? 'View Document' : 'Download Document'}
					{fileType && <RxDownload className="mx-2" />}
				</button>
			)}
			{selectedAssessment?.fileUploadUrl && !fileType && (
				<a
					href={selectedAssessment?.fileUploadUrl}
					download={selectedAssessment?.fileName}
					className="downloadBtn ms-2 text-decoration-none"
				>
					<RxDownload className="mb-2" />
				</a>
			)}
		</>
	);
	return (
		<>
			<BreadCrumb data={state} />
			{/* <h5 className="pb-2 pt-1">{selectedAssessment?.name}</h5> */}
			<Card>
				<div className="subHeader text-start">
					<h3 className="">{selectedAssessment?.name}</h3>
				</div>
				<ViewPageRow
					labelOne={ASSESSMENTS.assesmentsDetails.trainer}
					labelOneValue={batch?.trainerId?.name}
					labelTwo={ASSESSMENTS.assesmentsDetails.createdBy}
					labelTwoValue={createdByName?.name}
				/>
				<ViewPageRow
					labelOne={ASSESSMENTS.assesmentsDetails.type}
					labelOneValue={
						selectedAssessment?.type === 'Final' ? 'Final' : 'By Weekly'
					}
					labelTwo={ASSESSMENTS.assesmentsDetails.batchId}
					labelTwoValue={batch?.name}
				/>
				<ViewPageRow
					labelOne={ASSESSMENTS.assesmentsDetails.startDate}
					labelOneValue={moment(startDate).format('DD-MM-YYYY')}
					labelTwo={ASSESSMENTS.assesmentsDetails.endDate}
					labelTwoValue={moment(deadline).format('DD-MM-YYYY')}
				/>
				<ViewPageSingleRow
					label={ASSESSMENTS.assesmentsDetails.topic}
					value={selectedAssessment?.topics}
				/>
				{selectedAssessment?.references?.length ? (
					<ViewPageSingleRow
						label={ASSESSMENTS.assesmentsDetails.references}
						value={referencesValue}
					/>
				) : (
					''
				)}

				{!selectedAssessment?.fileUploadUrl ? (
					''
				) : (
					<ViewPageSingleRow
						label={ASSESSMENTS.assesmentsDetails.referenceFile}
						value={referenceFile}
					/>
				)}
				<ViewPageSingleRow
					label={ASSESSMENTS.assesmentsDetails.description}
					value={selectedAssessment?.details}
				/>
			</Card>
			<div className="pb-2 mb-5">
				<DataTable
					height={300}
					columns={addResultcoloumn}
					rows={results}
					pagination={false}
					tableTitle="Results"
					customPagination={false}
					enableGlobalSearch={false}
					enableDropdown={true}
				/>
			</div>
			{selectedAssessment?.fileUploadUrl ? (
				<Modal
					centered={true}
					size="xl"
					fullscreen="lg-down"
					show={showImage}
					onHide={() => setShowImage(false)}
				>
					<img
						className="d-block w-100"
						src={
							selectedAssessment?.fileUploadUrl
								? selectedAssessment?.fileUploadUrl
								: ''
						}
						alt="First slide"
					/>
				</Modal>
			) : (
				''
			)}
		</>
	);
};

export default AssessmentDetails;
