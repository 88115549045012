import { makeAPICall } from './api';
import { ApiPath, HttpMethods } from './api/definitions';

class AssessmentService {
	getAllAssessments() {
		return makeAPICall(ApiPath.getAllAssessments, HttpMethods.get, 'auth');
	}

	getAllAssessmentReportsById(id) {
		return makeAPICall(
			`${ApiPath.getAllAssessments}/${id}`,
			HttpMethods.get,
			'auth'
		);
	}

	getAssessmentById(id) {
		return makeAPICall(
			`${ApiPath.getAssessmentById}/${id}`,
			HttpMethods.get,
			'auth'
		);
	}

	createAssessment(data, id) {
		return makeAPICall(
			`${ApiPath.createAssessment}${id}`,
			HttpMethods.post,
			'FormData',
			data
		);
	}

	updateAssessment(data, id) {
		return makeAPICall(
			`${ApiPath.updateAssessment}${id}`,
			HttpMethods.patch,
			'FormData',
			data
		);
	}

	deleteAssessment(id) {
		return makeAPICall(
			`${ApiPath.deleteAssessment}${id}`,
			HttpMethods.delete,
			'auth'
		);
	}

	getBatchCharts(id) {
		return makeAPICall(
			`${ApiPath.getBatchCharts}${id}`,
			HttpMethods.get,
			'auth'
		);
	}

	getAssessmentBatchtrainees(id) {
		return makeAPICall(
			`${ApiPath.getAssessmentBatchTrainees}${id}`,
			HttpMethods.get,
			'auth'
		);
	}

	createAndUpdateAssessmentResults(data) {
		return makeAPICall(
			ApiPath.createAndUpdateAssessmentResults,
			HttpMethods.post,
			'auth',
			data
		);
	}

	// API,s FOR TRAINER ROLE
	getAllTrainerBatches(id) {
		return makeAPICall(
			`${ApiPath.getAllTrainerBatches}${id}`,
			HttpMethods.get,
			'auth'
		);
	}

	// API's FOR TRAINEE ROLE
	getAllTraineeAssessments(traineeID, batchId) {
		return makeAPICall(
			`${ApiPath.getAllTraineeAssessments}/${batchId}/${traineeID}`,
			HttpMethods.get,
			'auth'
		);
	}

	getAllTraineeBatchesInAssessments(traineeID) {
		return makeAPICall(
			`${ApiPath.getAllTraineeBatchAssessments}/${traineeID}`,
			HttpMethods.get,
			'auth'
		);
	}

	traineeAssessmentSubmission(data) {
		return makeAPICall(
			ApiPath.traineeAssessmentSubmission,
			HttpMethods.post,
			'FormData',
			data
		);
	}

	traineeAssessmentReSubmission(data) {
		return makeAPICall(
			ApiPath.traineeAssessmentSubmission,
			HttpMethods.patch,
			'FormData',
			data
		);
	}

	getTraineeAssessmentSubmittedInfo(data, fileURL) {
		return makeAPICall(
			`${ApiPath.getTraineeAssessmentSubmittedInfo}?fileURL=${fileURL}`,
			HttpMethods.post,
			'auth',
			data
		);
	}
}

export default AssessmentService;
// getTraineeAssessmentSubmttedInfo;
