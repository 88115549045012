import React, { useState, useEffect, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { FaCalendarAlt, FaTrash } from 'react-icons/fa';
import TypeaheadComponent from '../../common-components/TypeHead';
import Card from '../../common-components/Card/Card';
import {
	EMPLOYEE,
	BUTTONS,
	TECHDROPDOWNITEMS,
} from '../../constants/AppConstants';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import ProgramServices from '../../services/programServices';
import EmployeeServices from '../../services/employeeService';
import { formDataConversion } from '../../utils/helper';
import '../EmployeeProfile/Styles.css';

const EmployeeForm = () => {
	const [breadcrumbState, setBreadcrumbState] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [skillsList, setSkillsList] = useState([]);
	const [Designation, setDesignationList] = useState([]);
	const [managerList, setManagerList] = useState([]);
	const [fileTypeError, setFileTypeError] = useState(false);
	const [options, setOptions] = useState({
		genderOptions: ['Male', 'Female', 'Others'],
		domainOptions: TECHDROPDOWNITEMS.map((item) => item.status),
		countryCodeOptions: ['+91', '+1'],
	});
	const [formState, setFormState] = useState({
		name: {
			value: '',
			error: false,
			errorMsg: '',
		},
		emailId: {
			value: '',
			error: false,
			errorMsg: '',
		},
		contactNo: {
			value: '',
			error: false,
			errorMsg: '',
		},
		countryCode: {
			value: '+91',
			error: false,
			errorMsg: '',
		},
		alternateNo: {
			value: undefined,
			error: false,
			errorMsg: '',
		},
		altCountryCode: {
			value: '+91',
			error: false,
			errorMsg: '',
		},
		gender: {
			value: '',
			error: false,
			errorMsg: '',
		},
		doj: {
			value: '',
			error: false,
			errorMsg: '',
		},
		skills: {
			value: [],
			error: false,
			errorMsg: '',
		},
		// considerTech: {
		// 	value: [],
		// 	error: false,
		// 	errorMsg: '',
		// },
		// teamLead: {
		// 	value: '',
		// 	error: false,
		// 	errorMsg: '',
		// },
		manager: {
			value: '',
			error: false,
			errorMsg: '',
		},
		fissionId: {
			value: '',
			error: false,
			errorMsg: '',
		},
		designation: {
			value: '',
			error: false,
			errorMsg: '',
		},
		experience: {
			value: '',
			error: false,
			errorMsg: '',
		},
		domain: {
			value: '',
			error: false,
			errorMsg: '',
		},
		isTrainer: {
			value: false,
			error: false,
			errorMsg: '',
		},
		courseOrCertification: [
			{
				value: [],
				error: false,
				errorMsg: '',
			},
		],
		uploadedFile: {
			value: [],
			error: false,
			errorMsg: '',
		},
	});

	const location = useLocation();
	const navigate = useNavigate();
	const { state } = location;
	const inputRef = useRef(null);
	const programService = new ProgramServices();
	const employeeService = new EmployeeServices();
	const getAllSkillsForEmployees = async () => {
		try {
			const response = await programService.getAllSkills();
			const responseData = response?.data;
			setSkillsList(responseData?.length ? responseData : []);
		} catch (error) {
			toast.error('Error:', error?.details);
		}
	};

	const mapData = (list) =>
		list.map((data) => ({
			name: data?.name || '',
			_id: data?._id || '',
		}));

	const getAllEmployees = async () => {
		try {
			const response = await employeeService.getAllEmployees();
			const responseData = response.data;
			setOptions({
				...options,
				employeeOptions: responseData?.length ? mapData(responseData) : [],
			});
		} catch (error) {
			toast.error('Error:', error?.details);
		}
	};

	const getDesignationList = async () => {
		try {
			const response = await employeeService.getDesignationList();
			const responseData = response.data;
			setDesignationList(responseData);
		} catch (error) {
			toast.error('Error:', error?.details);
		}
	};

	const getAllManagers = async () => {
		try {
			const response = await employeeService.getManagerList();
			const responseData = response.data;
			setManagerList(responseData);
		} catch (error) {
			toast.error('Error:', error?.details);
		}
	};

	const updateField = (name, key, value) => {
		const copy = { ...formState[key] };
		const error = !value?.toString()?.length;
		let errorMsg;
		if (key === 'alternateNo' && value === '') {
			errorMsg = '';
		} else {
			errorMsg = value?.toString()?.length ? '' : `${name} is required`;
		}
		return { ...copy, value, error, errorMsg };
	};

	const handleChange = (event) => {
		const { id, value, name, type, checked } = event.target;
		// if (id === 'considerTech') {
		// 	// Convert value to an array
		// 	const selectedOptions = Array.from(
		// 		event.target.selectedOptions,
		// 		(option) => option.value
		// 	);
		// 	setFormState((prevState) => ({
		// 		...prevState,
		// 		[id]: updateField(name, id, selectedOptions),
		// 	}));
		// }
		if (type === 'checkbox') {
			setFormState((prevState) => ({
				...prevState,
				[id]: { value: checked, error: false, errorMsg: '' },
			}));
		} else {
			setFormState((prevState) => ({
				...prevState,
				[id]: updateField(name, id, value),
			}));
		}
	};

	const uploadHandler = (event) => {
		const { id, name } = event.target;
		const file = event.target.files[0];
		if (!file.type.match('image/(jpg|jpeg|png|gif)')) {
			setFileTypeError(true);
			return;
		}
		setFileTypeError(false);
		setFormState((prev) => ({
			...prev,
			uploadedFile: updateField(name, id, [{ file, fileName: file.name }]),
		}));
	};

	const dateHandler = (date) => {
		setFormState((prevState) => ({
			...prevState,
			doj: updateField('Date of joining', 'doj', date),
		}));
	};

	const courseOrCertificationHandler = (index, value) => {
		const updatedCourseOrCertification = [...formState.courseOrCertification];
		updatedCourseOrCertification[index] = {
			value: value,
			error: false,
			errorMsg: '',
		};
		const updatedFormState = {
			...formState,
			courseOrCertification: updatedCourseOrCertification,
		};
		setFormState(updatedFormState);
	};

	const addCourseOrCertification = () => {
		const updatedCourseOrCertification = [...formState.courseOrCertification];
		updatedCourseOrCertification.push({
			value: '',
			error: false,
			errorMsg: '',
		});
		setFormState((prevState) => ({
			...prevState,
			courseOrCertification: updatedCourseOrCertification,
		}));
	};

	const removeCourseOrCertification = (index) => {
		const updatedCourseOrCertification = [...formState.courseOrCertification];
		updatedCourseOrCertification.splice(index, 1);
		setFormState((prevState) => ({
			...prevState,
			courseOrCertification: updatedCourseOrCertification,
		}));
	};
	const handleTypeaheadChange = (name, id, selected) => {
		const updatedField = updateField(name, id, selected);
		setFormState((prevState) => ({
			...prevState,
			[id]: updatedField,
		}));
	};

	const onValidation = () => {
		let isValid = true;
		const updatedFormState = { ...formState };
		// Validate
		Object.keys(updatedFormState).forEach((key) => {
			// Exclude specific keys
			if (
				key !== 'alternateNo' &&
				key !== 'altCountryCode' &&
				key !== 'uploadedFile' &&
				key !== 'isTrainer' &&
				key !== 'courseOrCertification'
			) {
				updatedFormState[key] = updateField(
					EMPLOYEE?.addEmployee[key],
					key,
					updatedFormState[key].value
				);
				if (updatedFormState[key]?.error) {
					isValid = false;
				}
			}
		});

		const isPhoneNumberValid =
			formState.contactNo.value.toString().length === 10;
		updatedFormState.contactNo = {
			...updatedFormState.contactNo,
			error: !isPhoneNumberValid,
			errorMsg: formState?.contactNo?.value?.length
				? isPhoneNumberValid
					? ''
					: 'Please enter 10 digit Contact number'
				: 'Contact number is required',
		};

		if (!isPhoneNumberValid) isValid = false;
		setFormState(updatedFormState);

		if (formState?.alternateNo?.value) {
			const isalternaneNumberValid =
				formState?.alternateNo?.value?.toString().length === 10;
			updatedFormState.alternateNo = {
				...updatedFormState.alternateNo,
				error: !isalternaneNumberValid,
				errorMsg: isalternaneNumberValid
					? ''
					: 'Please enter 10 digit Contact number',
			};

			if (!isalternaneNumberValid) isValid = false;
			setFormState(updatedFormState);
		}

		const isFlIdValid = formState.fissionId.value.startsWith('FL');
		updatedFormState.fissionId = {
			...updatedFormState.fissionId,
			error: !isFlIdValid,
			errorMsg: formState?.fissionId?.value?.length
				? isFlIdValid
					? ''
					: 'Fission Id should start with FL'
				: 'Fission Id is required',
		};

		if (!isFlIdValid) isValid = false;
		setFormState(updatedFormState);

		const isEmailValid = ['@fissionlabs.com', '@fissionlabs.in'].some((word) =>
			formState.emailId.value.endsWith(word)
		);
		updatedFormState.emailId = {
			...updatedFormState.emailId,
			error: !isEmailValid,
			errorMsg: formState?.emailId?.value?.length
				? isEmailValid
					? ''
					: 'Email should end with @fissionlabs.com or @fissionlabs.in'
				: 'Email Id is required',
		};
		if (!isEmailValid) isValid = false;
		setFormState(updatedFormState);
		return isValid;
	};

	useEffect(() => {
		getAllEmployees();
		getAllSkillsForEmployees();
		// fetchData();
		getDesignationList();
		getAllManagers();
	}, []);

	const generateInitialFormState = (selectedEmployee) => {
		const updatedFormState = { ...formState };
		Object.keys(selectedEmployee).forEach((key) => {
			if (updatedFormState[key]) {
				if (
					key === 'courseOrCertification' &&
					selectedEmployee[key]?.length !== 0
				) {
					updatedFormState[key] = selectedEmployee[key].map((link) => ({
						value: link,
						error: false,
						errorMsg: '',
					}));
					// } else if (key === 'considerTech') {
					// 	// Convert value to an array
					// 	const selectedOptions = Array.from(
					// 		selectedEmployee?.considerTech,
					// 		(option) => option._id
					// 	);
					// 	updatedFormState[key].value = selectedOptions;
				} else if (key === 'contactNo') {
					updatedFormState[key].value = selectedEmployee[key]?.no;
					updatedFormState.countryCode.value =
						selectedEmployee[key]?.countryCode;
				} else if (key === 'alternateNo') {
					if (selectedEmployee[key]) {
						updatedFormState[key].value = selectedEmployee[key]?.no;
						updatedFormState.altCountryCode.value =
							selectedEmployee[key]?.countryCode;
					}
				} else {
					updatedFormState[key].value = selectedEmployee[key];
				}
			}
		});
		return updatedFormState;
	};
	const onCancel = () => {
		navigate('/employees');
	};

	useEffect(() => {
		if (location.pathname !== '/employees/addEmployees') {
			setIsEdit(true);
			setBreadcrumbState([
				{ name: 'Employees', route: '/employees', isLink: true },
				{ name: 'Edit Employee', route: '', isLink: false },
			]);
			const selectedEmployee = location.state;
			selectedEmployee.uploadedFile = selectedEmployee?.fileUploadUrl
				? [
						{
							file: selectedEmployee.fileUploadUrl,
							fileName: selectedEmployee.fileName,
						},
				  ]
				: [];
			setFormState(
				generateInitialFormState({
					...selectedEmployee,
					// teamLead: selectedEmployee?.teamLead?._id,
					manager: selectedEmployee?.manager?._id,
					designation: selectedEmployee?.designation?._id,
				})
			);
		} else {
			setIsEdit(false);
			setBreadcrumbState([
				{ name: 'Employees', route: '/employees', isLink: true },
				{ name: 'Add Employee', route: '', isLink: false },
			]);
		}
	}, []);

	const onSubmit = () => {
		const isValid = onValidation();
		const formattedCourseOrCertification = Array?.from(
			formState?.courseOrCertification,
			(obj) => obj?.value
		);
		const data = {
			name: formState?.name?.value,
			emailId: formState?.emailId?.value,
			contactNo: {
				no: formState?.contactNo?.value,
				countryCode: formState?.countryCode?.value,
			},
			alternateNo: formState?.alternateNo?.value
				? {
						no: formState?.alternateNo?.value,
						countryCode: formState?.altCountryCode?.value,
				  }
				: '',
			gender: formState?.gender?.value,
			doj: formState?.doj?.value,
			skills: Array.from(formState?.skills?.value, (obj) => obj?._id),
			// considerTech: formState?.considerTech?.value,
			// teamLead: formState?.teamLead?.value,
			manager: formState?.manager?.value,
			fissionId: formState?.fissionId?.value,
			designation: formState?.designation?.value,
			experience: formState?.experience?.value,
			courseOrCertification: !formattedCourseOrCertification?.length
				? []
				: formattedCourseOrCertification,
			domain: formState?.domain?.value,
			isTrainer: formState?.isTrainer?.value,
			file: formState?.uploadedFile?.value[0]
				? formState?.uploadedFile?.value[0]?.file
				: '',
			fileName: formState?.uploadedFile?.value[0]
				? formState?.uploadedFile?.value[0]?.fileName
				: '',
		};
		const employee = formDataConversion(data);
		if (isValid) {
			if (isEdit) {
				employeeService
					.updateEmployee(employee, state?._id)
					.then((res) => {
						toast.success(res?.message);
						navigate('/employees');
					})
					.catch((err) => toast.error(err?.details));
			} else {
				employeeService
					.createEmployee(employee)
					.then((res) => {
						toast.success(res?.message);
						navigate('/employees');
					})
					.catch((err) => {
						toast.error(err?.details);
					});
			}
		}
	};

	return (
		<>
			<BreadCrumb data={breadcrumbState} />
			<h4 className="headerStyle ms-1">
				{isEdit ? `${EMPLOYEE.editEmployee}` : `${EMPLOYEE.addEmployees}`}
			</h4>
			<Card className="py-4 mb-2 px-4">
				<div className="col-12 row">
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.name} {''}
							<span className="error">*</span>
						</label>
						<input
							type="text"
							className={`styled-input ${isEdit ? 'read-only-field' : ''} ${
								formState?.name?.error ? 'error' : ''
							}`}
							placeholder="Employee Name"
							name="Employee Name"
							id="name"
							required
							readOnly={isEdit}
							value={formState.name.value}
							onChange={handleChange}
							autoComplete="off"
						/>
						<p className="errorText">
							{formState.name.error ? formState.name.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.emailId} {''}
							<span className="error">*</span>
						</label>
						<input
							placeholder="Email Id"
							id="emailId"
							name="Email Id"
							className={`styled-input ${isEdit ? 'read-only-field' : ''} ${
								formState?.emailId?.error ? 'error' : ''
							}`}
							type="email"
							required
							readOnly={isEdit}
							defaultValue={formState.emailId.value}
							onChange={handleChange}
							autoComplete="off"
						/>
						<p className="errorText">
							{formState.emailId.error ? formState.emailId.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.contactNo} {''}
							<span className="error">*</span>
						</label>
						<div className="d-flex">
							<select
								id="countryCode"
								value={formState.countryCode.value}
								className={'styled-input countryCode'}
								onChange={(event) => handleChange(event)}
							>
								<>
									{options?.countryCodeOptions.map((data, index) => (
										<option value={data} key={index}>
											{data}
										</option>
									))}
								</>
							</select>
							<input
								placeholder="Contact number"
								className={`styled-input contactValue ${
									formState?.contactNo?.error ? 'error' : ''
								}`}
								name="Contact number"
								id="contactNo"
								type="text"
								pattern="[6789]{1}[0-9]{9}"
								required
								value={formState.contactNo.value || ''}
								onChange={handleChange}
								maxLength="10"
								minLength="10"
								autoComplete="off"
							/>
						</div>
						<p className="errorText">
							{formState.contactNo.error ? formState.contactNo.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.alternateNo} {''}
						</label>
						<div className="d-flex">
							<select
								id="altCountryCode"
								value={formState.altCountryCode.value}
								className="styled-input countryCode"
								onChange={(event) => handleChange(event)}
							>
								{options?.countryCodeOptions.map((data, index) => (
									<option value={data} key={index}>
										{data}
									</option>
								))}
							</select>
							<input
								placeholder="Alternate number"
								id="alternateNo"
								name="Alternate number"
								className={`styled-input contactValue ${
									formState?.alternateNo?.error ? 'error' : ''
								}`}
								type="text"
								maxLength="10"
								minLength="10"
								defaultValue={formState.alternateNo.value}
								onChange={handleChange}
								autoComplete="off"
							/>
						</div>
						<p className="errorText">
							{formState?.alternateNo?.error
								? formState.alternateNo.errorMsg
								: ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.gender} {''}
							<span className="error">*</span>
						</label>
						{/* <input type="text"> */}
						<select
							className={`styled-input  ${
								isEdit ? ' gender-read-only read-only-field' : ''
							} ${formState?.gender?.error ? 'error' : ''}`}
							name="Gender"
							id="gender"
							required
							value={formState?.gender?.value}
							onChange={handleChange}
						>
							<>
								<option disabled value={''}>
									Select
								</option>
								{options?.genderOptions.map((data, index) => (
									<option value={data} key={index}>
										{data}
									</option>
								))}
							</>
						</select>
						<p className="errorText">
							{formState.gender.error ? formState.gender.errorMsg : ''}
						</p>
						{/* <div className="d-flex mt-1">
							<input
								type="file"
								placeholder='helooo'
								name="FileUpload"
								id="fileUpload"
								accept=".pdf, .png, .docx, .doc"
								onChange={uploadHandler}
							/>
							{formState?.uploadedFile?.value[0]?.fileName ? (
								<>
									<div className="uploaded-file ps-1">
										<span className="fw-bold">
											{formState?.uploadedFile?.value[0]?.fileName || ''}
										</span>
									</div>
									<FaTrash
										name="FileUpload"
										id="fileUpload"
										className="delete-file_icon "
										onClick={(event) => {
											setFormState((prev) => ({
												...prev,
												uploadedFile: updateField('Employe Profile', event.target.id, []),
											}));
										}}
									/>
								</>
							) : (
								''
							)}
						</div> */}
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.doj} <span className="error">*</span>
						</label>
						<div className="date-picker-container mt-1">
							<ReactDatePicker
								placeholderText="Date of joining"
								name="Date of joining"
								className={`styled-input session-date ${
									isEdit ? 'read-only-field' : ''
								} ${formState?.doj?.error ? 'error' : ''}`}
								selected={
									formState.doj.value ? new Date(formState.doj.value) : null
								}
								onChange={(date) => dateHandler(date)}
								dateFormat="yyyy-MM-dd"
								required
								readOnly={isEdit}
								id="doj"
								autoComplete="off"
							/>
							<FaCalendarAlt className="calendar-icon" />
						</div>
						<p className="errorText">
							{formState.doj.error ? formState.doj.errorMsg : ''}
						</p>
					</div>

					<div className="col-6">
						<TypeaheadComponent
							className={`styled-input ${
								formState?.skills?.error ? 'error' : ''
							}`}
							multiple={true}
							id="skills"
							name="Skills"
							onChange={(selected) =>
								handleTypeaheadChange('Skills', 'skills', selected)
							}
							options={skillsList}
							labelKey="skillName"
							placeholder="Select skills"
							selected={
								formState?.skills?.value?.length ? formState.skills.value : []
							}
							error={formState.skills.error}
							errorMsg={formState.skills.errorMsg}
						/>
					</div>
					{/* <div className="col-6 mb-3">
						<label>
							{EMPLOYEE.addEmployee.considerTech}
							<span className="error">*</span>
						</label>
						<select
							className="form-select"
							name="considerTech"
							id="considerTech"
							required
							value={
								formState.considerTech.value.length
									? formState.considerTech.value[0]
									: ''
							}
							onChange={handleChange}
						>
							<>
								<option disabled value={''}>
									Select
								</option>
								{skillsList?.length &&
									skillsList?.map((data, index) => (
										<option value={data._id} key={index}>
											{data.skillName}
										</option>
									))}
							</>
						</select>
						<p className="error">
							{formState.considerTech.error
								? formState.considerTech.errorMsg
								: ''}
						</p>
					</div> */}
					{/* <div className="col-6 mb-3">
						<label>
							{EMPLOYEE.addEmployee.teamLead} <span className="error">*</span>
						</label>
						<select
							className="form-select"
							id="teamLead"
							name="Team Lead"
							required
							value={formState.teamLead.value}
							onChange={handleChange}
						>
							<>
								<option disabled value={''}>
									Select
								</option>
								{options?.employeeOptions?.length &&
									options?.employeeOptions?.map((data, index) => (
										<option value={data._id} key={index}>
											{data.name}
										</option>
									))}
							</>
						</select>
						<p className="error">
							{formState.teamLead.error ? formState.teamLead.errorMsg : ''}
						</p>
					</div> */}
					<div className="col-6">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.manager} {''}
							<span className="error">*</span>
						</label>
						<select
							className={`styled-input ${
								formState?.manager?.error ? 'error' : ''
							}`}
							id="manager"
							name="Manager"
							required
							value={formState.manager.value ? formState.manager.value : ''}
							onChange={handleChange}
						>
							<>
								<option disabled value={''}>
									Select
								</option>
								{managerList.length &&
									managerList.map((data, index) => (
										<option value={data._id} key={index}>
											{data.name}
										</option>
									))}
							</>
						</select>
						<p className="errorText">
							{formState.manager.error ? formState.manager.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.fissionId} {''}
							<span className="error">*</span>
						</label>
						<input
							className={`styled-input mt-1${isEdit ? 'read-only-field' : ''} ${
								formState?.fissionId?.error ? 'error' : ''
							}`}
							placeholder="Fission Id"
							type="text"
							name="Fission Id"
							required
							readOnly={isEdit}
							defaultValue={formState.fissionId.value}
							id="fissionId"
							onChange={handleChange}
							autoComplete="off"
						/>
						<p className="errorText">
							{formState.fissionId.error ? formState.fissionId.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.designation} {''}
							<span className="error">*</span>
						</label>
						<select
							className={`styled-input ${
								formState?.designation?.error ? 'error' : ''
							}`}
							id="designation"
							name="Designation"
							required
							value={
								formState.designation.value ? formState.designation.value : ''
							}
							onChange={handleChange}
						>
							<>
								<option disabled value={''}>
									Select
								</option>
								{Designation?.length &&
									Designation.map((data, index) => (
										<option value={data._id} key={index}>
											{data.name}
										</option>
									))}
							</>
						</select>
						<p className="errorText">
							{formState.designation.error
								? formState.designation.errorMsg
								: ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.experience} {''}
							<span className="error">*</span>
						</label>
						<input
							type="number"
							placeholder="Experience"
							name="Experience"
							className={`styled-input mt-1 ${
								formState?.experience?.error ? 'error' : ''
							}`}
							id="experience"
							required
							autoComplete="off"
							defaultValue={formState.experience.value}
							onChange={handleChange}
						/>
						<p className="errorText">
							{formState.experience.error ? formState.experience.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.domain} {''}
							<span className="error">*</span>
						</label>
						<select
							className={`styled-input ${
								formState?.domain?.error ? 'error' : ''
							}`}
							id="domain"
							name="Expertise"
							required
							value={formState.domain.value}
							onChange={handleChange}
						>
							<>
								<option disabled value={''}>
									Select
								</option>
								{options?.domainOptions.map((data, index) => (
									<option value={data} key={index}>
										{data}
									</option>
								))}
							</>
						</select>
						<p className="errorText">
							{formState.domain.error ? formState.domain.errorMsg : ''}
						</p>
					</div>
					<div className="col-8 mb-2">
						<label className="inputLabel">Employee Profile</label>
						<div className="d-flex mt-1 mb-1">
							<input
								className="chooseFile"
								type="file"
								ref={inputRef}
								name="FileUpload"
								id="fileUpload"
								accept=".png, .jpg, .jpeg"
								onChange={uploadHandler}
							></input>
							<input
								placeholder="Upload File"
								className={'styled-input employeeUploadInputField form-select'}
								type="text"
								disabled
								value={formState?.uploadedFile?.value[0]?.fileName || ''}
								autoComplete="off"
								style={{
									backgroundColor: formState?.uploadedFile?.value[0]?.fileName
										? 'rgb(239 239 239)'
										: 'white',
								}}
							></input>
							<FaTrash
								name="FileUpload"
								id="fileUpload"
								className="delete-file_icon"
								onClick={(event) => {
									setFormState((prev) => ({
										...prev,
										uploadedFile: updateField(
											'Employee Profile',
											event.target.id,
											[]
										),
									}));
									inputRef.current.value = '';
								}}
							/>
						</div>
						<p className="errorText">
							{' '}
							{fileTypeError
								? '* Invalid file type. Please select a JPG, JPEG, PNG file.'
								: ''}
						</p>
					</div>
					{/* <p className="error">
						{formState?.uploadedFile?.error
							? formState.uploadedFile.errorMsg
							: ''}
					</p> */}
					<div className="col-4 mt-4">
						<div className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								name="Is Trainer"
								id="isTrainer"
								checked={formState.isTrainer.value}
								onChange={handleChange}
							/>
							<label className="inputLabel" htmlFor="exampleCheckbox">
								Is Trainer
							</label>
						</div>
					</div>

					<div className="col-12">
						<label className="inputLabel">
							{EMPLOYEE.addEmployee.courseOrCertification}
						</label>
						{formState?.courseOrCertification?.map((field, index) => (
							<div className="row" key={index}>
								<div className="col-11">
									<input
										type="text"
										className="styled-input"
										placeholder="Provide Certifications"
										value={field?.value}
										onChange={(e) =>
											courseOrCertificationHandler(index, e.target.value)
										}
									/>
								</div>
								<div className="col-1 d-flex justify-content-center align-items-center">
									{(formState?.courseOrCertification?.length === 1 ||
										formState.courseOrCertification.length - 1 === index) && (
										<AiFillPlusCircle
											onClick={addCourseOrCertification}
											className="plusIconStyle"
										/>
									)}
									{formState.courseOrCertification.length - 1 !== index && (
										<AiFillMinusCircle
											onClick={() => removeCourseOrCertification(index)}
											className="minusIconStyle"
										/>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			</Card>
			<Card className="position-sticky bottom-0">
				<div className="d-flex justify-content-center p-2">
					<button
						type="button"
						className="positiveButton btnStyle"
						onClick={() => onSubmit()}
					>
						{BUTTONS.save}
					</button>
					<button
						type="button"
						className="btnStyle negativeButton ms-4"
						onClick={() => onCancel()}
					>
						{BUTTONS.cancel}
					</button>
				</div>
			</Card>
		</>
	);
};

export default EmployeeForm;
