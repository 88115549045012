/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FaTrash } from 'react-icons/fa';
import AssessmentService from '../../services/assessmentService';
import './Styles.css';

const TraineeAssessmentSubmissionFormModal = ({
	modalState,
	updateModalState,
	selectedAssessment,
	selectedBatchId,
	traineeSubmittedAssessmentInfoFToResubmit,
	getTraineeAssessmentScores,
}) => {
	const [traineeId, setTraineeId] = useState();
	const [formValues, setFormValues] = useState({
		githubUrls: [],
		description: '',
		uploadedFile: [],
	});
	const [errors, setErrors] = useState({
		githubUrl: null,
		description: null,
		file: null,
	});
	const handleDeleteValue = (index) => {
		const newGithubUrls = formValues.githubUrls.filter(
			(_, idx) => idx !== index
		);
		setFormValues((prevValues) => ({
			...prevValues,
			githubUrls: newGithubUrls,
		}));

		const hasGithubUrls = newGithubUrls.length > 0;
		setErrors((prevErrors) => ({
			...prevErrors,
			githubUrl: hasGithubUrls
				? null
				: '* At least one GitHub URL is required.',
		}));
	};

	const handleDescriptionChange = (event) => {
		if (event.target.value?.length) {
			setErrors((prevErrors) => ({
				...prevErrors,
				description: null,
			}));
		}
		setFormValues((prevValues) => ({
			...prevValues,
			description: event.target.value,
		}));
	};

	const MAX_FILE_SIZE = 4608; // 4.5MB in kilobytes

	const getFileSizeInKiloBytes = (base64String) => {
		const stringLength = base64String.length - 'data:image/png;base64,'.length;
		const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
		const sizeInKiloBytes = sizeInBytes / 1024;
		return sizeInKiloBytes;
	};

	const uploadHandler = async (event) => {
		const fileList = event.target.files;

		if (formValues?.uploadedFile?.length > 2) {
			setErrors((prevErrors) => ({
				...prevErrors,
				file: '* You can only add up to 3 Files.',
			}));
			return;
		}
		setErrors((prevErrors) => ({
			...prevErrors,
			file: null,
		}));

		const uploadedFiles = formValues?.uploadedFile || [];
		const totalSizeKiloBytes = uploadedFiles.reduce(
			(accumulator, file) =>
				accumulator + getFileSizeInKiloBytes(file.fileUploadUrl),
			0
		);

		const newFiles = Array.from(fileList);
		const newFilesSizePromises = newFiles.map(async (file) => {
			const base64 = file;
			return getFileSizeInKiloBytes(base64);
		});
		const newFilesSizes = await Promise.all(newFilesSizePromises);
		const newFilesSizeKiloBytes = newFilesSizes.reduce(
			(accumulator, size) => accumulator + size,
			0
		);

		if (totalSizeKiloBytes + newFilesSizeKiloBytes > MAX_FILE_SIZE) {
			setErrors((prevErrors) => ({
				...prevErrors,
				file: '* Total file size exceeds the limit',
			}));
			return;
		}
		setErrors((prevErrors) => ({
			...prevErrors,
			file: null,
		}));

		const uploadedFilesDataPromises = newFiles.map(async (file) => {
			const base64 = file;
			return { fileName: file.name, fileUploadUrl: base64 };
		});
		const uploadedFilesData = await Promise.all(uploadedFilesDataPromises);

		setFormValues((prevValues) => ({
			...prevValues,
			uploadedFile: [...uploadedFiles, ...uploadedFilesData],
		}));
	};

	const isValidUrl = (str) => {
		try {
			const url = new URL(str);
			return (
				url.protocol === 'https:' &&
				url.hostname === 'github.com' &&
				url.pathname.split('/').length > 2
			);
		} catch (err) {
			return false;
		}
	};

	const handleAddValue = (event) => {
		event.preventDefault();
		const input = event.target.previousSibling;
		const value = input.value.trim();

		if (value !== '') {
			const isDuplicate = formValues?.githubUrls?.includes(value);
			if (isDuplicate) {
				setErrors({
					...errors,
					githubUrl: '* This GitHub URL has already been added.',
				});
			} else if (formValues?.githubUrls?.length >= 3) {
				setErrors({
					...errors,
					githubUrl: '* You can only add up to 3 Github URLs.',
				});
			} else if (!isValidUrl(value)) {
				setErrors({
					...errors,
					githubUrl: '* Please enter a valid Github URL.',
				});
			} else {
				setErrors({ ...errors, githubUrl: null });
				setFormValues((prevValues) => ({
					...prevValues,
					githubUrls: [...(prevValues?.githubUrls || []), value],
				}));
				input.value = '';
			}
		} else {
			setErrors({ ...errors, githubUrl: '* Github URL cannot be empty.' });
		}
	};

	const handleInputChange = (event, index) => {
		const value = event.target.value.trim();
		if (!value) {
			setErrors((prevErrors) => ({
				...prevErrors,
				githubUrl: '* Github URL is required',
			}));
		} else if (!isValidUrl(value)) {
			setErrors((prevErrors) => ({
				...prevErrors,
				githubUrl: '* Please enter a valid Github URL',
			}));
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				githubUrl: null,
			}));
		}
		setFormValues((prevValues) => {
			const newGithubUrls = [...prevValues.githubUrls];
			newGithubUrls[index] = value;
			return {
				...prevValues,
				githubUrls: newGithubUrls,
			};
		});
	};

	const validateForm = (formValues) => {
		const errors = {};

		if (formValues?.githubUrls?.length === 0) {
			errors.githubUrl = '* At least one GitHub URL is required';
		}

		if (formValues?.description?.trim() === '') {
			errors.description = '* Description is required';
		}

		if (formValues?.uploadedFile?.length === 0) {
			errors.file = '* At least one File is required';
		}
		if (formValues?.uploadedFile?.length > 3) {
			errors.file = '* You can upload only 3 Files';
		}

		return errors;
	};

	function handleFileDelete(name) {
		const filteredFiles = formValues?.uploadedFile.filter(
			(file) => file.fileName !== name
		);
		setFormValues((prevValues) => ({
			...prevValues,
			uploadedFile: filteredFiles,
		}));
		if (filteredFiles?.length === 0) {
			setErrors((prevErrors) => ({
				...prevErrors,
				file: '* At least one file must be selected.',
			}));
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				file: null,
			}));
		}
	}
	const convertToFormdata = (object) => {
		const formdata = new FormData();
		Object.entries(object).forEach(([key, val]) => {
			if (key === 'images') {
				for (const item of val) {
					formdata.append('files', item.fileUploadUrl);
					formdata.append('fileName', item.fileName);
				}
			} else if (Array.isArray(val)) {
				val.forEach((item) => {
					formdata.append(key, JSON.stringify(item));
				});
			} else {
				formdata.append(key, JSON.stringify(val));
			}
		});
		return formdata;
	};
	const handleSubmit = () => {
		// event.preventDefault();
		const errors = validateForm(formValues);
		const data = {
			Trainee: traineeId,
			batchId: selectedBatchId,
			githubLinks: formValues?.githubUrls,
			images: formValues?.uploadedFile,
			description: formValues?.description,
			assessmentId: selectedAssessment?._id,
		};

		const assessmentService = new AssessmentService();

		if (Object.keys(errors).length > 0) {
			setErrors(errors);
		} else if (modalState?.create) {
			const submitData = convertToFormdata(data);
			assessmentService
				.traineeAssessmentSubmission(submitData)
				.then((res) => {
					toast.success('The Assessment is  Submitted Successfully...!');
					updateModalState({
						create: false,
						edit: false,
					});
					setFormValues({});
					getTraineeAssessmentScores();
				})
				.catch((err) => {
					toast.error(err);
				});
		} else {
			const submitData = convertToFormdata(data);
			assessmentService
				.traineeAssessmentReSubmission(submitData)
				.then((res) => {
					toast.success('The Assessment is Re-Submitted Successfully...!');
					updateModalState({
						create: false,
						edit: false,
					});
					setFormValues({});
					getTraineeAssessmentScores();
				})
				.catch((err) => {
					toast.error(err);
				});
		}
	};

	useEffect(() => {
		setTraineeId(localStorage.getItem('userId'));
		if (modalState?.edit) {
			setFormValues(traineeSubmittedAssessmentInfoFToResubmit);
		}
	}, [modalState?.edit]);

	const closeModalHandler = () => {
		updateModalState({ create: false, edit: false });
		setFormValues({});
	};
	return (
		<>
			<Modal
				show={modalState?.create || modalState?.edit}
				onHide={() => closeModalHandler()}
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>{selectedAssessment?.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Form>
							<Form.Group controlId="githubUrl">
								<Form.Label>Github URL</Form.Label>
								<span className="error">*</span>
								{formValues?.githubUrls?.map((value, index) => (
									<div
										className="mb-3 mt-1 d-flex justify-content-around"
										key={index}
									>
										<Form.Control
											type="text"
											value={value}
											onChange={(event) => handleInputChange(event, index)}
										/>
										<Button
											variant="danger"
											onClick={() => handleDeleteValue(index)}
										>
											Delete
										</Button>
									</div>
								))}
								{formValues?.githubUrls?.length >= 3 && (
									<p className="text-danger">
										You can only add up to 3 Github URLs.
									</p>
								)}
								<div className="mb-3 mt-1 d-flex justify-content-around">
									<Form.Control type="text" placeholder="Enter value" />
									<Button variant="success" onClick={handleAddValue}>
										Add
									</Button>
								</div>
								{errors?.githubUrl && (
									<p className="text-danger">{errors.githubUrl}</p>
								)}
							</Form.Group>
							<Form.Group controlId="formFile" className="mb-2">
								<Row>
									<Col
										xs={4}
										className="uploadContainer ms-1 m-1 pt-2 pb-2 text-center align-self-center"
									>
										<input
											type="file"
											multiple
											onChange={uploadHandler}
											accept=".jpg,.jpeg,.png,.gif"
										/>
									</Col>

									<Col xs={6}>
										{formValues?.uploadedFile?.map((file) => (
											<Row
												key={file?.fileName}
												className="justify-content-start align-items-center bg-light border rounded my-2 p-1"
											>
												<Col xs={10}>
													<p className="d-inline me-2">{file?.fileName}</p>
												</Col>
												<Col xs={2}>
													<FaTrash
														className="delete-file_icon pb-2 fs-4"
														onClick={() => handleFileDelete(file?.fileName)}
													/>
												</Col>
											</Row>
										))}
									</Col>
								</Row>
								{errors?.file && <p className="text-danger">{errors.file}</p>}
								{/* {formValues?.uploadedFile?.length > 3 && (
									<p className="text-danger">
										You can only add up to 3 Files.
									</p>
								)} */}
							</Form.Group>

							<Form.Group controlId="description">
								<Form.Label>Description</Form.Label>
								<Form.Control
									as="textarea"
									placeholder="Enter description"
									value={formValues?.description}
									className="descriptionInput"
									onChange={handleDescriptionChange}
								/>
								{errors?.description && (
									<p className="text-danger">{errors?.description}</p>
								)}
							</Form.Group>

							<div className="d-flex mt-4 justify-content-end">
								<Button
									className="cancelBtn"
									onClick={() => closeModalHandler()}
								>
									Cancel
								</Button>
								<Button
									className="submitBtn mx-2"
									type="button"
									onClick={
										moment(selectedAssessment?.deadline).isBefore(
											moment(new Date())
										)
											? () =>
													toast.warning('Deadline Exceeded', {
														autoClose: 2000,
													})
											: handleSubmit
									}
								>
									Submit
								</Button>
							</div>
						</Form>
					</Container>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default TraineeAssessmentSubmissionFormModal;
