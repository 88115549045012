import { makeAPICall } from './api';
import { ApiPath, HttpMethods } from './api/definitions';

class TrainerServices {
	getAllTrainers(searchValue, skip, limit) {
		return makeAPICall(
			`${ApiPath.getAllTrainers}?searchvalue=${searchValue}&skip=${skip}&limit=${limit}`,
			HttpMethods.get,
			'auth'
		);
	}

	getEmployeeById(id, searchValue) {
		return makeAPICall(
			`${ApiPath.getAllEmployees}/${id}?searchvalue=${searchValue}`,
			HttpMethods.get,
			'auth'
		);
	}

	trainerAsTrainee(id, status) {
		return makeAPICall(
			`${ApiPath.trainerAsTrainee}/${id}`,
			HttpMethods.post,
			'auth',
			status
		);
	}

	trainerAsTrainer(id, status) {
		return makeAPICall(
			`${ApiPath.trainerAsTrainer}/${id}`,
			HttpMethods.post,
			'auth',
			status
		);
	}

	getAllTrainersList() {
		return makeAPICall(`${ApiPath.getTrainersList}`, HttpMethods.get, 'auth');
	}
}

export default TrainerServices;
