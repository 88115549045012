import { makeAPICall } from './api';
import { ApiPath, HttpMethods } from './api/definitions';

export const createFeedback = (id, data) =>
	makeAPICall(`${ApiPath.feedbackForm}/${id}`, HttpMethods.post, 'auth', data);

export const getAllBatchBycourse = (formId, courseId) =>
	makeAPICall(
		`${ApiPath.feedbackForm}/getallbatchbycourse/${formId}/${courseId}`,
		HttpMethods.get,
		'auth'
	);

export const getAllBatchByCourseAndForm = (formId, courseId) =>
	makeAPICall(
		`${ApiPath.feedbackForm}/getbatchbycourseandform/${formId}/${courseId}`,
		HttpMethods.get,
		'auth'
	);

export const getAllFeedback = () =>
	makeAPICall(`${ApiPath.feedbackForm}`, HttpMethods.get, 'auth');

export const forwardFeedback = (id, data) =>
	makeAPICall(
		`${ApiPath.feedbackForm}/addorupdatebatchData/${id}`,
		HttpMethods.put,
		'auth',
		data
	);

export const getTraineeTrainerFeedback = (role, userId) =>
	makeAPICall(
		`${ApiPath.feedbackForm}/${role}/${userId}`,
		HttpMethods.get,
		'auth'
	);

export const getTraineeTrainerFeedbackById = (formId, batchId) =>
	makeAPICall(
		`${ApiPath.feedbackForm}/getFeddbackformDetilsByTrainerTrainee/${formId}/${batchId}`,
		HttpMethods.get,
		'auth'
	);

export const submitFeedback = (givenToUserId, data) =>
	makeAPICall(
		`${ApiPath.submitFeedback}/${givenToUserId}`,
		HttpMethods.post,
		'auth',
		data
	);

export const submittedFeedbackData = (data) =>
	makeAPICall(
		`${ApiPath.submittedFeedbackDetails}`,
		HttpMethods.post,
		'auth',
		data
	);

export const editFeedbackFormForAdmin = (formId, data) =>
	makeAPICall(
		`${ApiPath.feedbackForm}/${formId}`,
		HttpMethods.put,
		'auth',
		data
	);

export const getFeedbackFormByIdForAdmin = (id) =>
	makeAPICall(`${ApiPath.feedbackForm}/${id}`, HttpMethods.get, 'auth');
