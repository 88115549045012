/* eslint-disable no-irregular-whitespace */
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import {
	getTraineeTrainerFeedbackById,
	submitFeedback,
	submittedFeedbackData,
} from '../../../../services/feedbackService';
import { BUTTONS, userRoles } from '../../../../constants/AppConstants';
import Card from '../../../../common-components/Card/Card';
import '../FeedbackContainer.css';

const FeedbackFormDetail = () => {
	const [feedbackOn, setFeedbackOn] = useState('');
	const [questions, setQuestions] = useState([]);
	const [givenToUserData, setGivenToUserData] = useState([]);
	const [givenToUserId, setGivenToUserId] = useState('');
	const [submit, setSubmit] = useState(false);
	const [initData, setInitData] = useState([]);
	const [userId, setUserId] = useState(localStorage.getItem('userId'));
	const [alreadySubmitted, setAlreadySubmitted] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();
	const { state } = location;
	const { batchId, _id, formName, batchName } = state;

	const getTraineeTrainerAllFeedbackById = () => {
		getTraineeTrainerFeedbackById(_id, batchId).then((res) => {
			const response = res.data;
			response.map((result) => {
				setFeedbackOn(result?.feedbackOn);
				setGivenToUserData(result?.userData);
				setGivenToUserId(result?.userData[0]?._id);
				const allQuestions = result?.question?.map((el) => {
					const obj = {
						...el,
						rating: 0,
						answer: '',
					};
					return obj;
				});
				setInitData(allQuestions);
				setQuestions(allQuestions);
				return allQuestions;
			});
		});
	};

	useEffect(() => {
		if (!userId) {
			setUserId(localStorage.getItem('userId'));
		}
		getTraineeTrainerAllFeedbackById();
	}, [location]);

	const getSubmittedFeedbackData = () => {
		setSubmit(false);
		const data = {
			formId: _id,
			batchId: batchId,
			givenby: userId,
			givento: givenToUserId || null,
		};
		submittedFeedbackData(data).then((res) => {
			if (!res?.data?.length) {
				setAlreadySubmitted(false);
				setQuestions(initData);
			} else {
				res?.data?.map((el) => {
					if (el.submittion_status === 'Submitted') {
						setAlreadySubmitted(true);
						const formatSubmittedData = el?.data?.map((x, i) => {
							const obj = {
								que: x.question,
								que_No: i + 1,
								answer: x.answer,
								rating: x.rating,
							};
							return obj;
						});
						setQuestions(formatSubmittedData);
					}
					return el;
				});
			}
		});
	};

	useEffect(() => {
		if (givenToUserId?.length || givenToUserId?.length === undefined) {
			getSubmittedFeedbackData();
		}
	}, [givenToUserId]);

	const getNewData = (id, obj) => {
		const data = questions.map((x) => (x._id === id ? { ...x, ...obj } : x));
		setQuestions(data);
	};

	const handleRating = (rate, id, question) => {
		const obj = {
			...question,
			rating: rate,
		};
		getNewData(id, obj);
	};

	const handleChange = (e, id, question) => {
		const obj = {
			...question,
			answer: e.target.value,
		};
		getNewData(id, obj);
	};

	const selectDropDown = (e) => {
		setGivenToUserId(e.target.value);
	};

	const submitFeedbackform = () => {
		const formattedData = questions.map((el) => {
			const obj = {
				question: el.que,
				answer: el.answer,
				rating: el.rating,
			};
			return obj;
		});
		const obj = {
			givento: givenToUserId,
			batchId: batchId,
			feedbackformId: _id,
			data: formattedData,
		};
		submitFeedback(userId, obj).then((res) => {
			if (res.statusCode === 200) {
				toast.success(res?.message);
				navigate('/feedback');
			} else {
				toast.error(res?.message);
			}
		});
	};

	const onSubmit = () => {
		setSubmit(true);
		const submitData = questions.every(
			(question) => question?.answer.length > 0
		);
		if (submitData === true) {
			submitFeedbackform();
		}
	};

	const handleCancelEvent = () => {
		navigate('/feedback');
	};

	return (
		<>
			<div className="mt-4">
				<div className="d-flex justify-content-between align-items-center">
					<h6>
						<span className="title me-1">Form Name :</span> {formName}
					</h6>
					<h6>
						<span className="title me-1">Batch Name :</span> {batchName}
					</h6>
					<h6 className="d-flex align-items-center">
						<span className="title me-2">Feedback On:</span>
						{feedbackOn}
					</h6>
				</div>
				<Card>
					<div className="col-12">
						{[
							userRoles?.trainee,
							userRoles?.trainer,
							userRoles?.manager,
						].includes(feedbackOn) && (
							<div className="d-flex align-items-center ps-4 pt-4">
								<h6 className="givenTorole">{feedbackOn} :</h6>
								{feedbackOn === userRoles.manager ? (
									<span className="pb-2">{givenToUserData[0].name}</span>
								) : (
									<select
										className="form-select selectRole"
										id="gender"
										required
										onChange={selectDropDown}
									>
										<>
											{givenToUserData?.map((user, index) => (
												<option value={user._id} key={index}>
													{user.name}
												</option>
											))}
										</>
									</select>
								)}
							</div>
						)}
						{questions?.map((question, index) => (
							<div
								key={index}
								className="d-flex align-items-center questionTable"
							>
								<h6 className="col-4">
									{question?.que_No}
									{'. '}
									{question?.que}
								</h6>
								<div className="col-2">
									<Rating
										id="rating"
										size={25}
										initialValue={question?.rating ? question?.rating : 0}
										allowFraction={true}
										readonly={!!alreadySubmitted}
										onClick={(e) => handleRating(e, question?._id, question)}
									/>
									{submit &&
										(question?.rating === 0 ? (
											<p className="error mt-1"> {'Rating is required'} </p>
										) : (
											''
										))}
								</div>
								<div className="col-6 my-3">
									<Form.Control
										as="textarea"
										placeholder="Feedback"
										id={index}
										value={question?.answer?.length ? question?.answer : ''}
										readOnly={!!alreadySubmitted}
										onChange={(e) => handleChange(e, question?._id, question)}
									/>
									{submit &&
										(question?.answer.length === 0 ? (
											<p className="error"> {'feedback is required'} </p>
										) : (
											''
										))}
								</div>
							</div>
						))}
					</div>
					<div className="d-flex justify-content-end py-3 px-3">
						<span className={alreadySubmitted && 'disabledButton'}>
							<button
								type="button"
								className="btn form_submit-button"
								onClick={onSubmit}
								disabled={alreadySubmitted}
							>
								{BUTTONS.submit}
							</button>
						</span>
						<button
							type="button"
							className="btn form_cancel-button ms-3"
							onClick={handleCancelEvent}
						>
							{BUTTONS.cancel}
						</button>
					</div>
				</Card>
			</div>
		</>
	);
};

export default FeedbackFormDetail;
