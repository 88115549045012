import { makeAPICall } from './api';
import { HttpMethods, ApiPath } from './api/definitions';

class BatchService {
	getAllBatches() {
		return makeAPICall(ApiPath.getAllBatches, HttpMethods.get, 'auth');
	}

	getAllBatchesyeardomain(obj) {
		return makeAPICall(
			`${ApiPath.getAllBatches}/domain`,
			HttpMethods.post,
			'auth',
			obj
		);
	}

	createBatch(data, id) {
		return makeAPICall(
			`${ApiPath.createBatch}/${id}`,
			HttpMethods.post,
			'auth',
			data
		);
	}

	getBatchesByStatus(status, searchValue, skip, limit) {
		return makeAPICall(
			`${ApiPath.filterBatchByStatus}?searchvalue=${searchValue}&skip=${skip}&limit=${limit}`,
			HttpMethods.post,
			'auth',
			status
		);
	}

	getBatchById(id) {
		return makeAPICall(
			`${ApiPath.getAllBatches}/${id}`,
			HttpMethods.get,
			'auth'
		);
	}

	updateBatch(data, id) {
		return makeAPICall(
			`${ApiPath.updateBatch}${id}`,
			HttpMethods.put,
			'auth',
			data
		);
	}

	deleteBatch(id) {
		return makeAPICall(
			`${ApiPath.deleteBatch}${id}`,
			HttpMethods.delete,
			'auth'
		);
	}

	getTrainerBatchesByStatus(
		trainerId,
		batchType,
		batchStatus,
		searchValue,
		skip,
		limit
	) {
		if (batchType === 'AllBatches') {
			return makeAPICall(
				`${
					ApiPath.getTrainerBatchesByStatus
				}/${trainerId}/${batchType}/${batchStatus}?searchvalue=${searchValue}&skip=${
					skip || ''
				}&limit=${limit || ''}`,
				HttpMethods.get,
				'auth'
			);
		}
		return makeAPICall(
			`${ApiPath.getTrainerBatchesByStatus}/${trainerId}/${batchType}/${batchStatus}`,
			HttpMethods.get,
			'auth'
		);
	}

	getTraineeBatchesByStatus(traineeId, batchStatus, searchValue) {
		return makeAPICall(
			`${ApiPath.getTraineeBatchesByStatus}/${traineeId}/${batchStatus}?searchvalue=${searchValue}`,
			HttpMethods.get,
			'auth'
		);
	}

	getTraineeDetailsByBatchId(batchId) {
		return makeAPICall(
			`${ApiPath.getTraineeDetailsByBatchId}/${batchId}`,
			HttpMethods.get,
			'auth'
		);
	}
}

export default BatchService;
