import React from 'react';
import { Row, Col } from 'react-bootstrap';

export function ViewPageRow({
	labelOne,
	labelOneValue,
	labelTwo,
	labelTwoValue,
}) {
	return (
		<Row className="descriptionViewRowWrapper">
			<Col sm={2}>
				<p className="descriptionViewRowWrapperLabel">{labelOne}</p>
			</Col>
			<Col sm={1}>
				<p className="columnColor">:</p>
			</Col>
			<Col sm={3}>
				<p className="fw-normal batch-description_values">{labelOneValue}</p>
			</Col>
			<Col sm={2}>
				<p className="descriptionViewRowWrapperLabel">{labelTwo}</p>
			</Col>
			<Col sm={1}>
				<p className="columnColor">:</p>
			</Col>
			<Col sm={3}>
				<p className="fw-normal batch-description_values">{labelTwoValue}</p>
			</Col>
		</Row>
	);
}

export function ViewPageSingleRow({ label, value }) {
	return (
		<Row className="descriptionViewRowWrapper">
			<Col sm={2}>
				<p className="descriptionViewRowWrapperLabel">{label}</p>
			</Col>
			<Col sm={1}>
				<p className="columnColor">:</p>
			</Col>
			<Col sm={8}>
				<p className="descriptionViewRowWrapperLabelValue"> {value}</p>{' '}
			</Col>
		</Row>
	);
}
