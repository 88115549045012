import React from 'react';
import './Profile.css';
import { EMPLOYEE } from '../../constants/AppConstants';
import Card from '../Card/Card';
import profile from '../../assets/profile.png';

export const Profile = (props) => {
	const {
		name,
		fissionId,
		emailId,
		designation,
		experience,
		contactNo,
		doj,
		manager,
		courseOrCertification,
		fileUploadUrl,
		domain,
		skills,
	} = props?.employee ?? {};
	return (
		<Card className="px-5 pt-3 pb-5 profileCard">
			<div className="mb-2 text-start">
				<h6 className="">{props?.title}</h6>
			</div>
			<div className="w-110">
				<img
					className="employee-profile-image"
					src={fileUploadUrl || profile}
					alt="Employee Profile Image"
				/>
				<div className="text-start mt-3 mb-2 ">
					<span className="titleInView">
						{EMPLOYEE.employeeProfile.employeeName}
					</span>
					<div className="titleInViewValues">
						<span>{name || 'No Data'}</span>
					</div>
				</div>
				<div className="text-start mb-2">
					<span className="titleInView">{EMPLOYEE.employeeProfile.flId}</span>
					<div className="titleInViewValues">
						<span>{fissionId || 'No Data'}</span>
					</div>
				</div>
				<div className="text-start mb-2">
					<span className="titleInView">
						{EMPLOYEE.employeeProfile.emailId}
					</span>
					<div className="titleInViewValues">
						<span>{emailId || 'No Data'}</span>
					</div>
				</div>
				<div className="text-start mb-2">
					<span className="titleInView">{EMPLOYEE.employeeProfile.domain}</span>
					<div className="titleInViewValues">
						<span>{domain || 'No Data'}</span>
					</div>
				</div>
				<div className="text-start mb-2">
					<span className="titleInView">
						{EMPLOYEE.employeeProfile.designation}
					</span>
					<div className="titleInViewValues">
						<span>{designation?.name || 'No Data'}</span>
					</div>
				</div>
				<div className="text-start mb-2">
					<span className="titleInView">
						{EMPLOYEE.employeeProfile.experience}
					</span>
					<div className="titleInViewValues">
						<span>
							{experience?.length && experience === '1'
								? `${experience} Year`
								: `${experience} Years`}
						</span>
					</div>
				</div>
				<div className="text-start mb-2">
					<span className="titleInView">
						{EMPLOYEE.employeeProfile.contactNumber}
					</span>
					<div className="titleInViewValues">
						<span>
							{contactNo
								? `${contactNo.countryCode}  ${contactNo.no}`
								: 'No Data'}
						</span>
					</div>
				</div>
				<div className="text-start mb-2">
					<span className="titleInView">
						{EMPLOYEE.employeeProfile.joiningDate}
					</span>
					<div className="titleInViewValues">
						<span>{doj || 'No Data'}</span>
					</div>
				</div>
				<div className="text-start mb-2">
					<span className="titleInView">
						{EMPLOYEE.employeeProfile.manager}
					</span>
					<div className="titleInViewValues">
						<span>{manager?.name ? manager?.name : 'No Data'}</span>
					</div>
				</div>
			</div>
			{courseOrCertification?.length ? (
				<div className="text-start mb-2">
					<span className="titleInView">
						{EMPLOYEE.employeeProfile.courseOrCertification}
					</span>
					<div className="titleInViewValues">
						<span>
							{courseOrCertification?.length
								? courseOrCertification.toString()
								: 'No Data'}
						</span>
					</div>
				</div>
			) : (
				'No Certifications Found'
			)}
			<div className="text-start">
				<span className="titleInView">{EMPLOYEE.skills}</span>
				<div className="titleInViewValues d-flex flex-wrap w-100">
					{skills.map((skill, index) => (
						<div
							key={index}
							className={`col-md-${
								skill.skillName.length > 20 ? '12' : 'auto'
							} mb-2 ms-1`}
						>
							<span className="chip px-2 text-white">{skill.skillName}</span>
						</div>
					))}
				</div>
			</div>
		</Card>
	);
};
