import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import { SHAREFEEDBACKTO } from '../../constants/AppConstants';
import { forwardFeedback } from '../../services/feedbackService';
import CustomModal from '../../common-components/CustomModal/CustomModal';
import CustomTable from '../../common-components/CustomTable/CustomTable';

function ShareFeedback({
	modalState,
	formInfo,
	courseHandler,
	allPrograms,
	updateModalState,
	formattedBatchList,
}) {
	const [formattedBatches, setFormattedBatches] = useState([]);
	const [selectedBatches, setSelectedBatches] = useState([]);

	const formatBatchesToShareFeedback = (allBatches) => {
		const currentDate = moment().toISOString();
		const selectedList = allBatches.map((batch) => {
			const updatedBatch = { ...batch };

			if (batch.TraineeStatus && !batch.Trainee) {
				updatedBatch.Trainee = currentDate;
			}

			if (batch.TrainerStatus && !batch.Trainer) {
				updatedBatch.Trainer = currentDate;
			}
			if (batch.ManagerStatus && !batch.Manager) {
				updatedBatch.Manager = currentDate;
			}
			delete updatedBatch.name;
			return updatedBatch;
		});
		setSelectedBatches(selectedList);
	};

	const handleChecked = (e, batchId) => {
		const { checked } = e.target;
		const updatedBatches = formattedBatches.map((batch) => {
			if (batch.batchId === batchId) {
				return {
					...batch,
					[`${e.target.id}Status`]: checked,
					status: 'Forwarded',
				};
			}
			return batch;
		});
		formatBatchesToShareFeedback(updatedBatches);
		setFormattedBatches(updatedBatches);
	};

	const onClosePopup = () => updateModalState(false);

	const filteredKeys = Object.entries(SHAREFEEDBACKTO).filter(
		([, value]) => value !== formInfo.feedbackOn
	);

	const tableHeader = filteredKeys.map(([key, value]) => (
		<th className="text-secondary table-header-cell" key={key}>
			{value}
		</th>
	));

	useEffect(() => {
		setFormattedBatches(formattedBatchList);
	}, [formattedBatchList]);

	const shareFeedback = () => {
		forwardFeedback(formInfo?._id, selectedBatches).then((res) => {
			if (res.statusCode === 200) {
				toast.success(res?.message);
				updateModalState(false);
			} else {
				toast.error(res?.message);
			}
		});
	};

	return (
		<CustomModal
			modalState={modalState}
			closeModalHandler={onClosePopup}
			size={'lg'}
			modalHeader={formInfo.formName}
		>
			<Row className="text-align-center">
				<Col sm={2}>
					<p className="fw-bolder mt-2">{'Feedback On'}</p>
				</Col>
				<Col sm={1}>
					<p className="fw-bolder mt-2">:</p>
				</Col>
				<Col sm={3}>
					<p className="mt-2">{formInfo?.feedbackOn}</p>
				</Col>
				<Col sm={2}>
					<p className="fw-bolder mt-2">{'Select Course'}</p>
				</Col>
				<Col sm={1}>
					<p className="fw-bolder mt-2">:</p>
				</Col>
				<Col sm={3}>
					<select
						className="form-select"
						id="gender"
						required
						onChange={courseHandler}
					>
						<>
							{allPrograms?.map((data, index) => (
								<option value={data?._id} key={index}>
									{data?.name}
								</option>
							))}
						</>
					</select>
				</Col>
			</Row>
			<CustomTable header={tableHeader} className={'w-100'}>
				{formattedBatches.length === 0 ? (
					<tr>
						<td colSpan={5} className="text-center text-muted">
							No Batches found
						</td>
					</tr>
				) : (
					formattedBatches.map((batch, index) => (
						<tr key={index} className="text-center">
							<td>{index + 1}</td>
							<td>{batch.name}</td>
							{formInfo.feedbackOn !== 'Trainer' && (
								<td>
									<input
										className="form-check-input mt-3"
										type="checkbox"
										onChange={(event) => handleChecked(event, batch.batchId)}
										id="Trainer"
										checked={batch?.TrainerStatus}
										disabled={batch?.Trainer}
									/>
									<div>
										{batch?.Trainer &&
										Math.abs(moment(batch?.Trainer).diff(moment(), 'days')) !==
											0
											? `${Math.abs(
													moment(batch?.Trainer).diff(moment(), 'days')
											  )} days`
											: null}
									</div>
								</td>
							)}
							{formInfo.feedbackOn !== 'Trainee' && (
								<td>
									<input
										className="form-check-input mt-3"
										type="checkbox"
										onChange={(event) => handleChecked(event, batch.batchId)}
										id="Trainee"
										checked={batch?.TraineeStatus}
										disabled={batch?.Trainee}
									/>
									<span>
										{batch?.Trainee &&
										Math.abs(moment(batch?.Trainee).diff(moment(), 'days')) !==
											0
											? `${Math.abs(
													moment(batch?.Trainee).diff(moment(), 'days')
											  )} days`
											: null}
									</span>
								</td>
							)}
							{formInfo.feedbackOn !== 'Manager' && (
								<td>
									<input
										className="form-check-input mt-3"
										type="checkbox"
										onChange={(event) => handleChecked(event, batch.batchId)}
										id="Manager"
										checked={batch?.ManagerStatus}
										disabled={batch?.Manager}
									/>
									<span>
										{batch?.Manager &&
										Math.abs(moment(batch?.Manager).diff(moment(), 'days')) !==
											0
											? `${Math.abs(
													moment(batch?.Manager).diff(moment(), 'days')
											  )} days`
											: null}
									</span>
								</td>
							)}
						</tr>
					))
				)}
			</CustomTable>
			<div className="d-flex justify-content-end mb-2">
				<button
					type="button"
					className="btn form_submit-button me-3"
					onClick={() => shareFeedback()}
				>
					Forward
				</button>
				<button
					type="button"
					className="btn form_cancel-button cancelButton"
					onClick={onClosePopup}
				>
					Cancel
				</button>
			</div>
		</CustomModal>
	);
}

export default ShareFeedback;
