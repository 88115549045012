import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

export const ConfirmationModal = ({
	showPopup,
	header,
	body,
	cancel,
	confirm,
	onConfirm,
	onClosePopup,
}) => {
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		onClosePopup();
	};
	useEffect(() => {
		setShow(showPopup);
	}, [showPopup]);

	return (
		<>
			<Modal show={show} onHide={handleClose} centered>
				<Modal.Header closeButton>
					<Modal.Title>{header}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text-center p-2 m-2">{body}</div>
				</Modal.Body>
				<Modal.Footer>
					<button
						className="btn btn-outline-secondary pt-0 pb-0"
						onClick={handleClose}
					>
						{cancel}
					</button>
					<button
						className="btn btn-outline-danger pt-0 pb-0"
						onClick={onConfirm}
					>
						{confirm}
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
