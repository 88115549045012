import React from 'react';
import Chart from 'react-apexcharts';

export function DonutChart(props) {
	const {
		names,
		donutChartLegend,
		percentages,
		title,
		type,
		traineeIds,
		isEmployeeSpectrumTooltipContentTrue,
		progressDetailsList,
	} = props;

	const colors = [
		'#f08c69',
		'#2B3467',
		'#6D8B74',
		'#EB6440',
		'#C89595',
		'#393E46',
		'#A0C3D2',
	];
	const employeeSpectrumTooltipContent = {
		custom: function ({ series, seriesIndex, w }) {
			return (
				'<div class="arrow_box p-2">' +
				'<div>' +
				`Expertise : ${w.config.labels[seriesIndex]}</div>` +
				`<div>${title}${series[seriesIndex]} %` +
				'</div>' +
				'<div>' +
				`Employee Count : ${traineeIds[seriesIndex]}</div>` +
				'</div>'
			);
		},
	};

	const batchesSpectrumTooltipContent = {
		custom: function ({ seriesIndex, w }) {
			return (
				'<div class="arrow_box p-2">' +
				'<div>' +
				`Batch Name : ${w.config.labels[seriesIndex]}</div>` +
				`<div>${title}${progressDetailsList[seriesIndex]} %` +
				'</div>' +
				'<div>' +
				`Employee Count : ${traineeIds[seriesIndex]}</div>` +
				'<div>' +
				`Trainee Percentage : ${percentages[seriesIndex]} %` +
				'</div>' +
				'</div>'
			);
		},
	};

	return React.createElement(Chart, {
		type: type,
		series: percentages,
		height: '280',
		options: {
			labels: names,
			size: '20%',
			legend: donutChartLegend,
			tooltip: isEmployeeSpectrumTooltipContentTrue
				? employeeSpectrumTooltipContent
				: batchesSpectrumTooltipContent,
			colors: colors,
			opacity: 20,
			innerHeight: '10',
		},
	});
}
