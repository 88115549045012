import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { googleLogout } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import jwtDecode from 'jwt-decode';
import Form from 'react-bootstrap/Form';
import { gapi } from 'gapi-script';
import {
	navigateToScreen,
	setLocalStorageItem,
	updateField,
} from '../../utils/helper';
import { LOGIN } from '../../constants/AppConstants';
import FissionLogo from '../../assets/fission-logo.svg';
import loginPageBGImage from '../../assets/loginPageBGImage.png';
import loginPageBG from '../../assets/loginPageBG.svg';
import googleLogo from '../../assets/googlelogo.svg';
import BackgroundComponents from '../../assets/BackgroundComponents.png';
import UserContext from '../../context/userContext';
import UserLoginServices from '../../services/userLoginServices';

import './Login.css';

let hasRun = false;
export const logOutUser = () => {
	if (hasRun) {
		return;
	}
	hasRun = true;
	googleLogout();
	localStorage.clear();
	// eslint-disable-next-line no-promise-executor-return
	const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
	wait(1000)
		.then(() => {
			toast.warning('Account Logged out !!!', { autoClose: 700 });
			return wait(1000);
		})
		.then(() => {
			window.location.assign('/');
		});
};

export const Login = () => {
	const { setUserLoggedIn, setUserRole } = useContext(UserContext);
	const [user, setUser] = useState('');
	const [formState, setFormState] = useState({
		emailId: {
			value: '',
			error: false,
			errorMsg: '',
		},
		password: {
			value: '',
			error: false,
			errorMsg: '',
		},
	});

	const navigate = useNavigate();
	const obj = new UserLoginServices();

	const storeLocalstorage = async (data) => {
		const jwtTokenDecoded = jwtDecode(data?.token);
		await Promise.all([
			setLocalStorageItem(
				data?.googleToken ? 'accessToken' : 'loginnormal',
				data?.googleToken ? JSON.stringify(data?.googleToken) : true
			),
			setLocalStorageItem('headerToken', data?.token),
			setLocalStorageItem('username', jwtTokenDecoded?.userName),
			setLocalStorageItem('role', jwtTokenDecoded?.userRoles[0]),
			setLocalStorageItem('userId', jwtTokenDecoded?.userId),
		]);

		toast.success('Logging In....', { autoClose: 1000 });
		setTimeout(() => {
			setUser(data?.googleToken);
			setUserLoggedIn(true);
			setUserRole(jwtTokenDecoded?.userRoles[0]);
			navigateToScreen(jwtTokenDecoded?.userRoles[0], navigate);
		}, 2000);
	};

	const validateUser = async () => {
		gapi.auth2
			.getAuthInstance()
			.signIn()
			.then(async (res) => {
				const data = {
					token: res.xc.id_token,
				};
				try {
					const resData = await obj.validateUserLogin(data);
					if (resData.statusCode === 401) {
						toast.error('Unauthorized User', { autoClose: 1000 });
					} else {
						const credentials = {
							googleToken: JSON.stringify(res.xc.id_token),
							token: resData.token,
						};
						storeLocalstorage(credentials);
					}
				} catch (err) {
					if (err.message === 'Invalid token specified') {
						toast.error('Unauthorized User', { autoClose: 1000 });
					}
				}
			});
	};

	useEffect(() => {
		if (user) {
			validateUser(user);
		}
	}, [user]);

	const handleChange = (event) => {
		const { id, value, name } = event.target;
		setFormState((prevState) => ({
			...prevState,
			[id]: updateField(name, id, value),
		}));
	};

	const onValidation = () => {
		let isValid = true;
		const updatedFormState = { ...formState };
		// Validate
		Object.keys(updatedFormState).forEach((key) => {
			updatedFormState[key] = updateField(
				LOGIN?.[key],
				key,
				updatedFormState[key].value
			);
			if (updatedFormState[key]?.error) {
				isValid = false;
			}
		});
		setFormState(updatedFormState);
		return isValid;
	};

	const onsubmit = () => {
		const isValid = onValidation();
		const data = {
			emailId: formState?.emailId?.value,
			password: formState?.password?.value,
		};
		if (isValid) {
			obj.loginEmailPass(data).then(async (res) => {
				if (res.statusCode === 200) {
					const credentials = {
						token: res.token,
					};
					storeLocalstorage(credentials);
				} else if (res.statusCode === 402) {
					toast.error(res?.message, { autoClose: 1000 });
				} else if (res.statusCode === 408) {
					toast.error('Unauthorized User', { autoClose: 1000 });
				}
			});
		}
	};

	return (
		<div className="login-page">
			<div className="fission-labs-logo-container">
				<img
					className="fission-labs-logo"
					src={FissionLogo}
					alt="Fission Labs logo"
				/>
			</div>
			<Row>
				<Col sm={8}>
					<div className="background-container"></div>
					<div className="">
						<div className="image">
							<img
								className="image-container"
								src={loginPageBG}
								alt="Background"
							/>
							<img
								className="background-components"
								src={BackgroundComponents}
								alt="Background component"
							/>
							<img className="login-image" src={loginPageBGImage} alt="login" />
						</div>
					</div>
					<p className="description">
						We are More than Just A Company.
						<br />
						We harness innovation and engineering excellence <br />
						to create symphony between business processes and underlying
						technologies.
					</p>
				</Col>
				<Col className="login-container" sm={3}>
					<h2 className="app-heading">TRAINING ENGINE</h2>
					<h3 className="welcome-heading">Welcome Back!</h3>
					<div className="d-flex row justify-content-flex-start w-75">
						<div>
							<p className="label">
								Email
								<span className="error ms-1">*</span>
							</p>
							<Form.Control
								id="emailId"
								name="Email Id"
								placeholder="Email Address"
								value={formState?.emailId?.value}
								onChange={handleChange}
								className={`styled-input ${
									formState?.emailId?.error ? 'error' : ''
								}`}
							/>
							<p className="errorText">
								{formState?.emailId.error ? formState?.emailId.errorMsg : ''}
							</p>
						</div>
						<div>
							<p className="label">
								Password
								<span className="error ms-1">*</span>
							</p>
							<Form.Control
								id="password"
								name="Password"
								type="password"
								placeholder="Password"
								value={formState?.password?.value}
								onChange={handleChange}
								className={`styled-input ${
									formState?.password?.error ? 'error' : ''
								}`}
							/>
							<p className="errorText">
								{formState?.password.error ? formState?.password?.errorMsg : ''}
							</p>
						</div>
						<div>
							<Button onClick={onsubmit} className="rounded w-100 loginbutton">
								Log in
							</Button>{' '}
						</div>
						<div className="d-flex justify-content-center align-items-center dotted-line">
							<span className="p-2">OR</span>
						</div>
						<div>
							<Button
								onClick={validateUser}
								className="d-flex col justify-content-center gap-4 w-100 googleloginbutton"
							>
								<img src={googleLogo} />
								Sign In Using Google
							</Button>{' '}
						</div>
					</div>
				</Col>
			</Row>
		</div>
	);
};
