import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import { ConfirmationModal } from '../../common-components/ConfirmationModal/ConfirmationModal';
import DataTable from '../../common-components/DataTable/DataTable';
import { PROGRAMS, userRoles } from '../../constants/AppConstants';
import ProgramServices from '../../services/programServices';
import './Styles.css';

export const ProgramTable = ({
	userRole,
	programsList,
	onDeleteConfirm,
	status,
	addToInActiveList,
	handleSearch,
	dropdown,
	onSize,
	onPageNum,
	pageSize,
	pageNum,
	totalNoOfRecords,
	searchedRecords,
	searchValue,
}) => {
	const [selectedData, setSelectedData] = useState('');
	const [selectedCourse, setSelectedCourse] = useState({
		modal: false,
		batchesList: [],
	});
	const [showPopup, setshowPopup] = useState('');
	const navigate = useNavigate();
	const programServices = new ProgramServices();
	const onEdit = (data) => {
		programServices.getProgramById(data._id).then((response) => {
			const responseData = response.data;
			const path = `/course/${responseData?._id}`;
			navigate(path, { state: responseData });
		});
	};
	const closeBatchesModal = () => {
		setSelectedCourse({ modal: false });
	};
	const onDelete = (data) => {
		setSelectedData(data);
		setshowPopup(true);
	};

	const handleEvent = () => {
		const path = `/course/${'addCourse'}`;
		navigate(path, { state: 'courses' });
	};

	const cellClickedListener = (event) => {
		const modalTrue = event.colDef.field === 'batchCount';

		if (!modalTrue && event.value) {
			const path = `/course/${'courseDetails'}/${event.data?._id}`;
			navigate(path, { state: event.data?._id });
		}
	};

	const BatchesColumns = [
		{
			field: 'name',
			headerName: 'Batch Name',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
	];

	const columns = [
		{
			field: 'name',
			headerName: 'Name',
		},
		{
			field: 'duration',
			headerName: 'Duration',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					<span>{`${params.value} Days`}</span>
				</>
			),
		},
		{
			field: 'domain',
			headerName: 'Expertise',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'skillName',
			headerName: 'Skills',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'batchCount',
			headerName: 'Batches',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => {
				const openModal = () => {
					const selectedBatchData = params?.data?.batchdata?.length
						? params.data.batchdata
						: [];
					setSelectedCourse((prevState) => ({
						...prevState,
						batchesList: selectedBatchData,
						modal: true,
					}));
				};
				return (
					<span
						className={params?.value > 0 ? 'average' : ''}
						onClick={params?.value > 0 ? openModal : null}
					>{`${params.value}`}</span>
				);
			},
		},
		{
			field: 'contributers',
			headerName: 'Contributors',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'action',
			headerName: 'Action',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			hide: userRole === userRoles.employee,
			cellRenderer: (params) => (
				<>
					<FaEdit onClick={() => onEdit(params?.data)} className="edit-icon" />
					{/* Soft Delete */}
					{status === 'Active' ? (
						!params.data.batchCount > 0 ? (
							<FaTrash
								onClick={() => onDelete(params?.data)}
								className={'edit-icon delete-icon ms-2'}
							/>
						) : (
							<FaTrash className={'disabled-edit-icon ms-2'} />
						)
					) : null}
					{/* Hard Delete */}
					{status === 'Inactive' ? (
						<FaTrash
							onClick={() => onDelete(params?.data)}
							className="edit-icon delete-icon ms-2"
						/>
					) : null}
				</>
			),
		},
	];

	const onConfirm = () => {
		onDeleteConfirm(selectedData);
		setshowPopup(false);
	};

	const onConfirmToInActiveACourse = () => {
		addToInActiveList(selectedData?._id);
		setshowPopup(false);
	};

	const onClosePopup = () => {
		setshowPopup(false);
		setSelectedData('');
	};

	const getColumns = () => {
		if (userRole === userRoles.trainee) {
			return columns.filter(
				(column) => column.field !== 'action' && column.field !== 'batchCount'
			);
		}
		if (userRole === userRoles.manager) {
			columns.pop();
			return columns;
		}
		return columns;
	};

	const title =
		userRole === userRoles.trainee
			? PROGRAMS.traineePrograms
			: PROGRAMS.programs;

	const paginationChange = (updatedPageNumber, updatedPageSize) => {
		onSize(updatedPageSize);
		onPageNum(updatedPageNumber);
	};

	return (
		<div className="">
			{/* <div className="d-flex justify-content-end">
        <Button className="add-button" onClick={handleEvent}>
          <span>{`+ ${BUTTONS.add}`}</span>
        </Button>
      </div> */}
			<DataTable
				addButton={
					![userRoles.manager, userRoles.trainee, userRoles.employee].includes(
						userRole
					)
				}
				addButtonListner={handleEvent}
				height={500}
				tableTitle={title}
				columns={getColumns()}
				rows={programsList}
				pagination={userRole === 'Trainee'}
				customPagination={userRole !== 'Trainee'}
				dropdown={dropdown}
				enableGlobalSearch={userRole !== userRoles.trainee}
				cellClickedListener={cellClickedListener}
				handleSearch={handleSearch}
				paginationChange={paginationChange}
				currentPage={pageNum}
				size={pageSize}
				totalNoOfRecords={totalNoOfRecords}
				searchedRecords={searchedRecords}
				searchValue={searchValue}
			></DataTable>

			<ConfirmationModal
				showPopup={showPopup}
				header={status === 'Active' ? 'Move' : 'Delete'}
				body={
					status === 'Active'
						? 'Are you sure you want to move this Course to InActive courses'
						: 'Are you sure you want to Delete the Course permanently'
				}
				cancel={'Cancel'}
				confirm={status === 'Active' ? 'Move' : 'Delete'}
				onConfirm={status === 'Active' ? onConfirmToInActiveACourse : onConfirm}
				onClosePopup={onClosePopup}
			/>
			<Modal
				show={selectedCourse.modal}
				onHide={closeBatchesModal}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title className="">Batches List</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text-center p-2 m-2">
						<DataTable
							height={300}
							columns={BatchesColumns}
							rows={
								selectedCourse?.batchesList?.length
									? selectedCourse.batchesList
									: []
							}
							pagination={false}
							enableGlobalSearch={false}
							enableDropdown={true}
						/>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};
