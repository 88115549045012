import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { SIDEBAR } from '../../constants/AppConstants';
import './Sidebar.css';

export const Sidebar = (props) => {
	const [allowedRoutes, setAllowedRoutes] = useState([]);
	// Filter the sidebar routes based on the user's role
	useEffect(() => {
		const routes = SIDEBAR.filter((route) =>
			route.allowedRoles.includes(props?.userProps)
		);
		setAllowedRoutes(routes);
	}, [props?.userProps]);

	return (
		<>
			<Navbar className="sidebar col-12">
				<Nav className="d-flex align-self-start sidebarStyle flex-column w-100">
					{allowedRoutes.map((item) => (
						<NavLink
							to={item.path}
							key={item.label}
							className={'sidebar-styles'}
						>
							<Row className="align-items-start">
								<Col sm="1">
									{item.icon}
									{/* <AiFillFacebook className="fs-5" /> */}
								</Col>
								<Col>
									<div className="sidebarTitles py-2 sidebar-items">
										{item.label}
									</div>
								</Col>
							</Row>
						</NavLink>
					))}
				</Nav>
			</Navbar>
		</>
	);
};
