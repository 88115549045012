import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { ConfirmationModal } from '../../common-components/ConfirmationModal/ConfirmationModal';
import {
	DROPDOWNITEMS,
	DROPDOWNITEMSEMPLOYEES,
	userRoles,
} from '../../constants/AppConstants';
import BatchService from '../../services/batchService';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import { BatchesTable } from './BatchesTable';
import UseSearch from '../../common-components/UseSearch/UseSearch';
import Pagination from '../../common-components/Pagination/Pagination';
import NavBarTabs from '../../common-components/NavbarTabs/NavbarTabs';

const defaultDropdownItem = Object.values(DROPDOWNITEMS)[0];

const Batches = (props) => {
	const [selectedData, setSelectedData] = useState(null);
	const [trainerId] = useState(localStorage.getItem('userId'));
	const [batchStatusDropdown, setBatchStatusDropdown] =
		useState(defaultDropdownItem);
	const [showPopup, setshowPopup] = useState(false);
	const [batchesList, setBatchesList] = useState([]);
	const [initialValue, setInitialValue] = useState(true);
	const [selectedBatch, setSelectedBatch] = useState('MyBatches');
	const batchService = new BatchService();
	const [breadcrumb] = useState([
		{
			name: 'Batches',
			route: '',
			isLink: false,
		},
	]);
	const { debouncedSearchValue, handleSearch, searchValue, setSearchValue } =
		UseSearch('', 500);
	const { pageSize, pageNum, onSize, onPageNum, setPageNum } = Pagination(
		10,
		1
	);

	const batchTabChangeHandler = (batch) => {
		setSearchValue('');
		setSelectedBatch(batch);
		setBatchStatusDropdown(defaultDropdownItem);
	};

	const navigate = useNavigate();
	const onEdit = (data) => {
		batchService.getBatchById(data._id).then((response) => {
			const path = `/batches/${response.data._id}`;
			navigate(path, { state: response.data });
		});
	};
	const selectHandler = (key, event) => {
		setSearchValue('');
		setPageNum(1);
		setBatchStatusDropdown({ status: key, label: event.target.textContent });
	};
	const onDelete = (data) => {
		setSelectedData(data);
		setshowPopup(true);
	};

	const columns = [
		{
			field: 'name',
			headerName: 'Batch Name',
		},
		{
			field: 'Course',
			headerName: 'Course',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			hide: props?.userRole !== userRoles.trainee,
		},
		{
			field: 'startDate',
			headerName: 'Start Date',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'endDate',
			headerName: 'End Date',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'traineeIds',
			headerName: 'Trainees',
			width: 10,
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			hide: props?.userRole === userRoles.trainee,
		},
		{
			field: 'progress',
			headerName: 'Progress',
			width: 10,
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => <>{`${params.value}%`}</>,
		},
		{
			field: 'trainerId',
			headerName: 'Trainer',
			hide: props?.userRole !== userRoles.admin,
		},
		{
			field: 'action',
			headerName: 'Action',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			hide:
				props?.userRole === userRoles.trainee ||
				props?.userRole === userRoles.trainer ||
				props?.userRole === userRoles.employee,
			cellRenderer: (params) => (
				<>
					<FaEdit onClick={() => onEdit(params?.data)} className="edit-icon" />
					{[userRoles.admin, userRoles.manager].includes(props?.userRole) && (
						<FaTrash
							onClick={() => onDelete(params?.data)}
							className="delete-icon ms-2"
						/>
					)}
				</>
			),
		},
	];
	// Format Function
	function formatBatchList(batchList) {
		const formattedList = batchList.map((obj) => ({
			...obj,
			startDate: moment(obj.startDate).format('D MMM YYYY'),
			endDate: moment(obj.endDate).format('D MMM YYYY'),
			traineeIds: obj?.traineeIds?.length,
			trainerId: obj?.trainerId[0]?.name,
		}));
		return formattedList;
	}
	// Admin Manager
	const getAdminAndManagerBatchesByStatus = () => {
		const status = {
			status: batchStatusDropdown.status,
		};
		batchService
			.getBatchesByStatus(status, debouncedSearchValue, pageNum, pageSize)
			.then((res) => {
				const newBatchesList = formatBatchList(res?.data);
				const { datalength, searchlength } = res || {};
				const formattedBatchList = {
					list: newBatchesList?.length ? newBatchesList : [],
					totalRecords: datalength || 0,
					searchedRecords: searchlength || 0,
				};
				setBatchesList(res?.data?.length ? formattedBatchList : []);
			});
	};

	const onDeleteConfirm = (data) => {
		batchService.deleteBatch(data?._id).then((response) => {
			if (response?._id) {
				toast.success('Batch Deleted Successfully...!');
				getAdminAndManagerBatchesByStatus();
			}
		});
	};

	const onConfirm = () => {
		onDeleteConfirm(selectedData);
		setshowPopup(false);
	};

	const onClosePopup = () => {
		setshowPopup(false);
		setSelectedData('');
	};

	const getTrainerBatchesByStatus = () => {
		batchService
			.getTrainerBatchesByStatus(
				trainerId,
				selectedBatch,
				batchStatusDropdown.status,
				debouncedSearchValue,
				pageNum,
				pageSize
			)
			.then((res) => {
				const newBatchesList = formatBatchList(res?.data);
				const formattedBatchList = {
					list: newBatchesList?.length ? newBatchesList : [],
					totalRecords: res?.datalength || 0,
					searchedRecords: res?.searchlength || 0,
				};
				setBatchesList(res?.data?.length ? formattedBatchList : []);
			});
	};

	const getTraineeBatchesByStatus = () => {
		batchService
			.getTraineeBatchesByStatus(
				trainerId,
				batchStatusDropdown.status,
				debouncedSearchValue
			)
			.then((res) => {
				const newBatchesList = formatBatchList(res?.data);
				setBatchesList(res?.data?.length ? newBatchesList : []);
			});
	};

	function fetchBatchesByUserRole(userRole) {
		switch (userRole) {
			case userRoles.admin:
			case userRoles.manager:
				return getAdminAndManagerBatchesByStatus();
			case userRoles.trainer:
				return getTrainerBatchesByStatus();
			case userRoles.trainee:
				return getTraineeBatchesByStatus();
			case userRoles.employee:
				return getAdminAndManagerBatchesByStatus();
			default:
				return Promise.resolve();
		}
	}

	const getColumns = () => {
		if (props?.userRole === userRoles.manager) {
			return columns.filter((column) => column.field !== 'action');
		}
		return columns;
	};

	useEffect(() => {
		fetchBatchesByUserRole(props?.userRole);
	}, [
		batchStatusDropdown.status,
		selectedBatch,
		props?.userRole,
		pageNum,
		pageSize,
	]);

	useEffect(() => {
		if (debouncedSearchValue !== '' && pageNum !== 1) {
			setPageNum(1);
		} else if (!initialValue) {
			fetchBatchesByUserRole(props?.userRole);
		} else {
			setInitialValue(!initialValue);
		}
	}, [debouncedSearchValue]);

	const adminAndManagerDropdownFilter = (
		<DropdownButton
			onSelect={selectHandler}
			title={batchStatusDropdown?.label || batchStatusDropdown}
			className={
				props?.userRole === userRoles.trainer ? 'mx-2 styled-input' : ''
			}
		>
			{(props.userRole === 'Employee'
				? DROPDOWNITEMSEMPLOYEES
				: DROPDOWNITEMS
			).map((key, i) => (
				<Dropdown.Item eventKey={key.status} key={i}>
					{key.label}
				</Dropdown.Item>
			))}
		</DropdownButton>
	);

	const batchesTabs = [
		{ id: 'MyBatches', label: 'My Batches' },
		{ id: 'AllBatches', label: 'All Batches' },
	];

	return (
		<div>
			<ConfirmationModal
				showPopup={showPopup}
				header={'Delete'}
				body={'Are you sure you want to delete'}
				cancel={'Cancel'}
				confirm={'Delete'}
				onConfirm={onConfirm}
				onClosePopup={onClosePopup}
			/>
			<BreadCrumb data={breadcrumb} />
			{props?.userRole === userRoles.trainer && (
				<NavBarTabs
					onCourseChange={batchTabChangeHandler}
					tabs={batchesTabs}
					activeValue={'MyBatches'}
				/>
			)}
			<BatchesTable
				columnNames={getColumns()}
				batchList={batchesList?.list ? batchesList.list : batchesList}
				userRole={props?.userRole}
				title={props?.userRole === userRoles.trainer && selectedBatch}
				handleSearch={handleSearch}
				dropdown={
					[userRoles.admin, userRoles.manager, userRoles.employee].includes(
						props?.userRole
					)
						? adminAndManagerDropdownFilter
						: false
				}
				onSize={onSize}
				onPageNum={onPageNum}
				pageSize={pageSize}
				pageNum={pageNum}
				totalNoOfRecords={batchesList?.totalRecords}
				searchedRecords={batchesList?.searchedRecords}
				searchValue={searchValue}
			></BatchesTable>
		</div>
	);
};

export default Batches;
