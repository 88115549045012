import React, { useEffect, useState } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Card from '../../common-components/Card/Card';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import {
	BATCHES,
	BUTTONS,
	TECHDROPDOWNITEMS,
} from '../../constants/AppConstants';
import ProgramServices from '../../services/programServices';
import TypeaheadComponent from '../../common-components/TypeHead';
import BatchService from '../../services/batchService';
import EmployeeServices from '../../services/employeeService';
import SkillsService from '../../services/skillsService';

export const mapData = (list) =>
	list.map((data) => ({
		name: data?.name || '',
		_id: data?._id || '',
	}));

export const mapSkills = (list) =>
	list.map((data) => ({
		name: data?.skillName || '',
		_id: data?._id || '',
	}));

const BatchForm = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const batchService = new BatchService();
	const employeeObj = new EmployeeServices();
	const programObj = new ProgramServices();
	const skillsObj = new SkillsService();

	const [domainList] = useState(TECHDROPDOWNITEMS.map((item) => item.status));
	const [ReportingManagerOptions, setReportingManagerOptions] = useState([]);
	// const [statusList] = useState(['Acknowledged', 'Completed', 'InProgress']);
	const [ProgramOptions, setProgramOptions] = useState([]);
	const [membersOptions, setMembersOptions] = useState([]);
	const [skillsList, setSkillsList] = useState([]);

	const [state, setState] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [selectedTraineeIds, setSelectedTraineeIds] = useState([]);
	const [selectedTrainer, setSelectedTrainer] = useState({});
	const [courseTrainerdata, setCourseTrainerdata] = useState([]);

	const [formState, setFormState] = useState({
		batchName: {
			value: '',
			error: false,
			errorMsg: '',
		},
		description: {
			value: '',
			error: false,
			errorMsg: '',
		},
		programId: {
			value: '',
			error: false,
			errorMsg: '',
		},
		batchManager: {
			value: '',
			error: false,
			errorMsg: '',
		},
		domain: {
			value: '',
			error: false,
			errorMsg: '',
		},
		// status: {
		// 	value: 'Acknowledged',
		// 	error: false,
		// 	errorMsg: '',
		// },
		skills: {
			value: [],
			error: false,
			errorMsg: '',
		},
		traineeIds: {
			value: [],
			error: false,
			errorMsg: '',
		},
		// trainerId: {
		// 	value: [],
		// 	error: false,
		// 	errorMsg: '',
		// },
		startDate: {
			value: new Date(),
			error: false,
			errorMsg: '',
		},
		endDate: {
			value: new Date(),
			error: false,
			errorMsg: '',
		},
	});

	useEffect(() => {
		skillsObj.getAllSkills().then((response) => {
			setSkillsList(response?.data?.length ? mapSkills(response?.data) : []);
		});

		employeeObj.getManagerList().then((response) => {
			const managerList = response?.data?.length ? mapData(response?.data) : [];
			setMembersOptions(managerList);
			setReportingManagerOptions(managerList);
		});

		programObj.getAllPrograms().then((response) => {
			const Programs = response?.data?.length ? mapData(response?.data) : [];
			setCourseTrainerdata(response?.data);
			setProgramOptions(Programs);
		});
	}, []);

	const generateInitialFormState = (projectData) => {
		const updatedFormState = { ...formState };

		Object.keys(projectData).forEach((key) => {
			if (updatedFormState[key]) {
				if (key === 'endDate' || key === 'startDate') {
					updatedFormState[key].value = projectData[key]
						? new Date(projectData[key])
						: null;
				} else if (key === 'batchManager' || key === 'programId') {
					updatedFormState[key].value = [projectData[key]];
				} else {
					updatedFormState[key].value = projectData[key];
				}
			}
		});

		return updatedFormState;
	};

	useEffect(() => {
		if (location.pathname !== '/batches/batchesForm') {
			setIsEdit(true);
			setState([
				{ name: 'Batch', route: '/batches', isLink: true },
				{ name: 'Edit Batch', route: '', isLink: false },
			]);
			if (location.state) {
				const batchData = location.state;
				const { name, skills, trainerId } = batchData;
				setFormState(
					generateInitialFormState({
						...batchData,
						batchName: name,
						skills: mapSkills(skills),
					})
				);
				setSelectedSkills(formState.skills.value);
				setSelectedTraineeIds(formState.traineeIds.value);
				setSelectedTrainer(trainerId);
			}
			// eslint-disable-next-line no-shadow
		} else {
			setIsEdit(false);
			setState([
				{ name: 'Batches', route: '/batches', isLink: true },
				{ name: 'Add Batch', route: '', isLink: false },
			]);
		}
	}, [
		location,
		skillsList,
		ProgramOptions,
		membersOptions,
		ReportingManagerOptions,
	]);

	const updateField = (name, key, value) => {
		const copy = { ...formState[key] };
		const error = !value.toString().length;
		let errorMsg;
		if (!value.toString().length) {
			if (key === 'traineeIds') {
				errorMsg = `${name} are required`;
			} else {
				errorMsg = `${name} is required`;
			}
		} else {
			errorMsg = '';
		}

		return { ...copy, value, error, errorMsg };
	};

	const handleChange = (event) => {
		const { id, value, name } = event.target;
		setFormState((prevState) => ({
			...prevState,
			[id]: updateField(name, id, value),
		}));
	};

	const dateHandler = (name, datetype, date) => {
		setFormState((prevState) => ({
			...prevState,
			[datetype]: updateField(name, datetype, date),
		}));
	};

	const onValidation = () => {
		let isValid = true;
		const updatedFormState = { ...formState };

		// Validate project name, description, programId, domain, and other fields except table of contents
		Object.keys(updatedFormState).forEach((key) => {
			if (key !== 'startDate' && key !== 'endDate') {
				if (!updatedFormState[key].value.length) {
					updatedFormState[key] = updateField(
						BATCHES.addBatchForm[key],
						key,
						updatedFormState[key].value
					);
					isValid = false;
				}
			} else if (!updatedFormState[key].value) {
				updatedFormState[key] = updateField(
					BATCHES.addBatchForm[key],
					key,
					updatedFormState[key].value
				);
				isValid = false;
			}
		});

		setFormState(updatedFormState);
		return isValid;
	};

	const handleTypeaheadChange = (id, selected) => {
		const updatedField = updateField(BATCHES.addBatchForm[id], id, selected);

		if (id === 'programId') {
			const x = courseTrainerdata?.find((el) => el?._id === selected[0]?._id);
			setSelectedTrainer(x?.trainerId);
		}
		setFormState((prevState) => ({
			...prevState,
			[id]: updatedField,
		}));
		if (id === 'skills') {
			setSelectedSkills(selected);
		} else if (id === 'traineeIds') {
			setSelectedTraineeIds(selected);
		}
	};

	const [isSubmitted, setIsSubmitted] = useState(false);
	const onSubmit = () => {
		setIsSubmitted(true);
		const isValid = onValidation();

		if (isValid) {
			const {
				batchName,
				description,
				traineeIds,
				domain,
				programId,
				skills,
				// status,
				batchManager,
				startDate,
				endDate,
			} = formState;
			const reqObj = {
				name: batchName.value,
				description: description.value,
				domain: domain.value,
				batchManager: batchManager.value[0]._id,
				startDate: startDate.value,
				endDate: endDate.value,
				traineeIds: traineeIds.value.map((val) => val._id),
				trainerId: selectedTrainer?._id,
				skills: skills.value.map((val) => val._id),
				// status: status.value,
				programId: programId.value[0]._id,
			};
			if (isValid) {
				if (isEdit) {
					// eslint-disable-next-line no-shadow
					const { state } = location;
					batchService
						.updateBatch({ ...reqObj }, state._id)
						.then((response) => {
							if (response.statusCode === 200) {
								toast.success('Batch Updated Successfully...!');
							} else {
								toast.error(response?.message);
							}
							navigate('/batches');
						});
				} else {
					const createdbyy = '63e370bd02708c85b78784c9';
					batchService.createBatch(reqObj, createdbyy).then(
						(response) => {
							if (response.statusCode === 200) {
								toast.success(response?.message);
								navigate('/batches');
							} else {
								toast.error(response?.message);
							}
						},
						(error) => {
							toast.error(error.details);
						}
					);
				}
			}
			// Perform the submit logic
		} else {
			// Handle validation errors or display a message
		}
	};

	return (
		<>
			<BreadCrumb data={state} />
			<h4 className="headerStyle ms-1">
				{isEdit
					? `${BATCHES.addBatchForm.editBatch}`
					: `${BATCHES.addBatchForm.addBatch}`}
			</h4>
			<Card className="py-4 mb-2 px-4">
				<div className="col-12 row">
					<div className="col-12">
						<label className="inputLabel">
							{BATCHES.addBatchForm.batchName} <span className="error">*</span>
						</label>
						<input
							type="text"
							className={`styled-input ${
								formState?.batchName?.error ? 'error' : ''
							}`}
							placeholder="Enter Name"
							name="Name"
							id="batchName"
							value={formState.batchName.value}
							required
							onChange={handleChange}
						/>
						<p className="errorText">
							{formState.batchName.error ? formState.batchName.errorMsg : ''}
						</p>
					</div>
					<div className="col-12">
						<label className="inputLabel">
							{BATCHES.addBatchForm.description}{' '}
							<span className="error">*</span>
						</label>
						<textarea
							type="text"
							className={`styled-input contactValue ${
								formState?.description?.error ? 'error' : ''
							}`}
							placeholder="Enter Batch Description"
							name="Description"
							id="description"
							value={formState.description.value}
							required
							onChange={handleChange}
						/>
						<p className="errorText">
							{formState.description.error
								? formState.description.errorMsg
								: ''}
						</p>
					</div>
					<div className="col-6">
						<label className="inputLabel">
							{BATCHES.addBatchForm.domain} <span className="error">*</span>
						</label>
						<select
							className={`styled-input ${
								formState?.domain?.error ? 'error' : ''
							}`}
							id="domain"
							value={formState.domain.value}
							onChange={handleChange}
						>
							<option value={''} disabled>
								Select
							</option>
							{domainList.map((ele) => (
								<option key={ele} value={ele}>
									{ele}
								</option>
							))}
						</select>
						<p className="errorText">
							{formState.domain.error ? formState.domain.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-1">
						<TypeaheadComponent
							id="programId"
							name="Course"
							className={`styled-input ${
								formState?.programId?.error ? 'error' : ''
							}`}
							onChange={(selected) =>
								handleTypeaheadChange('programId', selected)
							}
							options={ProgramOptions}
							labelKey="name"
							multiple={false}
							placeholder="Select Course"
							selected={
								formState.programId.value ? formState.programId.value : []
							}
							error={formState.programId.error}
							errorMsg={formState.programId.errorMsg}
						/>
					</div>
					<div className="col-6 mb-1">
						<label className="inputLabel">
							{BATCHES.addBatchForm.trainerId} <span className="error">*</span>
						</label>
						<input
							type="text"
							className="read-only-field styled-input"
							placeholder="Enter Trainer"
							name="Name"
							id="trainerId"
							value={selectedTrainer?.name ? selectedTrainer?.name : ''}
							title={!selectedTrainer?.name ? 'Select Course First' : ''}
							readOnly
							required
						/>
						<p className="error">
							{(isSubmitted && !selectedTrainer?.name) ||
							formState.programId.error
								? 'Trainer is required'
								: ''}
						</p>
					</div>
					<div className="col-6 mb-1">
						<TypeaheadComponent
							id="batchManager"
							name="Batch Manager"
							className={`styled-input ${
								formState?.batchManager?.error ? 'error' : ''
							}`}
							onChange={(selected) =>
								handleTypeaheadChange('batchManager', selected)
							}
							options={ReportingManagerOptions}
							labelKey="name"
							multiple={false}
							placeholder="Select Batch Manager"
							selected={
								formState.batchManager.value ? formState.batchManager.value : []
							}
							error={formState.batchManager.error}
							errorMsg={formState.batchManager.errorMsg}
						/>
					</div>
					{/* <div className="col-6">
						<label>
							{BATCHES.addBatchForm.status} <span className="error">*</span>
						</label>
						<select
							className="form-select"
							id="status"
							value={formState.status.value}
							onChange={handleChange}
						>
							<option value={''} disabled>
								Select
							</option>
							{statusList.map((ele) => (
								<option key={ele} value={ele}>
									{ele}
								</option>
							))}
							{renderDomainsList()}
						</select>
						<p className="error">
							{formState.domain.error ? formState.domain.errorMsg : ''}
						</p>
					</div> */}
					<div className="col-6 mb-1">
						<TypeaheadComponent
							id="skills"
							name="Skills"
							className={`styled-input ${
								formState?.skills?.error ? 'error' : ''
							}`}
							onChange={(selected) => handleTypeaheadChange('skills', selected)}
							options={skillsList}
							labelKey="name"
							multiple={true}
							placeholder="Select Skills"
							selected={selectedSkills?.length ? selectedSkills : []}
							error={formState.skills.error}
							errorMsg={formState.skills.errorMsg}
						/>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{BATCHES.addBatchForm.startDate} {''}
							<span className="error">*</span>
						</label>
						<div className="date-picker-container">
							<ReactDatePicker
								placeholderText="Start Date"
								className={`styled-input session-date ${
									formState?.startDate?.error ? 'error' : ''
								}`}
								selected={
									formState.startDate?.value ? formState.startDate?.value : null
								}
								onChange={(date) =>
									dateHandler('Start Date', 'startDate', date)
								}
								dateFormat="yyyy-MM-dd"
								required
								id="startDate"
								autoComplete="off"
							/>
							<FaCalendarAlt className="calendar-icon" />
						</div>
						<p className="errorText">
							{formState.startDate.error ? formState.startDate.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-2">
						<label className="inputLabel">
							{BATCHES.addBatchForm.endDate} {''}{' '}
							<span className="error">*</span>
						</label>
						<div className="date-picker-container">
							<ReactDatePicker
								placeholderText="End Date"
								className={`styled-input session-date ${
									formState?.endDate?.error ? 'error' : ''
								}`}
								selected={
									formState.endDate?.value ? formState.endDate?.value : null
								}
								onChange={(date) => dateHandler('End Date', 'endDate', date)}
								dateFormat="yyyy-MM-dd"
								required
								id="endDate"
								autoComplete="off"
							/>
							<FaCalendarAlt className="calendar-icon" />
						</div>
						<p className="errorText">
							{formState.endDate.error ? formState.endDate.errorMsg : ''}
						</p>
					</div>
					<div className="col-12">
						<TypeaheadComponent
							id="traineeIds"
							name="Trainees"
							className={`styled-input session-date ${
								formState?.traineeIds?.error ? 'error' : ''
							}`}
							labelKey="name"
							onChange={(selected) =>
								handleTypeaheadChange('traineeIds', selected)
							}
							multiple={true}
							options={membersOptions}
							placeholder="Select Trainees"
							selected={selectedTraineeIds?.length ? selectedTraineeIds : []}
							error={formState.traineeIds.error}
							errorMsg={formState.traineeIds.errorMsg}
						/>
					</div>
				</div>
			</Card>
			<Card className="position-sticky bottom-0">
				<div className="d-flex justify-content-center p-2">
					<button
						type="button"
						className=" positiveButton btnStyle"
						onClick={onSubmit}
					>
						{BUTTONS.save}
					</button>
					<button
						type="button"
						className="btnStyle negativeButton ms-4"
						onClick={() => navigate('/batches')}
					>
						{BUTTONS.cancel}
					</button>
				</div>
			</Card>
		</>
	);
};

export default BatchForm;
