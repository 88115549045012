import axios from 'axios';
import { getHeaders } from './utilities';
// eslint-disable-next-line import/no-cycle
import { logOutUser } from '../../Pages/Auth/Login';

export const baseURL = `${process.env.REACT_APP_BASE_URL}/api/v1`; // api end point

class ApiException {
	details;

	message;

	statusCode;

	timestamp;

	constructor(details = '', message = '', statusCode = '', timestamp = '') {
		this.details = details;
		this.message = message;
		this.statusCode = statusCode;
		this.timestamp = timestamp;
	}
}

/**
 * @param {string} path - API endpoint
 * @param {string} method - Request method type
 * @param {Object} hederType - header
 * @param {string} data - request payload
 * @param {string} extraParams - additional Paramaters in request
 * @param {string} queryParams - query paramaters
 */

export const makeAPICall = async (
	path,
	method,
	headerType,
	data,
	extraParams,
	queryParams
) => {
	const requestConfiguration = {
		url:
			baseURL +
			path +
			(queryParams ? +'?' + new URLSearchParams(queryParams).toString() : ''),
		method,
		data,
		headers: getHeaders(headerType),
		params: extraParams || {},
		transformResponse: [(response) => JSON.parse(response)],
	};
	return axios(requestConfiguration)
		.then((response) => {
			if (response.status >= 200 && response.status < 300) {
				return response.data;
			}
		})
		.catch((error) => {
			if (error.response) {
				const { details, message, statusCode, timestamp } = error.response.data;
				if (statusCode === 401) {
					logOutUser();
				}
				throw new ApiException(message, statusCode, details, timestamp);
			} else if (error.request) {
				throw new ApiException('Something went wrong !');
			} else {
				const { message = '' } = error;
				throw new ApiException(message);
			}
		});
};
