import { useState, useEffect } from 'react';

const UseSearch = (initialValue, delay) => {
	const [searchValue, setSearchValue] = useState(initialValue);
	const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchValue);

	useEffect(() => {
		const delayTimeout = setTimeout(() => {
			setDebouncedSearchValue(searchValue);
		}, delay);

		return () => clearTimeout(delayTimeout);
	}, [searchValue, delay]);

	const handleSearch = (value) => {
		setSearchValue(value);
	};

	return {
		debouncedSearchValue,
		handleSearch,
		setDebouncedSearchValue,
		searchValue,
		setSearchValue,
	};
};

export default UseSearch;
