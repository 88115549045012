import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FaEdit } from 'react-icons/fa';
import { TRAINERS } from '../../constants/AppConstants';
import DataTable from '../../common-components/DataTable/DataTable';
import EmployeeServices from '../../services/employeeService';

export const TrainersTable = ({
	trainerList,
	onSize,
	onPageNum,
	pageSize,
	pageNum,
	handleSearch,
	totalNoOfRecords,
	searchedRecords,
	userRole,
}) => {
	const employeeServices = new EmployeeServices();
	const navigate = useNavigate();
	const format = (data) => ({
		...data,
		doj: moment(data.doj).format('yy-MM-DD'),
	});
	const onEdit = async (data) => {
		const path = `/employees/${data?._id}`;
		const trainerId = data?._id;
		employeeServices.getEmployeeById(trainerId).then((res) => {
			const responseData = format(res?.data);
			navigate(path, { state: responseData });
		});
	};
	const cellClickedListener = (event) => {
		if (event.value) {
			const path = `/employees/employeeProfile/${event.data?._id}`;
			navigate(path, { state: event.data });
		}
	};

	const columns = [
		{
			field: 'name',
			headerName: 'Name',
		},
		{
			field: 'emailId',
			headerName: 'Email Id',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			width: undefined, // or omit the width property altogether
			minWidth: 250, // set a minimum width for the column
			maxWidth: 300, // set a maximum width for the column
		},
		{
			field: 'experience',
			headerName: 'Total Experience',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => <>{`${params.value} Years`}</>,
		},
		{
			field: 'count_inprogress',
			headerName: 'Ongoing',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'count_completed',
			headerName: 'Completed',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'count_upcoming',
			headerName: 'Upcoming',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'manager',
			headerName: 'Manager',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		...(userRole === 'Admin'
			? [
					{
						field: '',
						headerName: 'Action',
						headerClass: 'my-header-class',
						cellStyle: { textAlign: 'center' },
						cellRenderer: (params) => (
							<FaEdit
								onClick={() => onEdit(params?.data)}
								className="edit-icon"
							/>
						),
					},
			  ]
			: []),
	];

	const paginationChange = (updatedPageNumber, updatedPageSize) => {
		onSize(updatedPageSize);
		onPageNum(updatedPageNumber);
	};

	return (
		<div className="">
			<DataTable
				height={500}
				tableTitle={TRAINERS.trainers}
				columns={columns}
				rows={trainerList}
				pagination={false}
				customPagination={true}
				enableGlobalSearch={true}
				cellClickedListener={cellClickedListener}
				handleSearch={handleSearch}
				paginationChange={paginationChange}
				currentPage={pageNum}
				size={pageSize}
				totalNoOfRecords={totalNoOfRecords}
				searchedRecords={searchedRecords}
			></DataTable>
		</div>
	);
};
