import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import DataTable from '../../../../common-components/DataTable/DataTable';

import { getTraineeTrainerFeedback } from '../../../../services/feedbackService';

const TraineeTrainerFeedback = ({ userRole, trainerFeedbackColumns }) => {
	const [userId, setUserId] = useState(localStorage.getItem('userId'));
	const [forms, setForms] = useState([]);

	const formatForms = (data) => {
		const formattedData = data.map((item, i) => {
			const batch = item;
			batch.id = i + 1;
			if (batch.submittedOn === null) {
				batch.submittedOn = '-';
			} else {
				batch.submittedOn = moment(batch.submittedOn).format('DD-MM-YYYY');
			}
			return item;
		});
		setForms(formattedData);
	};

	const getAllTraineeTrainerFeedbacks = () => {
		getTraineeTrainerFeedback(userRole, userId).then((res) => {
			const data = res?.data;
			formatForms(data);
		});
	};

	useEffect(() => {
		if (!userId) {
			setUserId(localStorage.getItem('userId'));
		}
		getAllTraineeTrainerFeedbacks();
	}, []);

	const navigate = useNavigate();
	const cellClickedListener = (event) => {
		if (event.value) {
			// navigate to FeedbackformDetail page
			const path = `/feedback/${event.data?.formName}/${event.data?._id}`;
			navigate(path, { state: event.data, userRole });
		}
	};

	return (
		<div>
			<DataTable
				columns={trainerFeedbackColumns}
				rows={forms}
				tableTitle="Feedbacks"
				pagination={true}
				height={525}
				enableGlobalSearch={true}
				cellClickedListener={cellClickedListener}
			/>
		</div>
	);
};

export default TraineeTrainerFeedback;
