import { makeAPICall } from './api';
import { ApiPath, HttpMethods } from './api/definitions';

class userLoginServices {
	validateUserLogin(data) {
		return makeAPICall(ApiPath.validateUserLogin, HttpMethods.post, '', data);
	}

	loginEmailPass(data) {
		return makeAPICall(ApiPath.loginEmailPass, HttpMethods.post, '', data);
	}

	switchRole(data) {
		return makeAPICall(ApiPath.switchRole, HttpMethods.post, '', data);
	}
}

export default userLoginServices;
