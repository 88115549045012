import React from 'react';
import Card from '../../common-components/Card/Card';
import { BarChart } from '../../common-components/BarChart/BarChart';

export function NoOfAssessmentsAndBatches(props) {
	const { names, scores, title, hoverText } = props;
	const series = [
		{
			name: hoverText,
			data: scores?.length ? scores : [],
		},
	];
	return (
		<Card>
			<div className="mt-3">
				<div className="d-inline header d-flex justify-content-between">
					<div className="subHeader text-start">
						<h3 className="">{title}</h3>
					</div>
				</div>
				<BarChart
					names={names?.length ? names : []}
					seriesData={series}
					itemsLength={scores?.length}
					height={270}
					isDashboard={true}
					barWidth={names?.length <= 3 ? '20' : '32'}
				/>
			</div>
		</Card>
	);
}
