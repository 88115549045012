import React from 'react';
import moment from 'moment';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from '../../../common-components/Card/Card';
import { BATCHES } from '../../../constants/AppConstants';
import {
	ViewPageRow,
	ViewPageSingleRow,
} from '../../../common-components/ViewPageRow/ViewPageRow';
import '../Styles.css';

export const BatchDescription = ({ selectedBatch }) => {
	const {
		name,
		description,
		skills,
		programId,
		trainerId,
		domain,
		startDate,
		endDate,
		progress,
		batchManager,
	} = selectedBatch;

	return (
		<>
			<Card>
				<div className="subHeader text-start">
					<h3 className="">{name}</h3>
				</div>
				<ViewPageRow
					labelOne={BATCHES.batchesDescription.batchManager}
					labelOneValue={batchManager?.name || 'No Data'}
					labelTwo={BATCHES.batchesDescription.trainer}
					labelTwoValue={trainerId ? trainerId?.name : 'No Data'}
				/>
				<ViewPageRow
					labelOne={BATCHES.batchesDescription.course}
					labelOneValue={
						programId?.name ? (
							<li className="col-10 batch-description_values fw-normal ">
								{programId?.name ? programId.name : 'No Data'}
							</li>
						) : (
							'No Data'
						)
					}
					labelTwo={BATCHES.batchesDescription.domain}
					labelTwoValue={domain || 'No Data'}
				/>
				<ViewPageRow
					labelOne={BATCHES.batchesDescription.startDate}
					labelOneValue={moment(startDate).format('DD-MM-YYYY') || 'No Data'}
					labelTwo={BATCHES.batchesDescription.endDate}
					labelTwoValue={moment(endDate).format('DD-MM-YYYY') || 'No Data'}
				/>
				<ViewPageRow
					labelOne={BATCHES.batchesDescription.skills}
					labelOneValue={
						skills?.length ? (
							<ul className="list-inline">
								{skills.map((item, i) => (
									<li className="list-inline-item" key={item._id}>
										{item ? item.skillName : 'No Data'}
										{i !== skills.length - 1 && ','}
									</li>
								))}
							</ul>
						) : (
							<li>No Data</li>
						)
					}
					labelTwo={BATCHES.batchesDescription.contributor}
					labelTwoValue={
						programId?.contributers.length > 0 ? (
							<ul className="list-inline">
								{programId.contributers.map((item, i) => (
									<li className="list-inline-item" key={i}>
										{item ? item.name : 'No Data'}
										{i !== programId.contributers.length - 1 && ','}
									</li>
								))}
							</ul>
						) : (
							<li>No Data</li>
						)
					}
				/>
				<ViewPageSingleRow
					label={BATCHES.batchesDescription.progress}
					value={
						<div className="d-flex">
							<ProgressBar
								animated
								className="progressbar mt-1 inline-block"
								variant="success"
								now={progress || 0}
							/>
							<span>{progress || 0}%</span>
						</div>
					}
				/>
				<ViewPageSingleRow
					label={BATCHES.batchesDescription.description}
					value={description || 'No Data'}
				/>
			</Card>
		</>
	);
};
