import { makeAPICall } from './api';
import { HttpMethods, ApiPath } from './api/definitions';

class DashboardService {
	getDashboardEmployeePieChart() {
		return makeAPICall(
			ApiPath.dashboardEmployeePieChart,
			HttpMethods.get,
			'auth'
		);
	}

	getBatchwiseAssessmentsList(data) {
		return makeAPICall(
			`${ApiPath.batchwiseAssessmentsList}`,
			HttpMethods.post,
			'auth',
			data
		);
	}

	getTechwiseBatchList(data) {
		return makeAPICall(
			`${ApiPath.techwiseBatchList}`,
			HttpMethods.post,
			'auth',
			data
		);
	}

	getTopFiveBatchesTechwise(data) {
		return makeAPICall(
			`${ApiPath.topFiveBatchesTechwise}`,
			HttpMethods.post,
			'auth',
			data
		);
	}

	getBatchesByStatus(data) {
		return makeAPICall(
			`${ApiPath.getBatchesByStatus}`,
			HttpMethods.post,
			'auth',
			data
		);
	}

	getAssessmentsByBatch(id) {
		return makeAPICall(
			`${ApiPath.getAssessmentsDetailsByBatch}/${id}`,
			HttpMethods.get,
			'auth'
		);
	}

	getAllBatchesList(year) {
		return makeAPICall(
			`${ApiPath.getAllBatchesList}/${year}`,
			HttpMethods.get,
			'auth'
		);
	}

	getEmployeeSpectrumInfo(data) {
		return makeAPICall(
			`${ApiPath.getEmployeeSpectrumInfo}`,
			HttpMethods.post,
			'auth',
			data
		);
	}

	getResourceCount(data) {
		return makeAPICall(
			`${ApiPath.getResourceCount}`,
			HttpMethods.post,
			'auth',
			data
		);
	}

	getEmployeesLeaderboardInfoTechwise(data) {
		return makeAPICall(
			`${ApiPath.getEmployeesLeaderboardInfoTechwise}`,
			HttpMethods.post,
			'auth',
			data
		);
	}

	getAllDashboardBatches(data) {
		return makeAPICall(
			`${ApiPath.getAllDashboardBatches}`,
			HttpMethods.post,
			'auth',
			data
		);
	}
	// localhost:9999/dashboard/resource/count/2023 getResourceCount getEmployeeLeaderboardInfoTechwise
}

export default DashboardService;
