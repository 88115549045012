export const HttpMethods = {
	get: 'GET',
	post: 'POST',
	delete: 'DELETE',
	put: 'PUT',
	patch: 'PATCH',
};

export const ApiPath = {
	getAllPrograms: '/program',
	getAllProgramsByStatus: '/program/status',
	filterBatchByStatus: '/batch/filter',
	getAllTrainerCourses: '/trainercourseMandetory',
	programSoftDelete: '/courseStatusUpdate',
	getAllSkills: '/master/skill',
	getAllDesignation: '/designation',
	getAllManagers: '/employees/managerList',
	createProgram: '/program',
	updateProgram: '/program/',
	getAllEmployees: '/employees',
	getTrainingsByEmpId: '/trainee',
	getAllTrainers: '/trainers',
	getTrainersList: '/employees/alltrainers',
	getAllBatches: '/batch',
	createBatch: '/batch',
	updateBatch: '/batch/',
	deleteBatch: '/batch/',
	getAllAssessments: '/assesmentwithaverage',
	getAssessmentById: '/assesment',
	createAssessment: '/assesment/',
	updateAssessment: '/assesment/',
	deleteAssessment: '/assesment/',
	getAssessmentBatchTrainees: '/assesment/batch/trainee/',
	createAndUpdateAssessmentResults: '/result/assessmentResult',
	getBatchCharts: '/result/batch/',
	trainerAsTrainee: '/employees/batchwithassesmentaverage',
	trainerAsTrainer: '/trainers',
	validateUserLogin: '/google/login',
	loginEmailPass: '/login',
	switchRole: '/switchAccounts',
	createEmployee: '/employees',
	updateEmployee: '/employees',
	dashboardEmployeePieChart: '/dashboard/batchemp',
	dashboardResourceCount: '/dashboard/resource/count',
	dashboardBatchStatus: '/dashboard/batch/status',
	// New Dashboard Api
	batchwiseAssessmentsList: '/dashboard/assesmentbatchwise',
	techwiseBatchList: '/dashboard/batchtechwise',
	topFiveBatchesTechwise: '/dashboard/best5BtchTechwise',
	getBatchesByStatus: '/dashboard/batchemp',
	getAssessmentsDetailsByBatch: '/dashboard/getAssesmentByBatch',
	getAllBatchesList: '/batch/yearwise',
	getAssessmentsByBatch: '/dashboard/getAssesmentByBatch',
	getEmployeeSpectrumInfo: '/dashboard/getpieChartBatchEmployeeCount',
	getResourceCount: '/dashboard/resource/count',
	getEmployeesLeaderboardInfoTechwise: '/dashboard/leaderboard',
	getAllDashboardBatches: '/dashboard/dashboardBatches',
	// Trainer
	getTrainerBatchesByStatus: '/batch/trainer',
	getTraineeBatchesByStatus: '/batch/trainee',
	getAllTrainerBatches: '/getallTrainerBatches/',
	// Trainee
	getAllTraineeBatchAssessments: '/assesment/batches/trainee',
	getAllTraineeAssessments: '/traineeAssesmentScore',
	traineeAssessmentSubmission: '/submitResult/submission',
	getTraineeAssessmentSubmittedInfo: '/viewSubmition',
	getAllTraineeCourses: '/traineecourse',
	getTraineeDetailsByBatchId: '/batch/search',
	// Feedback
	feedbackForm: '/formfeedback',
	submitFeedback: '/feedbacksubmission',
	submittedFeedbackDetails: '/feedbacksubmission/formfeedback',
};
