import { React, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { DashboardContainer } from '../../Pages/Dashboard';
import Employee from '../../Pages/Employee';
import EmployeeProfile from '../../Pages/EmployeeProfile';
import Batches from '../../Pages/Batches';
import { Program } from '../../Pages/Programs';
import { TrainerCourses } from '../../Pages/TrainerCourses';
import { Assessments } from '../../Pages/Assessments';
import { AssessmentForm } from '../../Pages/Assessments/AssessmentForm';
import { Requests } from '../../Pages/Requests';
import Trainers from '../../Pages/Trainers';
import { BatchDetailContainer } from '../../Pages/Batches/BatchView/BatchDetailsContainer';
import ProgramDetail from '../../Pages/Programs/ProgramDetail';
import { AddProgram } from '../../Pages/Programs/AddProgram';
import BatchForm from '../../Pages/Batches/BatchForm';
import TrainerProfile from '../../Pages/Trainers/TrainerProfile';
import { Login } from '../../Pages/Auth/Login';
import { AuthenticatedLayout } from '../../Pages/Authenticated';
import UserContext from '../../context/userContext';
import ProtectedRoutes from './ProtectedRoutes';
import AssessmentDetails from '../../Pages/Assessments/AssessmentDetails';
import { PageNotFound } from '../../Pages/PageNotFound/PageNotFound';
import { userRoles } from '../../constants/AppConstants';
import FeedbackContainer from '../../Pages/Feedback/FeedbackContainer/FeedbackContainer';
import EmployeeForm from '../../Pages/Employee/EmployeeForm';
import FeedbackFormDetail from '../../Pages/Feedback/FeedbackContainer/TraineeFeedback/FeedbackFormDetail';

const Home = () => {
	const { userLoggedIn, userRole } = useContext(UserContext);

	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Login />} />
					<Route element={<ProtectedRoutes userLoggedIn={userLoggedIn} />}>
						<Route
							path="/dashboard"
							element={
								<AuthenticatedLayout>
									<DashboardContainer />
								</AuthenticatedLayout>
							}
						/>
						<Route
							path="/batches"
							element={
								<AuthenticatedLayout>
									<Batches userRole={userRole} />
								</AuthenticatedLayout>
							}
						/>
						<Route
							path="*"
							element={
								<AuthenticatedLayout>
									<PageNotFound />
								</AuthenticatedLayout>
							}
						/>
						<Route
							path="/employees/addEmployee"
							element={
								<AuthenticatedLayout>
									<EmployeeForm />
								</AuthenticatedLayout>
							}
						/>
						<Route
							path="/employees/editEmployee/:id"
							element={
								<AuthenticatedLayout>
									<EmployeeForm />
								</AuthenticatedLayout>
							}
						/>
						<Route
							path="/employees"
							element={
								<AuthenticatedLayout>
									<Employee userRole={userRole} />
								</AuthenticatedLayout>
							}
						/>
						<Route
							path="/employees/:id"
							element={
								<AuthenticatedLayout>
									<EmployeeForm userRole={userRole} />
								</AuthenticatedLayout>
							}
						/>
						<Route
							path="/employees/employeeProfile/:id"
							element={
								<AuthenticatedLayout>
									<EmployeeProfile />
								</AuthenticatedLayout>
							}
						></Route>
						<Route
							path="/batches/:category/:id"
							element={
								<AuthenticatedLayout>
									<BatchDetailContainer />
								</AuthenticatedLayout>
							}
						></Route>
						<Route
							path="/batches/:id"
							element={
								<AuthenticatedLayout>
									<BatchForm />
								</AuthenticatedLayout>
							}
						></Route>
						<Route
							path="/trainers"
							element={
								<AuthenticatedLayout>
									<Trainers />
								</AuthenticatedLayout>
							}
						/>
						<Route
							path="/trainers/:id"
							element={
								<AuthenticatedLayout>
									<TrainerProfile />
								</AuthenticatedLayout>
							}
						/>
						{[
							userRoles.admin,
							userRoles.manager,
							userRoles.trainee,
							userRoles.employee,
						].includes(userRole) && (
							<Route
								path="/course"
								element={
									<AuthenticatedLayout>
										<Program userRole={userRole} />
									</AuthenticatedLayout>
								}
							/>
						)}
						<Route
							path="/course/:category/:id"
							element={
								<AuthenticatedLayout>
									<ProgramDetail />
								</AuthenticatedLayout>
							}
						></Route>
						<Route
							path="/course/:id"
							element={
								<AuthenticatedLayout>
									<AddProgram userRole={userRole} />
								</AuthenticatedLayout>
							}
						></Route>
						{userRole === userRoles.trainer && (
							<Route
								path="/trainerCourses"
								element={
									<AuthenticatedLayout>
										<TrainerCourses />
									</AuthenticatedLayout>
								}
							/>
						)}
						<Route
							path="/trainerCourses/:category/:id"
							element={
								<AuthenticatedLayout>
									<ProgramDetail />
								</AuthenticatedLayout>
							}
						></Route>
						<Route
							path="/assessments"
							element={
								<AuthenticatedLayout>
									<Assessments userRole={userRole} />
								</AuthenticatedLayout>
							}
						/>
						<Route
							path="/assessments/:id"
							element={
								<AuthenticatedLayout>
									<AssessmentForm userRole={userRole} />
								</AuthenticatedLayout>
							}
						/>
						<Route
							path="/assessments/:category/:id"
							element={
								<AuthenticatedLayout>
									<AssessmentDetails />
								</AuthenticatedLayout>
							}
						></Route>
						<Route
							path="/requests"
							element={
								<AuthenticatedLayout>
									<Requests />
								</AuthenticatedLayout>
							}
						/>
						{[
							userRoles.admin,
							userRoles.manager,
							userRoles.trainee,
							userRoles.trainer,
						].includes(userRole) && (
							<Route
								path="/feedback"
								element={
									<AuthenticatedLayout>
										<FeedbackContainer userRole={userRole} />
									</AuthenticatedLayout>
								}
							/>
						)}
						<Route
							path="/feedback/:detail/:id"
							element={
								<AuthenticatedLayout>
									<FeedbackFormDetail />
								</AuthenticatedLayout>
							}
						/>
						<Route
							path="*"
							element={
								<AuthenticatedLayout>
									<PageNotFound />
								</AuthenticatedLayout>
							}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default Home;
