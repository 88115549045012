import React, { useEffect, useRef, useState } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AiFillPlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import { toNumber } from 'lodash';
import Card from '../../common-components/Card/Card';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import {
	PROGRAMS,
	BUTTONS,
	userRoles,
	TECHDROPDOWNITEMS,
} from '../../constants/AppConstants';
import ProgramServices from '../../services/programServices';
import EmployeeServices from '../../services/employeeService';
import TrainerServices from '../../services/trainerService';
import TypeaheadComponent from '../../common-components/TypeHead';
import { formDataConversion } from '../../utils/helper';

export const mapData = (list) =>
	list.map((data) => ({
		name: data?.name || '',
		_id: data?._id || '',
	}));

export const AddProgram = (props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const programService = new ProgramServices();
	const employeeService = new EmployeeServices();
	const trainerService = new TrainerServices();
	const [skillsList, setSkillsList] = useState([]);
	const [options, setOptions] = useState([]);
	const [domainList] = useState(TECHDROPDOWNITEMS.map((item) => item.status));
	const [state, setState] = useState([]);
	const [isEdit, setIsEdit] = useState(false);
	const [selectedSkills, setSelectedSkills] = useState([]);
	const [trainersList, setTrainersList] = useState([]);
	const [selectedContributors, setSelectedContributors] = useState([]);
	const [inValidReference, setInValidReference] = useState([]);
	const [invalidContentLink, setInvalidContentLink] = useState([]);

	const inputRef = useRef(null);

	const [formState, setFormState] = useState({
		projectName: {
			value: '',
			error: false,
			errorMsg: '',
		},
		description: {
			value: '',
			error: false,
			errorMsg: '',
		},
		skills: {
			value: '',
			error: false,
			errorMsg: '',
		},
		domain: {
			value: '',
			error: false,
			errorMsg: '',
		},
		duration: {
			value: '',
			error: false,
			errorMsg: '',
		},
		comments: {
			value: '',
			error: false,
			errorMsg: '',
		},
		contributors: {
			value: [],
			error: false,
			errorMsg: '',
		},
		trainerId: {
			value: '',
			error: false,
			errorMsg: '',
		},
		// mandetory: {
		// 	value: false,
		// 	error: false,
		// 	errorMsg: '',
		// },
		referenceLink: [
			{
				value: [],
				error: false,
				errorMsg: '',
			},
		],
		tableofContent: [
			{
				content: {
					value: '',
					error: false,
					errorMsg: '',
				},
				contentLink: {
					value: '',
					error: false,
					errorMsg: '',
				},
				duration: {
					value: '',
					error: false,
					errorMsg: '',
				},
			},
		],
		uploadedFile: {
			value: [],
			error: false,
			errorMsg: '',
		},
	});

	useEffect(() => {
		programService.getAllSkills().then((response) => {
			setSkillsList(response?.data?.length ? response?.data : []);
		});

		employeeService.getAllEmployees().then((response) => {
			setOptions(response?.data?.length ? mapData(response?.data) : []);
		});

		trainerService.getAllTrainersList().then((response) => {
			setTrainersList(response?.data?.length ? mapData(response?.data) : []);
		});
	}, []);

	const generateInitialFormState = (projectData) => {
		const updatedFormState = { ...formState };

		Object.keys(projectData).forEach((key) => {
			if (updatedFormState[key]) {
				if (key === 'referenceLink') {
					updatedFormState[key] = projectData[key].map((link) => ({
						value: link,
						error: false,
						errorMsg: '',
					}));
				} else if (key === 'tableofContent') {
					updatedFormState[key] = projectData[key].map((item) => ({
						content: {
							value: item.content || '',
							error: false,
							errorMsg: '',
						},
						contentLink: {
							value: item.contentLink || '',
							error: false,
							errorMsg: '',
						},
						duration: {
							value: item.duration.toString() || '',
							error: false,
							errorMsg: '',
						},
					}));
				} else {
					updatedFormState[key].value = projectData[key];
				}
			}
		});

		return updatedFormState;
	};

	useEffect(() => {
		if (location.pathname !== '/course/addCourse') {
			setIsEdit(true);
			setState([
				{ name: 'Course', route: '/course', isLink: true },
				{ name: 'Edit Course', route: '', isLink: false },
			]);
			if (location.state) {
				const projectData = location.state;
				const { name, contributers, trainerId } = projectData;
				projectData.uploadedFile = projectData.fileName
					? [
							{
								file: projectData.fileUploadUrl,
								fileName: projectData.fileName,
							},
					  ]
					: [];
				setFormState(
					generateInitialFormState({
						...projectData,
						projectName: name,
						contributors: contributers,
						trainerId: trainerId?._id,
					})
				);
				setSelectedSkills(formState.skills.value);
				setSelectedContributors(formState.contributors.value);
			}
			// eslint-disable-next-line no-shadow
		} else {
			setIsEdit(false);
			setState([
				{ name: 'Courses', route: '/course', isLink: true },
				{ name: 'Add Course', route: '', isLink: false },
			]);
		}
	}, [location, skillsList, options]);

	const updateField = (name, key, value) => {
		const copy = { ...formState[key] };
		const error = !value?.toString()?.length;
		const errorMsg = value?.toString()?.length ? '' : `${name} is required`;
		return { ...copy, value, error, errorMsg };
	};

	const uploadHandler = (event) => {
		const { id, name } = event.target;
		const file = event.target.files[0];
		setFormState((prev) => ({
			...prev,
			uploadedFile: updateField(name, id, [{ file, fileName: file.name }]),
		}));
	};

	const handleChange = (event) => {
		const { id, value, name, type, checked } = event.target;

		if (type === 'checkbox') {
			setFormState((prevState) => ({
				...prevState,
				[id]: { value: checked, error: false, errorMsg: '' },
			}));
		} else {
			setFormState((prevState) => ({
				...prevState,
				[id]: updateField(name, id, value),
			}));
		}
	};

	const handleCancelEvent = () => {
		if (props?.userRole === userRoles.trainer) {
			navigate('/trainerCourses');
		} else if (props?.userRole === userRoles.trainee) {
			navigate('/course');
		} else if (props?.userRole === userRoles.admin) {
			navigate('/course');
		}
	};

	const onValidation = () => {
		let isValid = true;
		const updatedFormState = { ...formState };
		// Validate project name, description, skills, domain, and other fields except table of contents
		Object.keys(updatedFormState).forEach((key) => {
			if (key === 'referenceLink') {
				const x = updatedFormState[key].map((field, index) => {
					if (!field.value.length) {
						updatedFormState[key][index] = updateField(
							PROGRAMS.addProgramForm[key],
							key.charAt(0).toLowerCase() + key.slice(1),
							field.value
						);
						isValid = false;
					} else {
						try {
							/* eslint-disable no-new */
							new URL(field.value);
							isValid = true;
						} catch (error) {
							isValid = false;
							return field;
						}
					}
					return isValid;
				});
				setInValidReference(x);
			} else if (
				key !== 'tableofContent' &&
				!updatedFormState[key]?.value?.length &&
				!['comments', 'duration', 'uploadedFile'].includes(key)
			) {
				updatedFormState[key] = updateField(
					PROGRAMS.addProgramForm[key],
					key.charAt(0).toLowerCase() + key.slice(1),
					updatedFormState[key].value
				);
				isValid = false;
			}
		});

		// Validate table of contents fields
		const x = updatedFormState.tableofContent.map((item, index) => {
			if (!item.content.value.length) {
				updatedFormState.tableofContent[index].content = updateField(
					'Content',
					'content',
					item.content.value
				);
				isValid = false;
			}
			if (!item.duration.value.length) {
				updatedFormState.tableofContent[index].duration = updateField(
					'Duration',
					'duration',
					item.duration.value
				);
				isValid = false;
			}
			if (item.contentLink.value.length) {
				try {
					/* eslint-disable no-new */
					new URL(item.contentLink.value);
					isValid = true;
				} catch (error) {
					isValid = false;
					return item.contentLink;
				}
			}
			return isValid;
		});
		setInvalidContentLink(x);
		setFormState(updatedFormState);
		return isValid;
	};

	const handleTypeaheadChange = (id, selected) => {
		const updatedField = updateField(PROGRAMS.addProgramForm[id], id, selected);
		setFormState((prevState) => ({
			...prevState,
			[id]: updatedField,
		}));
		if (id === 'skills') {
			setSelectedSkills(selected);
		} else if (id === 'contributors') {
			setSelectedContributors(selected);
		}
	};

	const calculateTotalDuration = (data) => {
		let totalDuration = 0;
		data.forEach((item) => {
			const duration = parseInt(item.duration.value, 10);
			if (duration) {
				totalDuration += duration;
			}
		});
		return totalDuration;
	};

	const handleReferenceFieldChange = (index, value) => {
		const updatedReferenceLink = [...formState.referenceLink];
		updatedReferenceLink[index] = {
			value: value,
			error: false,
			errorMsg: '',
		};
		const updatedFormState = {
			...formState,
			referenceLink: updatedReferenceLink,
		};
		setFormState(updatedFormState);
	};

	const handleTableOfContentChange = (index, field, value) => {
		const updatedTableOfContents = [...formState.tableofContent];
		const updatedField = updateField(field, field, value);
		updatedTableOfContents[index] = {
			...updatedTableOfContents[index],
			[field]: updatedField,
		};
		const updatedFormState = {
			...formState,
			tableofContent: updatedTableOfContents,
			duration: {
				...formState.duration,
				value: calculateTotalDuration(updatedTableOfContents),
			},
		};
		setFormState(updatedFormState);
	};

	const addReferenceField = () => {
		const updatedReferenceLink = [...formState.referenceLink];
		updatedReferenceLink.push({
			value: '',
			error: false,
			errorMsg: '',
		});
		setFormState((prevState) => ({
			...prevState,
			referenceLink: updatedReferenceLink,
		}));
	};

	const removeReferenceField = (index) => {
		const updatedReferenceLink = [...formState.referenceLink];
		updatedReferenceLink.splice(index, 1);
		setFormState((prevState) => ({
			...prevState,
			referenceLink: updatedReferenceLink,
		}));
	};

	const addTableOfContentsField = () => {
		const updatedTableOfContents = [...formState.tableofContent];
		updatedTableOfContents.push({
			content: {
				value: '',
				error: false,
				errorMsg: '',
			},
			contentLink: {
				value: '',
				error: false,
				errorMsg: '',
			},
			duration: {
				value: '',
				error: false,
				errorMsg: '',
			},
		});
		setFormState((prevState) => ({
			...prevState,
			tableofContent: updatedTableOfContents,
		}));
	};

	const removeTableOfContentsField = (index) => {
		const updatedTableOfContents = [...formState.tableofContent];
		updatedTableOfContents.splice(index, 1);
		setFormState((prevState) => ({
			...prevState,
			tableofContent: updatedTableOfContents,
		}));
	};

	const onSubmit = () => {
		const isValid = onValidation();

		if (isValid) {
			const {
				projectName,
				description,
				comments,
				contributors,
				trainerId,
				domain,
				skills,
				// mandetory,
				referenceLink,
				tableofContent,
				uploadedFile,
			} = formState;
			const data = {
				name: projectName.value,
				description: description.value,
				skills: skills.value.map((val) => val._id),
				domain: domain.value,
				referenceLink: referenceLink.map((item) => item.value),
				comments: comments.value,
				// duration: duration.value,
				tableofContent: tableofContent.map((item) => ({
					content: item.content.value,
					contentLink: item.contentLink.value,
					duration: toNumber(item.duration.value),
				})),
				contributers: contributors.value.map((val) => val._id),
				trainerId: trainerId.value,
				// mandetory: mandetory.value,
				file: uploadedFile?.value[0] ? uploadedFile?.value[0]?.file : '',
				fileName: uploadedFile?.value[0]
					? uploadedFile?.value[0]?.fileName
					: '',
			};
			const reqObj = formDataConversion(data);
			if (isValid) {
				if (isEdit) {
					// eslint-disable-next-line no-shadow
					const { state } = location;
					programService.updateProgram(reqObj, state._id).then((response) => {
						if (response?.statusCode === 200) {
							toast.success('Course Updated Successfully...!');
						} else {
							toast.error(response?.message);
						}
						if (props?.userRole === userRoles.trainer) {
							navigate('/trainerCourses');
						} else if (props?.userRole === userRoles.admin) {
							navigate('/course');
						}
					});
				} else {
					programService.createProgram(reqObj).then(
						(response) => {
							if (response.statusCode === 200) {
								toast.success(response?.message);
								if (props?.userRole === userRoles.trainer) {
									navigate('/trainerCourses');
								} else if (props?.userRole === userRoles.admin) {
									navigate('/course');
								}
							} else {
								toast.error(response?.message);
							}
						},
						(error) => {
							toast.error(error.details);
						}
					);
				}
			}
			// Perform the submit logic
		} else {
			// Handle validation errors or display a message
		}
	};

	return (
		<>
			<BreadCrumb data={state} />
			<h4 className="headerStyle ms-1">
				{isEdit
					? `${PROGRAMS.addProgramForm.editProgram}`
					: `${PROGRAMS.addProgramForm.addProgram}`}
			</h4>
			<Card className="py-4 mb-2 px-4">
				<div className="col-12 row">
					<div className="col-12">
						<label className="inputLabel">
							{PROGRAMS.addProgramForm.name} <span className="error">*</span>
						</label>
						<input
							type="text"
							className={`styled-input ${
								formState?.projectName?.error ? 'error' : ''
							}`}
							placeholder="Enter Name"
							name="Name"
							id="projectName"
							value={formState.projectName.value}
							required
							onChange={handleChange}
						/>
						<p className="errorText">
							{formState.projectName.error
								? formState.projectName.errorMsg
								: ''}
						</p>
					</div>
					<div className="col-12">
						<label className="inputLabel">
							{PROGRAMS.addProgramForm.description}{' '}
							<span className="error">*</span>
						</label>
						<textarea
							type="text"
							className={`styled-input ${
								formState?.description?.error ? 'error' : ''
							}`}
							placeholder="Enter Description"
							name="Description"
							id="description"
							value={formState.description.value}
							required
							onChange={handleChange}
						/>
						<p className="errorText">
							{formState.description.error
								? formState.description.errorMsg
								: ''}
						</p>
					</div>
					<div className="col-6 mb-1">
						<TypeaheadComponent
							className={`styled-input ${
								formState?.skills?.error ? 'error' : ''
							}`}
							id="skills"
							name="Skills"
							onChange={(selected) => handleTypeaheadChange('skills', selected)}
							options={skillsList}
							labelKey="skillName"
							multiple={true}
							placeholder="Select skills"
							selected={selectedSkills?.length ? selectedSkills : []}
							error={formState.skills.error}
							errorMsg={formState.skills.errorMsg}
						/>
					</div>
					<div className="col-6">
						<label className="inputLabel mb-2">
							{PROGRAMS.addProgramForm.domain} <span className="error">*</span>
						</label>
						<select
							className={`styled-input mt-2 ${
								formState?.domain?.error ? 'error' : ''
							}`}
							id="domain"
							value={formState.domain.value}
							onChange={handleChange}
						>
							<option value={''} disabled>
								Select
							</option>
							{domainList.map((ele) => (
								<option key={ele} value={ele}>
									{ele}
								</option>
							))}
						</select>
						<p className="errorText">
							{formState.domain.error ? formState.domain.errorMsg : ''}
						</p>
					</div>
					<div className="col-12">
						{formState.tableofContent.map((item, index) => (
							<div className="row" key={index}>
								<div className="col-5">
									<label className="inputLabel">
										{PROGRAMS.addProgramForm.tableofContent}
										<span className="error">*</span>
									</label>
									<input
										type="text"
										className={`styled-input ${
											item?.content?.error ? 'error' : ''
										}`}
										placeholder="Enter Content"
										value={item.content.value}
										onChange={(e) =>
											handleTableOfContentChange(
												index,
												'content',
												e.target.value
											)
										}
									/>
									<p className="errorText">
										{item.content.error ? item.content.errorMsg : ''}
									</p>
								</div>
								<div className="col-3">
									<label className="inputLabel">Content Link</label>
									<input
										type="text"
										className={`styled-input ${
											item.contentLink?.error ? 'error' : ''
										}`}
										placeholder="Enter Content Link"
										value={
											item?.contentLink?.value ? item?.contentLink?.value : ''
										}
										onChange={(e) =>
											handleTableOfContentChange(
												index,
												'contentLink',
												e.target.value
											)
										}
									/>
									{invalidContentLink?.map((el, i) =>
										el?.value === item?.contentLink?.value ? (
											<p key={i} className="error">
												{'Please provide valid link'}
											</p>
										) : (
											''
										)
									)}
								</div>
								<div className="col-3">
									<label className="inputLabel">Duration</label>
									<input
										type="number"
										className={`styled-input ${
											item.duration?.error ? 'error' : ''
										}`}
										placeholder="Enter Duration"
										value={item.duration.value}
										onChange={(e) =>
											handleTableOfContentChange(
												index,
												'duration',
												e.target.value
											)
										}
									/>
									<p className="errorText">
										{item.duration.error ? item.duration.errorMsg : ''}
									</p>
								</div>
								<div className="col-1 d-flex justify-content-center align-items-center">
									{(formState.tableofContent.length === 0 ||
										formState.tableofContent.length - 1 === index) && (
										<AiFillPlusCircle
											className="plusIconStyle ms-1"
											onClick={addTableOfContentsField}
										/>
									)}
									{formState.tableofContent.length - 1 !== index && (
										<AiFillMinusCircle
											className="minusIconStyle"
											onClick={() => removeTableOfContentsField(index)}
										/>
									)}
								</div>
							</div>
						))}
					</div>

					{/* <div className="d-flex ">
							<input
								type="file"
								placeholder='helooo'
								name="FileUpload"
								id="fileUpload"
								accept=".pdf, .png, .docx, .doc"
							    onChange={uploadHandler}
							/>
							{formState?.uploadedFile?.value[0]?.fileName ? (
								<>
									<div className="uploaded-file ps-1">
										<span className="fw-bold">
											{formState?.uploadedFile?.value[0]?.fileName || ''}
										</span>
									</div>
									<FaTrash
										name="FileUpload"
										id="fileUpload"
										className="delete-file_icon "
										onClick={(event) => {
											setFormState((prev) => ({
												...prev,
												uploadedFile: updateField('Program File', event.target.id, []),
											}));
										}}
									/>
								</>
							) : (
								''
							)}
						</div>
						<p className="error">
							{formState?.uploadedFile?.error
								? formState.uploadedFile.errorMsg
								: ''}
						</p> */}

					{/* <div className="col-6 mt-2"> */}
					<div className="col-6">
						<label>
							{PROGRAMS.addProgramForm.duration} {'(in days)'} {''}
							<span className="error">*</span>
						</label>
						<input
							type="number"
							className="form-control"
							placeholder="Duration"
							name="Duration"
							id="duration"
							disabled
							value={formState.duration.value}
						/>
						<p className="error">
							{formState.duration.error ? formState.duration.errorMsg : ''}
						</p>
					</div>
					<div className="d-flex col-6 fileInput">
						<input
							className="chooseFile mt-2"
							type="file"
							ref={inputRef}
							name="FileUpload"
							id="fileUpload"
							accept=".pdf, .png, .docx, .doc"
							onChange={uploadHandler}
						></input>
						<input
							placeholder="Upload File"
							className={'styled-input uploadInputField'}
							type="text"
							disabled
							value={formState?.uploadedFile?.value[0]?.fileName || ''}
							autoComplete="off"
							style={{
								backgroundColor: formState?.uploadedFile?.value[0]?.fileName
									? 'rgb(239 239 239)'
									: 'white',
							}}
						></input>
						<FaTrash
							name="FileUpload"
							id="fileUpload"
							className="delete-file_icon"
							onClick={(event) => {
								setFormState((prev) => ({
									...prev,
									uploadedFile: updateField(
										'Program File',
										event.target.id,
										[]
									),
								}));
								inputRef.current.value = '';
							}}
						/>
					</div>
					{/* <p className="errorText">
						{formState.uploadedFile.error
							? formState.uploadedFile.errorMsg
							: ''}
					</p> */}
					{/* </div> */}
					{/* <p className="error"> */}
					{/* {ReferenceAndFileErrorMsg
								? 'Either References Or File is required'
								: ''} */}
					{/* </p> */}
					<div className="col-6">
						{/* <label>
							{PROGRAMS.addProgramForm.contributers} {''}
							<span className="error">*</span>
						</label> */}
						<TypeaheadComponent
							className={`styled-input ${
								formState?.contributors?.error ? 'error' : ''
							}`}
							id="contributors"
							name="Contributors"
							labelKey="name"
							onChange={(selected) =>
								handleTypeaheadChange('contributors', selected)
							}
							multiple={true}
							options={options}
							placeholder="Select Contributors"
							selected={
								selectedContributors?.length ? selectedContributors : []
							}
							error={formState.contributors.error}
							errorMsg={formState.contributors.errorMsg}
						/>
					</div>
					<div className="col-6">
						<label>
							{PROGRAMS.addProgramForm.trainerId}{' '}
							<span className="error">*</span>
						</label>
						<select
							className="form-select styled-input"
							id="trainerId"
							name="Trainer"
							value={formState.trainerId.value ? formState.trainerId.value : ''}
							required
							onChange={handleChange}
						>
							<option disabled value={''}>
								Select
							</option>
							{trainersList.length &&
								trainersList?.map((ele) => (
									<option key={ele._id} value={ele._id}>
										{ele.name}
									</option>
								))}
						</select>
						<p className="error">
							{formState.trainerId.error ? formState.trainerId.errorMsg : ''}
						</p>
					</div>
					{/* <div className="col-6">
						<div className="form-check courseMandatory">
							<input
								className="form-check-input"
								type="checkbox"
								name="Mandatory Course"
								id="mandetory"
								checked={formState.mandetory.value}
								onChange={handleChange}
							/>
							<label
								className="form-check-label ms-2"
								htmlFor="exampleCheckbox"
							>
								Course Is Mandatory
							</label>
						</div>
					</div> */}
					<div className="col-12">
						<label className="inputLabel">
							{PROGRAMS.addProgramForm.referenceLink}
							<span className="error">*</span>
						</label>
						{formState.referenceLink.map((field, index) => (
							<div className="row" key={index}>
								<div className="col-11">
									<input
										type="text"
										className={`styled-input ${field.error ? 'error' : ''}`}
										placeholder="Enter Reference Links"
										value={field.value}
										onChange={(e) =>
											handleReferenceFieldChange(index, e.target.value)
										}
									/>
									<p className="errorText">
										{field.error ? field.errorMsg : ''}
										{inValidReference?.map((el, i) =>
											el?.value === field?.value ? (
												<p key={i} className="error">
													{'Please provide valid link'}
												</p>
											) : (
												''
											)
										)}
									</p>
								</div>
								<div className="col-1 d-flex justify-content-center align-items-center">
									{(formState.referenceLink.length === 1 ||
										formState.referenceLink.length - 1 === index) && (
										<AiFillPlusCircle
											onClick={addReferenceField}
											className="plusIconStyle"
										/>
									)}
									{formState.referenceLink.length - 1 !== index && (
										<AiFillMinusCircle
											onClick={() => removeReferenceField(index)}
											className="minusIconStyle"
										/>
									)}
								</div>
							</div>
						))}
					</div>
					<div className="col-12 my-2">
						<label className="inputLabel">
							{PROGRAMS.addProgramForm.comments}
						</label>
						<textarea
							type="text"
							className={`styled-input mt-1 ${
								formState?.experience?.error ? 'error' : ''
							}`}
							placeholder="Leave a Comment"
							name="Comments"
							id="comments"
							value={formState.comments.value}
							onChange={handleChange}
						/>
					</div>
				</div>
			</Card>
			<Card className="position-sticky bottom-0">
				<div className="d-flex justify-content-center pt-3">
					<button
						type="button"
						className="positiveButton btnStyle"
						onClick={onSubmit}
					>
						{BUTTONS.save}
					</button>
					<button
						type="button"
						className="btnStyle negativeButton ms-4"
						onClick={handleCancelEvent}
					>
						{BUTTONS.cancel}
					</button>
				</div>
			</Card>
		</>
	);
};
