import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { TECHDROPDOWNITEMS } from '../../constants/AppConstants';
import { BarChart } from '../../common-components/BarChart/BarChart';
import Card from '../../common-components/Card/Card';
import DashboardService from '../../services/dashboardService';
import { formatterForDashboard } from '../../utils/helper';
import './Styles.css';

export function TopFiveBatchesTechwise(props) {
	const { quarter, year } = props;
	const [tech, setTech] = useState(Object.values(TECHDROPDOWNITEMS)[0]);
	const [topFiveBatches, setTopFiveBatches] = useState({
		names: [],
		averageScores: [],
		noData: false,
	});
	const selectHandler = (event, key) => {
		setTech({ status: key, label: event });
	};

	// Top 5 batches Techwise
	const ifQuarter = {
		domain: tech?.label,
		quarter: {
			fromDate: quarter?.fromDate,
			tillDate: quarter?.tillDate,
		},
	};
	const ifYear = {
		domain: tech?.label,
		year: year?.year,
	};
	const DashboardServices = new DashboardService();

	const getTopFiveBatchesTechWise = () => {
		DashboardServices.getTopFiveBatchesTechwise(
			quarter?.label?.length ? ifQuarter : ifYear
		).then(async (res) => {
			const responseData = await res?.data;

			const [topFiveBatchesnames, topFiveBatchesAverageScores] =
				formatterForDashboard(responseData, 'name', 'averagescore');

			setTopFiveBatches((prevState) => ({
				...prevState,
				names: topFiveBatchesnames || [],
				averageScores: topFiveBatchesAverageScores || [],
				noData: !responseData?.length,
			}));
		});
	};

	useEffect(() => {
		getTopFiveBatchesTechWise();
	}, [quarter?.label, year?.year, tech?.label]);

	const techSeries = [
		{
			name: 'Batch Score',
			data: topFiveBatches?.averageScores,
		},
	];

	return (
		<div className="mt-3">
			<Card className="topFiveBatchesTechwise ">
				<div className="d-inline header pt-1 d-flex justify-content-start">
					<div className="subHeader text-start">
						<h3 className="">Top 5 Batches Techwise</h3>
					</div>
					<div style={{ width: '150px' }}>
						<DropdownButton
							onSelect={selectHandler}
							className="mt-1"
							title={tech?.label}
							auto
							// style={{ width: "150px" }}
						>
							{TECHDROPDOWNITEMS.map((key, i) => (
								<Dropdown.Item eventKey={key.status} key={i}>
									{key.label}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</div>
				</div>
				{!topFiveBatches?.noData ? (
					<BarChart
						names={topFiveBatches?.names}
						seriesData={techSeries}
						itemsLength={topFiveBatches?.names?.length}
						height={270}
						isDashboard={true}
						addPercentageForLabel={true}
						barWidth={topFiveBatches?.names?.length <= 3 ? '20' : '45'}
					/>
				) : (
					<h6 className="text-center mt-5 mx-auto"> No Data Found </h6>
				)}
			</Card>
		</div>
	);
}
