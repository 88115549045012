/* eslint-disable import/order */
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useEffect } from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import Home from './common-components/home/Home';
import UserState from './context/userState';
import { gapi } from 'gapi-script';

function App() {
	const clientId = process.env.REACT_APP_CLIENT_ID;
	useEffect(() => {
		function start() {
			gapi.client.init({
				clientId: clientId,
				scope: 'email',
			});
		}

		gapi.load('client:auth2', start);
	}, []);

	return (
		<UserState>
			<GoogleOAuthProvider clientId={clientId}>
				<div className="App">
					<Home />
					<ToastContainer />
				</div>
			</GoogleOAuthProvider>
		</UserState>
	);
}

export default App;
