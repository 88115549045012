import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { ProgramTable } from './ProgramTable';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import ProgramServices from '../../services/programServices';
import {
	COURSESTATUSDROPDOWNITEMS,
	userRoles,
} from '../../constants/AppConstants';
import UseSearch from '../../common-components/UseSearch/UseSearch';
import Pagination from '../../common-components/Pagination/Pagination';

export const Program = (props) => {
	const [state, setState] = useState([]);
	const [programsList, setProgramsList] = useState();
	const [corsesStatus, setCoursesStatus] = useState(
		Object.values(COURSESTATUSDROPDOWNITEMS)[0]
	);
	const [initialValue, setInitialValue] = useState(true);
	const { debouncedSearchValue, handleSearch, searchValue } = UseSearch(
		'',
		500
	);
	const { pageSize, pageNum, onSize, onPageNum, setPageNum } = Pagination(
		10,
		1
	);
	const programService = new ProgramServices();
	useEffect(() => {
		setState([
			{
				name: 'Courses',
				route: '',
				isLink: false,
			},
		]);
	}, []);

	const getAllProgramsByStatus = () => {
		if (props?.userRole === userRoles.trainee) {
			const userId = localStorage.getItem('userId');
			programService.getAllTraineeCourses(userId).then((res) => {
				const response = res?.data?.map((obj) => ({
					...obj,
					skillName: obj?.skills?.length ? obj?.skills.toString() : 'No Data',
					contributers: obj?.contributers?.length
						? obj?.contributers?.map((contributor) => contributor.name)
						: 'No Data',
				}));
				const { datalength, searchLength } = res || {};
				setProgramsList({
					list: response?.length ? response : [],
					totalRecords: datalength || 0,
					searchedRecords: searchLength || 0,
				});
				// setProgramsList(res?.data?.length ? response : []);
			});
		} else {
			programService
				.getAllProgramsByStatus(
					corsesStatus.status,
					debouncedSearchValue,
					pageNum,
					pageSize
				)
				.then((res) => {
					const response = res?.data?.map((obj) => ({
						...obj,
						skillName: obj?.skills?.length ? obj?.skills.toString() : 'No Data',
					}));
					const { datalength, searchlength } = res || {};
					const formattedResponse = {
						list: response?.length ? response : [],
						totalRecords: datalength || 0,
						searchedRecords: searchlength || 0,
					};
					setProgramsList(res?.data?.length ? formattedResponse : []);
				});
		}
	};

	/**
	 *
	 * @param {Object} response
	 * @param {string} message
	 */
	const showToast = (response, message) => {
		if (response) {
			toast.success(message);
			getAllProgramsByStatus();
		}
	};

	const onDeleteConfirm = (data) => {
		programService.deleteProgram(data?._id).then((response) => {
			showToast(response, 'Course Deleted Successfully...!');
		});
	};

	const addToInActiveList = (id) => {
		const updateStatus = {
			courseStatus: 'Inactive',
		};
		programService.programSoftDelete(id, updateStatus).then((response) => {
			showToast(response, 'Course moved to inactive courses successfully...!');
		});
	};

	const selectHandler = (key, event) => {
		setCoursesStatus({ status: key, label: event.target.textContent });
	};

	useEffect(() => {
		getAllProgramsByStatus();
	}, [corsesStatus?.status, pageSize, pageNum]);

	useEffect(() => {
		if (debouncedSearchValue !== '' && pageNum !== 1) {
			setPageNum(1);
		} else if (!initialValue) {
			getAllProgramsByStatus();
		} else {
			setInitialValue(!initialValue);
		}
	}, [debouncedSearchValue]);

	const coursesDropdown = props.userRole !== userRoles.trainee && (
		<DropdownButton
			onSelect={selectHandler}
			title={corsesStatus?.label || corsesStatus}
		>
			{COURSESTATUSDROPDOWNITEMS.map((key, i) => (
				<Dropdown.Item eventKey={key.status} key={i}>
					{key.label}
				</Dropdown.Item>
			))}
		</DropdownButton>
	);
	return (
		<>
			<BreadCrumb data={state} />

			<ProgramTable
				userRole={props?.userRole}
				programsList={
					programsList?.list?.length ? programsList.list : programsList
				}
				onDeleteConfirm={onDeleteConfirm}
				status={corsesStatus?.status}
				addToInActiveList={addToInActiveList}
				getAllProgramsByStatus={getAllProgramsByStatus}
				handleSearch={handleSearch}
				dropdown={coursesDropdown}
				onSize={onSize}
				onPageNum={onPageNum}
				pageSize={pageSize}
				pageNum={pageNum}
				totalNoOfRecords={programsList?.totalRecords}
				searchedRecords={programsList?.searchedRecords}
				searchValue={searchValue}
			/>
		</>
	);
};
