import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import { TrainerCoursesTable } from './TrainerCoursesTable';
import ProgramServices from '../../services/programServices';
import { ConfirmationModal } from '../../common-components/ConfirmationModal/ConfirmationModal';
import UseSearch from '../../common-components/UseSearch/UseSearch';
import Pagination from '../../common-components/Pagination/Pagination';
import DataTable from '../../common-components/DataTable/DataTable';
import NavBarTabs from '../../common-components/NavbarTabs/NavbarTabs';

export const TrainerCourses = () => {
	const [trainerCourses, setTrainerCourses] = useState([]);
	const [selectedCourse, setSelectedCourse] = useState('Mandetory');
	const [showPopup, setshowPopup] = useState(false);
	const [selectedData, setSelectedData] = useState('');
	const [initialValue, setInitialValue] = useState(true);
	const [selectedBatch, setSelectedBatch] = useState({
		modal: false,
		batchesList: [],
	});
	const [breadcrumb] = useState([
		{
			name: 'Courses',
			route: '',
			isLink: false,
		},
	]);
	const {
		debouncedSearchValue,
		handleSearch,
		searchValue,
		setDebouncedSearchValue,
	} = UseSearch('', 500);
	const navigate = useNavigate();
	const programServices = new ProgramServices();
	const { pageSize, pageNum, onSize, onPageNum, setPageNum } = Pagination(
		10,
		1
	);

	const onEdit = (data) => {
		programServices.getProgramById(data._id).then((response) => {
			const responseData = response.data;
			const path = `/course/${responseData?._id}`;
			navigate(path, { state: responseData });
		});
	};

	const onDelete = (data) => {
		setSelectedData(data);
		setshowPopup(true);
	};

	const status = 'Active';

	const columns = [
		{
			field: 'name',
			headerName: 'Name',
		},
		{
			field: 'duration',
			headerName: 'Duration',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					<span>{`${params.value} days`}</span>
				</>
			),
		},
		{
			field: 'domain',
			headerName: 'Expertise',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'skillName',
			headerName: 'Skills',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'batchCount',
			headerName: 'Batches',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => {
				const openModal = () => {
					const selectedBatchData = params?.data?.batchdata?.length
						? params.data.batchdata
						: [];
					setSelectedBatch((prevState) => ({
						...prevState,
						batchesList: selectedBatchData,
						modal: true,
					}));
				};
				return (
					<span
						className={params?.value > 0 ? 'average' : ''}
						onClick={params?.value > 0 ? openModal : null}
					>
						{`${params.value}`}
					</span>
				);
			},
		},
		{
			field: 'contributers',
			headerName: 'Contributors',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'action',
			headerName: 'Action',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					<FaEdit onClick={() => onEdit(params?.data)} className="edit-icon" />
					{/* Soft Delete */}
					{status === 'Active' && params?.data?.batchCount === 0 ? (
						<FaTrash
							onClick={() => onDelete(params?.data)}
							className="edit-icon ms-2"
						/>
					) : null}
					{/* Hard Delete */}
					{status === 'Inactive' ? (
						<FaTrash
							onClick={() => onDelete(params?.data)}
							className="edit-icon ms-2"
						/>
					) : null}
				</>
			),
		},
	];
	const getColumns = () => {
		if (selectedCourse !== 'myCourse') {
			return columns.filter((column) => column.field !== 'action');
		}
		return columns;
	};

	const courseChangeHandler = (course) => {
		setSelectedCourse(course);
		if (course !== 'AllCourses') {
			setDebouncedSearchValue('');
		}
	};

	const getAllTrainerCourses = () => {
		const trainerId = localStorage.getItem('userId');
		programServices
			.getAllTrainerCourses(
				trainerId,
				selectedCourse,
				debouncedSearchValue,
				pageNum,
				pageSize
			)
			.then((res) => {
				const response = res?.data?.map((obj) => ({
					...obj,
					skillName: obj?.skills?.length ? obj?.skills.toString() : 'No Data',
				}));
				const formattedResponse = {
					list: response,
					totalRecords: res?.TotalLength,
					searchedRecords: res?.searchLength,
				};
				setTrainerCourses(res?.data?.length ? formattedResponse : []);
			})
			.catch(setTrainerCourses([]));
	};

	useEffect(() => {
		getColumns();
		getAllTrainerCourses();
	}, [selectedCourse, pageNum, pageSize]);

	useEffect(() => {
		if (debouncedSearchValue !== '' && pageNum !== 1) {
			setPageNum(1);
		} else if (!initialValue) {
			getAllTrainerCourses();
		} else {
			setInitialValue(!initialValue);
		}
	}, [debouncedSearchValue]);

	const onClosePopup = () => {
		setshowPopup(false);
		setSelectedData('');
	};
	const showToast = (response, message) => {
		if (response?._id) {
			toast.success(message);
			getAllTrainerCourses();
		}
	};

	const onDeleteConfirm = (data) => {
		programServices
			.deleteProgram(data?._id)
			.then((response) => {
				showToast(response, 'Program Deleted Successfully...!');
			})
			.catch((err) => toast.error('delete trainer course failed: ', err));
	};

	const onConfirm = () => {
		onDeleteConfirm(selectedData);
		setshowPopup(false);
	};

	const BatchesColumns = [
		{
			field: 'name',
			headerName: 'Batch Name',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
	];

	const closeBatchesModal = () => {
		setSelectedBatch({ modal: false });
	};

	const coursesTabs = [
		{ id: 'Mandetory', label: 'Mandatory' },
		{ id: 'AllCourses', label: 'All Courses' },
		{ id: 'myCourse', label: 'My Courses' },
	];

	return (
		<div>
			<BreadCrumb data={breadcrumb} />
			<ConfirmationModal
				showPopup={showPopup}
				header={'Delete'}
				body={'Are you sure you want to Delete the Course'}
				cancel={'Cancel'}
				confirm={'Delete'}
				onConfirm={onConfirm}
				onClosePopup={onClosePopup}
			/>
			<Modal
				show={selectedBatch.modal}
				onHide={closeBatchesModal}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title className="">Batches List</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text-center p-2 m-2">
						<DataTable
							height={300}
							columns={BatchesColumns}
							rows={
								selectedBatch?.batchesList?.length
									? selectedBatch.batchesList
									: []
							}
							pagination={false}
							enableGlobalSearch={false}
							enableDropdown={true}
						/>
					</div>
				</Modal.Body>
			</Modal>
			<NavBarTabs
				onCourseChange={courseChangeHandler}
				tabs={coursesTabs}
				activeValue={selectedCourse}
			/>
			<TrainerCoursesTable
				columns={getColumns()}
				trainerCourses={trainerCourses?.list}
				selectedCourse={selectedCourse}
				handleSearch={handleSearch}
				onSize={onSize}
				onPageNum={onPageNum}
				pageSize={pageSize}
				pageNum={pageNum}
				totalNoOfRecords={trainerCourses?.totalRecords}
				searchedRecords={trainerCourses?.searchedRecords}
				searchValue={searchValue}
			/>
		</div>
	);
};
