/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { RxDownload } from 'react-icons/rx';
import { Modal } from 'react-bootstrap';
import Card from '../../common-components/Card/Card';
import DataTable from '../../common-components/DataTable/DataTable';
import { PROGRAMS } from '../../constants/AppConstants';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import ProgramServices from '../../services/programServices';
import {
	ViewPageRow,
	ViewPageSingleRow,
} from '../../common-components/ViewPageRow/ViewPageRow';
import './Styles.css';

function ProgramDetail() {
	const [state, setState] = useState([]);
	const [selectedProgram, setSelectedProgram] = useState();
	const [showImage, setShowImage] = useState(false);
	const [fileType, setFiletype] = useState(false);
	const [formattedTableOfContents, setFormattedTableOfContents] = useState([]);
	const location = useLocation();
	const params = useParams();
	const programId = location.state;
	const tableOfContentsColumn = [
		{
			field: 'content',
			headerName: 'Content',
		},
		{
			field: 'contentLink',
			headerName: 'Content Link',
			cellRenderer: (param) => (
				<a
					href={param.data.contentLink.length > 1 && param.data.contentLink}
					target="blank"
				>
					{param.data.contentLink}
				</a>
			),
		},

		{
			field: 'duration',
			headerName: 'Duration',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
	];

	useEffect(() => {
		const programService = new ProgramServices();
		programService.getProgramById(programId).then((response) => {
			if (response?.data.fileUploadUrl) {
				const urlObj = new URL(response?.data.fileUploadUrl);
				const pathParts = urlObj.pathname.split('/');
				const fileExtension = pathParts.pop().split('.').pop().toLowerCase();
				// Checking file type
				if (
					['doc', 'docx', 'pdf', 'txt', 'ppt', 'pptx', 'xls', 'xlsx'].includes(
						fileExtension
					)
				) {
					setFiletype(true);
				}
			}
			setSelectedProgram(response?.data);
			const formatTableofContents = response?.data?.tableofContent.map((el) => {
				const obj = el;
				obj.contentLink = !obj.contentLink ? '-' : obj.contentLink;
				return el;
			});
			setFormattedTableOfContents(formatTableofContents);
		});
	}, [programId]);

	useEffect(() => {
		setState([
			{
				name:
					params?.category === 'trainerCourseDetails'
						? 'Trainer Courses'
						: 'Courses',
				route:
					params?.category === 'trainerCourseDetails'
						? '/trainerCourses'
						: '/course',
				isLink: true,
				id: 1,
			},
			{ name: selectedProgram?.name, route: '', isLink: false, id: 2 },
		]);
	}, [selectedProgram?.name]);

	const contributorsList = selectedProgram?.contributers?.map(
		(item) => item?.name
	);

	const formattedContributorsList = contributorsList?.map((item, index) => (
		<span key={index} className="fw-normal batch-description_values ">
			{item}
			{contributorsList?.length > 1 &&
				(contributorsList?.length !== index + 1 ? ',' : '')}
		</span>
	));

	const selectedProgramsList = selectedProgram?.skills?.map((item, index) => (
		<li key={index} className="fw-normal batch-description_values d-inline ">
			{item.skillName}
			{selectedProgram?.skills.length > 1 &&
				(selectedProgram?.skills?.length !== index + 1 ? ',' : '')}
		</li>
	));

	const referenceLinks = selectedProgram?.referenceLink?.length ? (
		<a href={selectedProgram?.referenceLink} target="_blank" rel="noreferrer">
			{selectedProgram?.referenceLink.map((val, i) => (
				<ul key={i}>
					<li key={i} className="list-unstyled references text-wrap">
						{val}
					</li>
				</ul>
			))}
		</a>
	) : (
		'--'
	);

	const openfileModal = () => {
		if (fileType) {
			window.location.href = selectedProgram?.fileUploadUrl;
		} else setShowImage(true);
	};

	const referenceFile = (
		<>
			<button className="rbt-token-label" onClick={openfileModal}>
				{selectedProgram?.fileUploadUrl
					? !fileType
						? 'View Document'
						: 'Download Document'
					: 'No File Available'}
				{fileType && <RxDownload className="mb-1 mx-2" />}
			</button>
			{selectedProgram?.fileUploadUrl && !fileType ? (
				<a
					href={selectedProgram?.fileUploadUrl}
					download={selectedProgram?.fileName}
					className="downloadBtn ms-2 text-decoration-none"
				>
					<RxDownload className="mb-1" />
				</a>
			) : (
				''
			)}
		</>
	);
	return (
		<>
			<BreadCrumb data={state} />
			<Card>
				<div className="subHeader text-start">
					<h3 className="">{selectedProgram?.name}</h3>
				</div>
				<ViewPageRow
					labelOne={PROGRAMS.programDetails.trainer}
					labelOneValue={selectedProgram?.trainerId?.name}
					labelTwo={PROGRAMS.programDetails?.contributers}
					labelTwoValue={formattedContributorsList}
				/>
				<ViewPageRow
					labelOne={PROGRAMS.programDetails.duration}
					labelOneValue={`${selectedProgram?.duration} Days`}
					labelTwo={PROGRAMS.programDetails.domain}
					labelTwoValue={selectedProgram?.domain}
				/>
				<ViewPageRow
					labelOne={PROGRAMS.programDetails.comments}
					labelOneValue={
						selectedProgram?.comments ? selectedProgram?.comments : '--'
					}
					labelTwo={PROGRAMS.programDetails.skills}
					labelTwoValue={selectedProgramsList}
				/>
				<ViewPageSingleRow
					label={PROGRAMS.programDetails.referenceLinks}
					value={referenceLinks}
				/>
				{selectedProgram?.fileUploadUrl ? (
					<ViewPageSingleRow
						label={PROGRAMS.programDetails.referenceFile}
						value={referenceFile}
					/>
				) : (
					''
				)}
				<ViewPageSingleRow
					label={PROGRAMS.programDetails.description}
					value={selectedProgram?.description}
				/>
			</Card>
			<div className="w-100 mt-2 mb-3">
				<DataTable
					height={300}
					columns={tableOfContentsColumn}
					rows={formattedTableOfContents}
					pagination={true}
					tableTitle="Table Of Contents"
					customPagination={false}
					enableGlobalSearch={false}
					enableDropdown={false}
				/>
			</div>
			{selectedProgram?.fileUploadUrl ? (
				<Modal
					centered={true}
					size="xl"
					fullscreen="lg-down"
					show={showImage}
					onHide={() => setShowImage(false)}
				>
					<img
						className="d-block"
						src={
							selectedProgram?.fileUploadUrl
								? selectedProgram?.fileUploadUrl
								: ''
						}
						alt="First slide"
					/>
				</Modal>
			) : (
				''
			)}
		</>
	);
}

export default ProgramDetail;
