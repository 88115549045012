/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React from 'react';
import { toast } from 'react-toastify';
import { userRoles } from '../constants/AppConstants';

export const mapData = (list) => {
	const newObj = list.map((data) => {
		const obj = {
			name: data?.name ? data.name : '',
			_id: data?._id ? data._id : 0,
		};
		return obj;
	});
	return newObj;
};

export const renderList = (list) =>
	list.map((ele, index) => (
		<option key={index} value={ele}>
			{ele}
		</option>
	));

export const updateField = (name, key, value) => {
	const copy = { ...{ key } };
	const error = !value.toString().length;
	const errorMsg = value.toString().length ? '' : `${name} is required`;
	return { ...copy, value, error, errorMsg };
};

export const filteredArrayWithNameAndId = (options, value) => {
	const array = options?.filter((e) => e._id === value);
	return array;
};

export const convertBase64 = (file) =>
	new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = () => {
			resolve(fileReader.result);
		};
		fileReader.onerror = (error) => {
			reject(error);
		};
	});

export function formDataConversion(obj) {
	const formdata = new FormData();
	for (const [key, value] of Object.entries(obj)) {
		if (key !== 'file' && key !== 'fileName') {
			if (Array.isArray(value)) {
				if (typeof value[0] === 'object' && !Array.isArray(value[0])) {
					formdata.append(key, JSON.stringify(value));
				} else {
					value.forEach((item) => {
						formdata.append(key, item);
					});
				}
			} else {
				formdata.append(key, JSON.stringify(value));
			}
		} else {
			formdata.append(key, value);
		}
	}
	return formdata;
}

export const setLocalStorageItem = async (key, value) => {
	try {
		await localStorage.setItem(key, value);
	} catch (errorMessage) {
		toast.error(`Error storing item '${key}' in local storage:`);
	}
};

export const navigateToScreen = (userRole, navigate) => {
	switch (userRole) {
		case userRoles.admin:
		case userRoles.manager:
			navigate('/dashboard');
			break;
		case userRoles.trainer:
		case userRoles.trainee:
			navigate('/batches');
			break;
		case userRoles.employee:
			navigate('/course');
			break;
		default:
			navigate('/');
			break;
	}
};

export const getPathnameByRole = (userRole) => {
	switch (userRole) {
		case userRoles.admin:
		case userRoles.manager:
			return '/dashboard';
		case userRoles.trainer:
		case userRoles.trainee:
		case userRoles.employee:
			return '/batches';
		default:
			return '/';
	}
};

export const formatterForDashboard = (data, ...keys) => {
	const extractedData = keys.map((key) =>
		data.map((item) =>
			typeof item[key] === 'number' ? Number(item[key].toFixed(1)) : item[key]
		)
	);
	return extractedData;
};

export const setTheCurrentYear = (DASHBOARDYEARDROPDOWN, setState) => {
	const currentYear = new Date().getFullYear();
	const currentYearObject = DASHBOARDYEARDROPDOWN.find(
		(item) => item.year === currentYear
	);
	if (currentYearObject) {
		setState(currentYearObject);
	}
};
