import React from 'react';
import { NavLink } from 'react-router-dom';
import './BreadCrumb.css';

export const BreadCrumb = ({ data }) => (
	<div className="py-2">
		<nav aria-label="breadcrumb">
			<ol className="breadcrumb m-0">
				<li className="breadcrumb-item" key="home">
					<NavLink className="text-decoration-none" to="/">
						Home
					</NavLink>
				</li>
				{data?.map((item) => (
					<li className="breadcrumb-item" key={item?.id ? item.id : item.name}>
						{item.isLink ? (
							<NavLink className="text-decoration-none" to={item.route}>
								{item.name}
							</NavLink>
						) : (
							item.name
						)}
					</li>
				))}
			</ol>
		</nav>
	</div>
);
