import React from 'react';
import Card from '../../common-components/Card/Card';
import './Styles.css';

export function Info(props) {
	const { employeeCount, duration, totalCourseCount, totalBatches } = props;
	return (
		<Card className="h-100 w-100">
			<div className="text-center border-0 mx-5">
				<ul className="list-group list-group-flush border-0">
					<div className="list-group-item totalEmployeesBorder pb-0  d-flex justify-content-between">
						Total Employees
						<span className="totalEmployeesCountValue w-25 py-1 rounded-top">
							{employeeCount}
						</span>
					</div>
					<div className="list-group-item totalCourseCountBorder pb-0 d-flex justify-content-between">
						Total Courses
						<span className="totalCourseCountValue w-25 py-1 rounded-top">
							{totalCourseCount}
						</span>
					</div>
					<div className="list-group-item TotalBatchesBorder pb-0 d-flex justify-content-between">
						Total Batches
						<span className="totalBatchesValue w-25 py-1 rounded-top">
							{totalBatches}
						</span>
					</div>
					<div className="list-group-item durationBorder pb-0 d-flex justify-content-between">
						Learning Duration
						<span className="durationValue w-25 py-1 rounded-top">
							{duration}
						</span>
					</div>
				</ul>
			</div>
		</Card>
	);
}
