import { HttpMethods, ApiPath } from './api/definitions';
import { makeAPICall } from './api';

class SkillsService {
	getAllSkills() {
		return makeAPICall(ApiPath.getAllSkills, HttpMethods.get, 'auth');
	}
}

export default SkillsService;
