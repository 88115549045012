import DataTable from '../../../common-components/DataTable/DataTable';

export const FeedbackTable = ({
	columnList,
	feedbackList,
	addFeedbackHandler,
}) => (
	<div>
		<DataTable
			columns={columnList}
			rows={feedbackList}
			tableTitle="Feedbacks"
			addButton={true}
			addButtonListner={addFeedbackHandler}
			pagination={true}
			enableGlobalSearch={true}
		/>
	</div>
);
