import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Form, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { toast } from 'react-toastify';
import Carousel from 'react-bootstrap/Carousel';
import moment from 'moment';
import AssessmentService from '../../services/assessmentService';
import { userRoles } from '../../constants/AppConstants';
import './Styles.css';

function TraineeResultSubmissionModal({
	modalState,
	updateModalState,
	selectedAssessment,
	userRole,
	batchId,
}) {
	const [activeKey, setActiveKey] = useState(null);
	const [formData, setFormData] = useState({
		score: 0,
		remarks: '',
	});
	const [selectedTrainee, setSelectedTrainee] = useState();
	const [traineeSubmittedAssessmentInfo, setTraineeSubmittedAssessmentInfo] =
		useState({
			loading: false,
			data: [],
		});
	const handleAccordionClick = (eventKey) => {
		setActiveKey((prevActiveKey) =>
			prevActiveKey === eventKey ? null : eventKey
		);
		const selectedTraineeAssessment = selectedAssessment.filter(
			(assessment) => assessment.trainee === eventKey
		);
		setSelectedTrainee(selectedTraineeAssessment[0]);

		setFormData({
			score: selectedTraineeAssessment[0]?.score || 0,
			remarks: selectedTraineeAssessment[0]?.remarks || '',
		});
	};

	const onModalClosePopup = () => {
		updateModalState(false);
		setFormData({});
	};

	const assessmentService = new AssessmentService();
	const getTraineeAssessmentSubmittedInfo = (selectedTraineeId) => {
		if (userRole === userRoles.trainer) {
			const data = {
				batchId: batchId,
				traineeId: selectedTraineeId,
				assesmentId: selectedTrainee?.assesment,
			};
			setTraineeSubmittedAssessmentInfo((prevState) => ({
				...prevState,
				loading: true,
			}));
			assessmentService
				.getTraineeAssessmentSubmittedInfo(data, true)
				.then((res) => {
					const obj = res?.data[0];
					const formattedRes = obj
						? {
								...obj,
								createdAt: moment(obj.createdAt).format('DD-MM-YYYY'),
								updatedAt: moment(obj.updatedAt).format('DD-MM-YYYY'),
						  }
						: null;
					setTraineeSubmittedAssessmentInfo({
						loading: false,
						data: formattedRes,
					});
				})
				.catch((error) => {
					toast.error(error);
					setTraineeSubmittedAssessmentInfo({
						loading: false,
						data: [],
					});
				});
		}
	};

	const handleChange = (e) => {
		const { id, value } = e.target;
		let updatedValue;
		if (id === 'score') {
			updatedValue = Math.min(Math.max(Number(value), 0), 5); // Limit the score between 0 and 5
		} else {
			updatedValue = value; // Keep the remarks value as it is
		}
		setFormData((prevFormData) => ({
			...prevFormData,
			[id]: updatedValue.toString(), // Convert the value back to a string
		}));
	};

	const handleSubmit = () => {
		// e.preventDefault();
		const submitResult = [
			{
				trainee: selectedTrainee?.trainee,
				name: selectedTrainee?.name,
				score: formData?.score,
				remarks: formData?.remarks,
				assesmentname: selectedTrainee?.assesmentname,
				assesment: selectedTrainee?.assesment,
				resultId: selectedTrainee?.resultId,
			},
		];
		assessmentService
			.createAndUpdateAssessmentResults(submitResult)
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success('ADDED RESULTS');
					setActiveKey((prevActiveKey) =>
						prevActiveKey === activeKey ? null : activeKey
					);
				} else {
					toast.error(res?.message);
				}
			});
	};

	useEffect(() => {
		if (selectedTrainee?.trainee !== undefined) {
			getTraineeAssessmentSubmittedInfo(selectedTrainee?.trainee);
		}
	}, [selectedTrainee?.trainee]);

	const [showImage, setShowImage] = useState(false);
	const viewimage = () => {
		setShowImage(true);
	};
	return (
		<Modal show={modalState} onHide={onModalClosePopup} centered size="xl">
			<Modal.Header closeButton>
				<Modal.Title className="mx-3">
					{selectedAssessment[0]?.assesmentname}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Accordion activeKey={activeKey} onSelect={handleAccordionClick}>
					{selectedAssessment.map((trainee, index) => (
						<Accordion.Item key={index} eventKey={trainee?.trainee}>
							<Accordion.Header className="d-flex align-items-center justify-content-between">
								<div className="w-50">{trainee.name}</div>
								<div className="w-50 d-flex justify-content-end align-items-center">
									<span>{`Score: ${trainee.score ? trainee.score : 0}`}</span>
									<div className="ms-5"></div>
								</div>
							</Accordion.Header>
							<Accordion.Body className="p-3">
								{traineeSubmittedAssessmentInfo?.loading && <p>Loading...</p>}
								{traineeSubmittedAssessmentInfo?.data === null &&
									!traineeSubmittedAssessmentInfo?.loading && (
										<p>
											The Trainee{' '}
											<span className="text-danger fw-bold">
												{' '}
												{trainee.name}{' '}
											</span>{' '}
											has not submitted the assessment yet.
										</p>
									)}
								{traineeSubmittedAssessmentInfo?.data !== null &&
									!traineeSubmittedAssessmentInfo?.loading && (
										<>
											<Row>
												<Col>
													<Row>
														<Col xs={4}>
															<h6 className="titleColor">Trainee Name</h6>
														</Col>
														<Col xs="auto">:</Col>
														<Col>{selectedTrainee?.name}</Col>
													</Row>
													<Row>
														<Col xs={4}>
															<h6 className="titleColor">Date of Submission</h6>
														</Col>
														<Col xs="auto">:</Col>
														<Col>
															{traineeSubmittedAssessmentInfo?.data?.createdAt}
														</Col>
													</Row>
													<Row>
														<Col xs={4}>
															<h6 className="titleColor">Github URLs</h6>
														</Col>
														<Col xs="auto">:</Col>
														<Col>
															{traineeSubmittedAssessmentInfo?.data?.githubLinks?.map(
																// eslint-disable-next-line no-shadow
																(submission, index) => (
																	<div key={index}>
																		<a
																			href={submission}
																			target="_blank"
																			rel="noreferrer"
																		>
																			{submission}
																		</a>
																	</div>
																)
															)}
														</Col>
													</Row>
													{traineeSubmittedAssessmentInfo?.data?.createdAt !==
														traineeSubmittedAssessmentInfo?.data?.updatedAt && (
														<Row>
															<Col xs={4}>
																<h6 className="titleColor">
																	Date of Re-Submission
																</h6>
															</Col>
															<Col xs="auto">:</Col>
															<Col>
																{
																	traineeSubmittedAssessmentInfo?.data
																		?.updatedAt
																}
															</Col>
														</Row>
													)}

													<Row>
														<Col xs={4}>
															<h6 className="titleColor">Description</h6>
														</Col>
														<Col xs="auto">:</Col>
														<Col>
															{
																traineeSubmittedAssessmentInfo?.data
																	?.description
															}
														</Col>
													</Row>
												</Col>
												<Col>
													<Carousel>
														{traineeSubmittedAssessmentInfo?.data?.images?.map(
															(image) => (
																<Carousel.Item key={image?._id}>
																	<img
																		className="d-block w-100"
																		src={image?.fileUploadUrl}
																		alt="First slide"
																		onClick={viewimage}
																	/>
																</Carousel.Item>
															)
														)}
													</Carousel>
												</Col>
											</Row>
											{/* <Row className="mt-2">
												<Col>
													<b>Description:</b>{' '}
													{traineeSubmittedAssessmentInfo?.data?.description}
												</Col>
											</Row> */}
										</>
									)}
								<Form>
									<Col className="mt-0">
										<Col md={6}></Col>
										<Col
											md={2}
											className="d-flex ml-1 align-items-center gap-4"
										>
											<Form.Label
												htmlFor="score"
												className="mr-2 titleColor labelWithSpace"
											>
												Score:
											</Form.Label>
											<Form.Control
												className="text-dark "
												type="number"
												id="score"
												value={formData?.score}
												onChange={handleChange}
												inputMode="numeric"
												pattern="[0-5]"
											/>
										</Col>
										<Row className="mt-3">
											<Col md={10} className="d-flex align-items-center">
												<Form.Label
													htmlFor="remarks"
													className="mr-2 titleColor labelWithSpace"
												>
													Remarks:
												</Form.Label>
												<Form.Control
													type="text"
													id="remarks"
													value={formData?.remarks}
													onChange={handleChange}
												/>
											</Col>
											<Col md={1}>
												<Button
													className="submitBtn mx-2"
													type="button"
													onClick={handleSubmit}
													disabled={!traineeSubmittedAssessmentInfo?.data}
												>
													Submit
												</Button>
											</Col>
										</Row>
									</Col>
								</Form>
							</Accordion.Body>
						</Accordion.Item>
					))}
				</Accordion>
			</Modal.Body>
			<Modal
				centered={true}
				size="xl"
				fullscreen="lg-down"
				show={showImage}
				onHide={() => setShowImage(false)}
			>
				{/* <img src={imageUrl} alt="Full Size" /> */}
				<Carousel>
					{traineeSubmittedAssessmentInfo?.data?.images?.map((image) => (
						<Carousel.Item key={image?._id}>
							<img
								className="d-block w-100"
								src={image?.fileUploadUrl}
								alt="First slide"
								onClick={viewimage}
							/>
						</Carousel.Item>
					))}
				</Carousel>
			</Modal>
		</Modal>
	);
}

export default TraineeResultSubmissionModal;
