import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import { TrainersTable } from './TrainersTable';
import TrainerServices from '../../services/trainerService';
import UseSearch from '../../common-components/UseSearch/UseSearch';
import Pagination from '../../common-components/Pagination/Pagination';

const Trainers = () => {
	const [state, setState] = useState([]);
	const [trainerList, setTrainerList] = useState([]);
	const [userRole] = useState(localStorage.getItem('role'));
	const [initialValue, setInitialValue] = useState(true);
	const { debouncedSearchValue, handleSearch } = UseSearch('', 500);
	const { pageSize, pageNum, onSize, onPageNum, setPageNum } = Pagination(
		10,
		1
	);
	useEffect(() => {
		setState([
			{
				name: 'Trainers',
				route: '',
				isLink: false,
			},
		]);
	}, []);

	const fetchAllTrainers = async () => {
		try {
			const trainerService = new TrainerServices();
			const response = await trainerService.getAllTrainers(
				debouncedSearchValue,
				pageNum,
				pageSize
			);
			setTrainerList(response?.data?.length ? response : []);
		} catch (error) {
			toast.error('Error fetching trainers:', error);
		}
	};

	useEffect(() => {
		fetchAllTrainers();
	}, [pageSize, pageNum]);

	useEffect(() => {
		if (debouncedSearchValue !== '' && pageNum !== 1) {
			setPageNum(1);
		} else if (!initialValue) {
			fetchAllTrainers();
		} else {
			setInitialValue(!initialValue);
		}
	}, [debouncedSearchValue]);

	return (
		<>
			<BreadCrumb data={state} />
			<TrainersTable
				trainerList={trainerList?.data}
				handleSearch={handleSearch}
				onSize={onSize}
				onPageNum={onPageNum}
				pageSize={pageSize}
				pageNum={pageNum}
				totalNoOfRecords={trainerList?.datalength}
				searchedRecords={trainerList?.searchlength}
				userRole={userRole}
			/>
		</>
	);
};
export default Trainers;
