import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Form from 'react-bootstrap/Form';
import { BiSearchAlt } from 'react-icons/bi';
import Pagination from 'rc-pagination';
import { AiOutlineCaretRight, AiOutlineCaretLeft } from 'react-icons/ai';

const defaultProps = {
	tableStyle: {
		height: 500,
	},
	defaultColDef: {
		flex: 1,
		minWidth: 120,
		resizable: true,
		sortable: true,
	},
	pagination: true,
};

const DataTable = ({
	rows,
	columns,
	dropdown,
	defaultColDef,
	tableStyle,
	pagination,
	enableGlobalSearch,
	tableTitle,
	components,
	cellClickedListener,
	height,
	addButtonListner,
	addButton,
	handleCellValueChanged,
	handleSearch,
	paginationChange,
	currentPage,
	size,
	totalNoOfRecords,
	searchedRecords,
	customPagination,
}) => {
	const gridApiRef = useRef(null);
	const columnApiRef = useRef(null);
	const [rowData, setRowData] = useState(rows);
	const [tableHeight, setTableHeight] = useState(tableStyle);

	useEffect(() => {
		setRowData(rows);
	}, [rows]);

	useEffect(() => {
		setTableHeight(height ? { height: height } : { height: 500 });
	}, [height]);

	const onGridReady = (params) => {
		gridApiRef.current = params.api;
		columnApiRef.current = params.columnApi;
	};

	const serverSideSearchHandler = useCallback(
		(event) => {
			const { value } = event.target;
			handleSearch(value);
		},
		[handleSearch]
	);

	const clientSideSearchHandler = useCallback((event) => {
		gridApiRef.current?.setQuickFilter(event.target.value);
		if (
			gridApiRef.current?.getModel().rootNode.childrenAfterFilter.length > 0
		) {
			gridApiRef.current?.hideOverlay();
		} else {
			gridApiRef.current?.showNoRowsOverlay();
		}
	}, []);

	const onPageSizeChanged = () => {
		const { value } = document.getElementById('page-size');
		if (customPagination) {
			paginationChange(1, value);
		}
		gridApiRef.current.paginationSetPageSize(Number(value));
	};

	const onColumnResized = useCallback(() => {}, []);

	// const PerPageChange = (value) => {
	// 	setSize(value);
	// 	const newPerPage = Math.ceil(rowData.length / value);
	// 	if (current > newPerPage) {
	// 		setCurrent(newPerPage);
	// 	}
	// };

	const PrevNextArrow = (cur, type, originalElement) => {
		if (type === 'prev') {
			return <AiOutlineCaretLeft className="iconStyle" />;
		}
		if (type === 'next') {
			return <AiOutlineCaretRight className="iconStyle" />;
		}
		return originalElement;
	};

	return (
		<div className="ag-theme-alpine row">
			<div className="col-12">
				<div className="row justify-content-start">
					<div className={dropdown ? 'col-1' : 'col-4'}>
						{tableTitle && (
							<h5 className="p-3 m-0 mt-1 px-2 headerStyle text-start">
								{tableTitle}
							</h5>
						)}
					</div>
					{dropdown ? (
						<>
							<div className="col-4 pt-2">{dropdown && dropdown}</div>
							<div className="col-3"></div>
						</>
					) : (
						<div className="col-4"></div>
					)}

					<div className={addButton ? 'col-3' : 'col-4'}>
						{enableGlobalSearch && (
							<Form>
								<Form.Group
									className="p-2 search-bar"
									style={{ display: 'flex' }}
								>
									<Form.Control
										type="text"
										placeholder="Search"
										onChange={
											customPagination
												? serverSideSearchHandler
												: clientSideSearchHandler
										}
									/>
									<BiSearchAlt
										style={{
											position: 'relative',
											top: '10px',
											left: '-30px',
											fontSize: '22px',
											color: 'gray',
										}}
									/>
								</Form.Group>
							</Form>
						)}
					</div>
					{addButton && (
						<div className="col-1 p-2">
							<button
								className="positiveButton btnStyle p-2 mt-1"
								onClick={addButtonListner}
							>
								<span>{`+ ${'Add'}`}</span>
							</button>
						</div>
					)}
				</div>
			</div>
			<div style={tableHeight} className="position-relative">
				<AgGridReact
					defaultColDef={defaultColDef}
					onColumnResized={onColumnResized}
					modules={AllCommunityModules}
					onGridReady={onGridReady}
					pagination={pagination}
					columnDefs={columns}
					paginationPageSize={10}
					rowSelection={'single'}
					rowData={rowData}
					components={components}
					onCellClicked={cellClickedListener}
					onCellValueChanged={handleCellValueChanged}
					overlayNoRowsTemplate={'No Records To Show'}
				></AgGridReact>
				{pagination && !customPagination && (
					<div className="example-header px-2">
						<Form.Select
							aria-label="Default select example"
							id="page-size"
							onChange={onPageSizeChanged}
						>
							<option value="10" selected={true}>
								10
							</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</Form.Select>
					</div>
				)}
			</div>
			{!pagination && customPagination && (
				<div className="d-flex mt-2 paginationCard  justify-content-between ">
					<div className="align-self-start">
						<Form.Select
							aria-label="Default select example"
							id="page-size"
							onChange={onPageSizeChanged}
						>
							<option value="10" selected={true}>
								10
							</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</Form.Select>
					</div>
					<div className="pagination align-self-end">
						<Pagination
							className="pagination-data"
							showTotal={(total, range) =>
								`Showing ${range[0]}-${range[1]} of ${total}`
							}
							onChange={paginationChange}
							total={searchedRecords === 0 ? totalNoOfRecords : searchedRecords}
							current={currentPage}
							pageSize={size}
							showSizeChanger={false}
							itemRender={PrevNextArrow}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

DataTable.defaultProps = defaultProps;

export default React.memo(DataTable);
