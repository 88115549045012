import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FaEdit } from 'react-icons/fa';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import DataTable from '../../common-components/DataTable/DataTable';
import { EMPLOYEE, userRoles } from '../../constants/AppConstants';
import EmployeeServices from '../../services/employeeService';

export const EmployeeTable = ({
	employeeList,
	userRole,
	onSize,
	onPageNum,
	pageSize,
	pageNum,
	handleSearch,
	totalNoOfRecords,
	searchedRecords,
}) => {
	const [state, setState] = useState([]);
	useEffect(() => {
		setState([
			{
				name: 'Employees',
				route: '',
				isLink: false,
			},
		]);
	}, []);

	const navigate = useNavigate();
	const employeeservice = new EmployeeServices();
	const onEdit = (data) => {
		employeeservice.getEmployeeById(data._id).then((response) => {
			// eslint-disable-next-line no-shadow
			const { data } = response;
			const path = `/employees/${data?._id}`;
			navigate(path, { state: data });
		});
	};

	const cellClickedListener = (event) => {
		if (event.value) {
			const path = `/employees/employeeProfile/${event.data?._id}`;
			navigate(path, { state: event.data });
		}
	};

	const addEmployeeHandler = () => {
		const path = '/employees/addEmployees';
		navigate(path, { state: employeeList });
	};

	const cloumns = [
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 160, // set a minimum width for the column
			maxWidth: 240, // set a maximum width for the
			cellRenderer: function (params) {
				return (
					<div title={params.value}>
						{params.value ? params.value.substring(0, 70) : ''}
					</div>
				);
			},
		},
		{
			field: 'fissionId',
			headerName: 'FL Id',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'emailId',
			headerName: 'Email Id',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			width: undefined, // or omit the width property altogether
			minWidth: 290, // set a minimum width for the column
			maxWidth: 300, // set a maximum width for the column
			cellRenderer: function (params) {
				return (
					<div title={params.value}>
						{params.value ? params.value.substring(0, 70) : ''}
					</div>
				);
			},
		},
		// {
		// 	field: 'considerTech',
		// 	headerName: 'Technology',
		// 	headerClass: 'my-header-class',
		// 	cellStyle: { textAlign: 'center' },
		// },
		{
			field: 'experience',
			headerName: 'Experience',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'manager',
			headerName: 'Manager',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'action',
			headerName: 'Action',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					{/* <button
		          className="btn btn-outline-secondary pt-0 pb-0 me-2"
		          onClick={() => onEdit(params?.data)}
		        >
		          Edit
		        </button> */}
					<FaEdit onClick={() => onEdit(params?.data)} className="edit-icon" />
				</>
			),
		},
	];
	const getColumns = () => {
		if (userRole === userRoles.manager) {
			return cloumns.filter((column) => column.field !== 'action');
		}
		return cloumns;
	};

	const paginationChange = (updatedPageNumber, updatedPageSize) => {
		onSize(updatedPageSize);
		onPageNum(updatedPageNumber);
	};

	return (
		<div className="">
			<BreadCrumb data={state} />
			{/* <div className="d-flex justify-content-end">
        <Button className="add-button" onClick={addEmployeeHandler}>
          <span>{`+ ${BUTTONS.add}`}</span>
        </Button>
      </div> */}
			<DataTable
				addButton={![userRoles.manager, userRoles.trainee].includes(userRole)}
				addButtonListner={addEmployeeHandler}
				height={500}
				columns={getColumns()}
				rows={employeeList}
				tableTitle={EMPLOYEE.tableHeading}
				pagination={false}
				customPagination={true}
				enableGlobalSearch={true}
				cellClickedListener={cellClickedListener}
				handleSearch={handleSearch}
				paginationChange={paginationChange}
				currentPage={pageNum}
				size={pageSize}
				totalNoOfRecords={totalNoOfRecords}
				searchedRecords={searchedRecords}
			/>
		</div>
	);
};
