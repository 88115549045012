import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import DataTable from '../../../common-components/DataTable/DataTable';
import Card from '../../../common-components/Card/Card';
import '../Styles.css';

function BatchAssessmentsProgress(props) {
	const [startDate] = useState([]);
	const [endDate] = useState([]);

	const columns = [
		{
			field: 'assessment_name',
			headerName: 'Assessment Name',
		},
		{
			field: 'date',
			headerName: 'Start Date',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'EndDate',
			headerName: 'End Date',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'marks',
			headerName: ' Average Score',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => <>{params.value ? `${params.value}` : ''} </>,
		},
	];
	const graphdata = [];
	const scores = [];
	// let startDate = [];
	// let endDate = [];
	if (props.assessmentPerBatchId !== undefined) {
		props.assessmentPerBatchId.map((e) => graphdata.push(e.assessment_name));
		props.assessmentPerBatchId.map((e) => {
			startDate.push(e.date);
			endDate.push(e.EndDate);
			return scores.push(e.marks);
		});
	}

	const progressGraphData = {
		series: [
			{
				name: 'Score',
				data: scores !== undefined ? scores : [],
			},
		],
		options: {
			chart: {
				id: 'fb',
				group: 'social',
				height: 350,
				type: 'line',

				dropShadow: {
					enabled: true,
					color: '#c2d2ef',
					font: 2,
					top: 18,
					left: 7,
					blur: 10,
					opacity: 0.2,
				},
				toolbar: {
					show: false,
				},
			},
			colors: ['#c2d2ef', '#c2d2ef'],
			dataLabels: {
				enabled: true,
			},

			grid: {
				borderColor: '#e7e7e7',
				row: {
					colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
					opacity: 0.5,
				},
			},
			markers: {
				size: 1,
			},
			xaxis: {
				categories: graphdata,
				tickPlacement: 'between',
				title: {
					text: 'Assesment Score',
				},
			},
			legend: {
				position: 'top',
				horizontalAlign: 'right',
				floating: true,
				offsetY: -25,
				offsetX: -5,
			},
			tooltip: {
				custom: function ({ series, seriesIndex, dataPointIndex }) {
					const score = series[seriesIndex][dataPointIndex];
					const sDate = startDate[dataPointIndex];
					const eDate = endDate[dataPointIndex];
					return `<div style="text-align: left; padding: 5px; border-radius: 5px;">
            <ul style="list-style-type:none; padding:0px; margin:0px">
              <li><span style="font-size: 14px; font-weight: bold;">Average Score:</span><span style="margin-left: 5px">${score}%</span></li>
              <li><span style="font-size: 14px; font-weight: bold;">StartDate:</span><span style="margin-left: 5px">${sDate}</span></li>
              <li><span style="font-size: 14px; font-weight: bold;">EndDate:</span><span style="margin-left: 5px">${eDate}</span></li>
            </ul>
          </div>`;
				},
			},
		},
	};

	return (
		<div className="d-flex mt-0 gap-4 mt-1 mb-3">
			<div style={{ width: '45rem' }}>
				{props?.assessmentPerBatchId?.length ? (
					<>
						<Card className="text-center mt-2">
							<div className="subHeader text-start ">
								<h3 className="text-start mt-1">Assessments</h3>
							</div>
							<DataTable
								columns={columns}
								rows={
									props.assessmentPerBatchId
										? props.assessmentPerBatchId
										: 'NO DATA'
								}
								height={9 * 46}
								pagination={false}
								customPagination={false}
							/>
						</Card>
					</>
				) : (
					<>
						<Card className="text-center mt-2 pb-2">
							<div className="subHeader text-start">
								<h3 className="text-start mt-1">Assessments</h3>
							</div>
							<small>No Records To Show</small>
						</Card>
					</>
				)}
			</div>

			<div style={{ width: '46rem' }}>
				{props?.assessmentPerBatchId?.length ? (
					<>
						<Card className="text-center mt-2">
							<div className="subHeader text-start">
								<h3 className="text-start mt-1">Performance Analysis</h3>
							</div>
							<ReactApexChart
								style={{
									background: 'white',
									borderRadius: '10px',
									boxShadow: '0px -2px 16px -4px rgba(16, 24, 40, 0.12)',
									marginTop: '0.2rem',
								}}
								options={progressGraphData.options}
								series={progressGraphData.series}
								type="line"
								height={9 * 44}
							/>
						</Card>
					</>
				) : (
					<>
						<Card className="text-center mt-2 pb-2">
							<div className="subHeader text-start ">
								<h3 className="text-start mt-1">Performance Analysis</h3>
							</div>
							<small>No Records to Show</small>
						</Card>
					</>
				)}
			</div>
		</div>
	);
}

export default BatchAssessmentsProgress;
