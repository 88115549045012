import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const TypeaheadComponent = ({
	id,
	name,
	onChange,
	options,
	labelKey,
	placeholder,
	selected,
	error,
	errorMsg,
	multiple,
	className,
}) => (
	<div>
		<label className="inputLabel">
			{name} <span className="error">*</span>
		</label>
		<Typeahead
			className={className}
			multiple={multiple}
			single={!multiple}
			id={id}
			name={name}
			onChange={onChange}
			options={options}
			labelKey={labelKey}
			placeholder={placeholder}
			selected={selected}
		/>
		<p className="errorText">{error ? errorMsg : ''}</p>
	</div>
);

export default TypeaheadComponent;
