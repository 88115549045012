import React from 'react';
import './PageNotFound.css';

export const PageNotFound = () => (
	<div className="error-container">
		<div className="error-message">
			<h1>Oops! Page not found</h1>
			<p>
				The page you are looking for might have been removed or is temporarily
				unavailable.
			</p>
			<button className="back-btn" onClick={() => window.history.back()}>
				Go back
			</button>
		</div>
	</div>
);
