import React, { useState, useEffect } from 'react';
import { FaShareSquare, FaEdit } from 'react-icons/fa';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import { FeedbackTable } from '../FeedbackTable/FeedbackTable';
import TraineeTrainerFeedback from './TraineeFeedback/TraineeFeedback';
import {
	getAllFeedback,
	getFeedbackFormByIdForAdmin,
	getAllBatchBycourse,
} from '../../../services/feedbackService';
import ProgramServices from '../../../services/programServices';
import { userRoles } from '../../../constants/AppConstants';
import { BreadCrumb } from '../../../common-components/BreadCrumb/BreadCrumb';
import ShareFeedback from '../ShareFeedback';
import './FeedbackContainer.css';

function FeedbackContainer(props) {
	const [feedbackFormModal, setFeedbackFormModal] = useState({
		create: false,
		edit: false,
	});
	const [feedbackList, setFeedbackList] = useState([]);
	const [selectedFeedbackFormToEdit, setSelectedFeedbackFormToEdit] = useState(
		{}
	);
	const [shareFeedbackModal, setShareFeedbackModal] = useState(false);
	const [selectedForm, setSelectedForm] = useState('');
	const [allPrograms, setAllPrograms] = useState([]);
	const [formattedBatches, setFormattedBatches] = useState([]);
	const [courseId, setCourseId] = useState();
	const [breadcrumb] = useState([
		{
			name: 'Feedbacks',
			route: '',
			isLink: false,
		},
	]);
	const feedbackFormModalHandler = () => {
		setFeedbackFormModal({
			create: true,
			edit: false,
		});
	};
	const getAllFeedbacksList = () => {
		getAllFeedback().then((res) => {
			const data = res?.data;
			data.forEach((el, i) => {
				const item = el;
				item.id = i + 1;
			});
			setFeedbackList(data);
		});
	};

	const getAllBatchesByCourseAndForm = (formId, CourseId) => {
		getAllBatchBycourse(formId, CourseId).then((res) => {
			const batchData = res.data;
			const formattedBatchList = batchData.map((item) => {
				const formattedFeedback = item.forwardfeedback || {};

				const updatedForwardFeedback = {
					Trainee: formattedFeedback.Trainee || null,
					Trainer: formattedFeedback.Trainer || null,
					Manager: formattedFeedback.Manager || null,
					TraineeStatus: !!formattedFeedback.Trainee,
					TrainerStatus: !!formattedFeedback.Trainer,
					ManagerStatus: !!formattedFeedback.Manager,
				};
				return {
					batchId: item._id,
					name: item.name,
					...updatedForwardFeedback,
				};
			});
			setFormattedBatches(formattedBatchList);
		});
	};

	const programService = new ProgramServices();
	const getAllProgramList = (formId) => {
		programService.getAllPrograms().then((res) => {
			const getAllPrograms = res.data;
			setAllPrograms(getAllPrograms);
			setCourseId(getAllPrograms[0]?._id);
			getAllBatchesByCourseAndForm(
				formId?.length ? formId : selectedForm?._id,
				getAllPrograms[0]?._id
			);
		});
	};

	useEffect(() => {
		if ([userRoles.admin, userRoles.manager].includes(props?.userRole)) {
			getAllFeedbacksList();
		}
	}, [props?.userRole]);

	const onForward = (params) => {
		setSelectedForm(params.data);
		setShareFeedbackModal(true);
		setFormattedBatches([]);
		getAllProgramList(params?.data?._id);
	};

	const onEdit = (data) => {
		getFeedbackFormByIdForAdmin(data._id).then((response) => {
			const responseData = response.data;
			const modifiedQuestions = responseData?.question?.map((question) => ({
				que: question?.que,
				que_No: question?.que_No,
			}));
			setSelectedFeedbackFormToEdit({
				...responseData,
				question: modifiedQuestions,
			});
		});
		setFeedbackFormModal({
			create: false,
			edit: true,
		});
	};

	const columnList = [
		{
			field: 'id',
			headerName: 'Sl.No.',
		},
		{
			field: 'formName',
			headerName: 'Form Name',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'feedbackOn',
			headerName: 'Feedback On',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'formVersion',
			headerName: 'Form Version',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'createdBy.name',
			headerName: 'Created By',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'action',
			headerName: 'Action',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					<FaShareSquare
						onClick={() => onForward(params)}
						className="edit-icon"
					/>
					<FaEdit
						onClick={() => onEdit(params?.data)}
						className="edit-icon ms-2 "
					/>
				</>
			),
		},
	];

	const selectDropDown = (e) => {
		const { value } = e.target;
		setCourseId(value);
		setFormattedBatches([]);
		getAllBatchesByCourseAndForm(selectedForm?._id, value || courseId);
	};

	const trainerFeedbackColumns = [
		{
			field: 'id',
			headerName: 'Sl.No.',
		},
		{
			field: 'formName',
			headerName: 'Form Name',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'feedbackOn',
			headerName: 'Feedback On',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'status',
			headerName: 'Status',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'batchName',
			headerName: 'Batch Name',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'submittedOn',
			headerName: 'Submitted On',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
	];

	return (
		<>
			<BreadCrumb data={breadcrumb} />
			{props.userRole === userRoles.admin && (
				<>
					<FeedbackForm
						modalState={feedbackFormModal}
						updateModalState={setFeedbackFormModal}
						getAllFeedbacksList={getAllFeedbacksList}
						selectedFeedbackFormToEdit={
							feedbackFormModal?.edit ? selectedFeedbackFormToEdit : {}
						}
						updateSelectedFeedbackFormToEdit={setSelectedFeedbackFormToEdit}
					/>
					<FeedbackTable
						columnList={columnList}
						feedbackList={feedbackList}
						addFeedbackHandler={feedbackFormModalHandler}
					/>
					{[userRoles.admin, userRoles.manager].includes(props.userRole) && (
						<ShareFeedback
							modalState={shareFeedbackModal}
							formInfo={selectedForm}
							allPrograms={allPrograms}
							courseHandler={selectDropDown}
							updateModalState={setShareFeedbackModal}
							formattedBatchList={formattedBatches}
						/>
					)}
				</>
			)}
			{[userRoles.trainee, userRoles.trainer].includes(props.userRole) && (
				<>
					<TraineeTrainerFeedback
						trainerFeedbackColumns={trainerFeedbackColumns}
						userRole={props.userRole}
					/>
				</>
			)}
		</>
	);
}

export default FeedbackContainer;
