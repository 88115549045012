import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import DashboardService from '../../services/dashboardService';
import { NoOfAssessmentsAndBatches } from './NoOfAssessmentsAndBatches';
import { TopFive } from './TopFive';
import { BatchSpectrum } from './BatchSpectrum';
import { TopFiveBatchesTechwise } from './TopFiveBatchesTechwise';
import {
	DASHBOARDQUARTERDROPDOWN,
	DASHBOARDYEARDROPDOWN,
} from '../../constants/AppConstants';
import { formatterForDashboard, setTheCurrentYear } from '../../utils/helper';
import { Info } from './Info';
import { EmployeeSpectrumForYear } from './EmployeeSpectrumForYear';
import { DashboardAssessmentsGraph } from './DashboardAssessmentsGraph';
import './Styles.css';

export function DashboardContainer() {
	const [quarter, setQuarter] = useState({
		label: '',
		fromDate: '',
		tillDate: '',
	});
	// Object.values(DASHBOARDQUARTERDROPDOWN)[0]
	const [year, setYear] = useState();
	const [noOfAssessmentsBatchwise, setNoOfAssessmentsBatchwise] = useState({
		names: [],
		assessmentCount: [],
	});
	const [employee, setEmployee] = useState({
		domainsList: [],
		domainPercentages: [],
		totalTrainee: [],
	});
	const [resourceCount, setResourceCount] = useState({});
	const [noOfBatchesTechwise, setNoOfBatchesTechwise] = useState({
		names: [],
		batchCount: [],
	});

	const quarterFormatter = (selectedYear, selectedQuarter) => {
		const fromDate = moment(`${selectedYear}-${selectedQuarter}`, 'YYYY-Q')
			.startOf('quarter')
			.toISOString();

		const tillDate = moment(`${selectedYear}-${selectedQuarter}`, 'YYYY-Q')
			.endOf('quarter')
			.toISOString();
		return { fromDate, tillDate };
	};

	const selectHandler = (event, isYearDropdown) => {
		if (isYearDropdown) {
			if (year.year !== event) {
				setYear({ year: event });
				setQuarter({ label: '', fromDate: '', tillDate: '' });
			}
		} else {
			const { fromDate, tillDate } = quarterFormatter(year.year, event);
			setQuarter({ label: event, fromDate, tillDate });
		}
	};

	const dashboardServices = new DashboardService();
	const ifQuarter = {
		quarter: {
			fromDate: quarter?.fromDate,
			tillDate: quarter?.tillDate,
		},
	};

	const ifYear = {
		year: year?.year,
	};

	// All Batches for Assessments & Employees Spectrum
	const getEmployeeSpectrumInfoDetails = async () => {
		try {
			const response = await dashboardServices.getEmployeeSpectrumInfo(
				quarter.label.length ? ifQuarter : ifYear
			);
			const responseData = response?.data || [];
			const [employeesBatchNames, totalNoOfTrainees, traineePercentagesList] =
				formatterForDashboard(
					responseData,
					'domain',
					'totalCount',
					'Percentage'
				);
			setEmployee((prevState) => ({
				...prevState,
				domainsList: employeesBatchNames || [],
				domainPercentages: traineePercentagesList || [],
				totalTrainee: totalNoOfTrainees || [],
			}));
		} catch (error) {
			toast.error('Error fetching employee spectrum info:', error);
		}
	};

	// No of batches techwise
	const getTechWiseBatchList = async () => {
		try {
			const DashboardServices = new DashboardService();
			const response = await DashboardServices.getTechwiseBatchList(
				quarter.label.length ? ifQuarter : ifYear
			);
			const responseData = response?.data || [];
			const [techWiseNames, techWiseBatchCountList] = formatterForDashboard(
				responseData,
				'_id',
				'batchcount'
			);
			setNoOfBatchesTechwise({
				names: techWiseNames || [],
				batchCount: techWiseBatchCountList || [],
			});
		} catch (error) {
			toast.error('Error fetching techwise batch list:', error);
		}
	};

	// No of  assessments batchewise getResourceCount
	const getBatchWiseAssessmentsList = async () => {
		try {
			const DashboardServices = new DashboardService();
			const response = await DashboardServices.getBatchwiseAssessmentsList(
				quarter.label.length ? ifQuarter : ifYear
			);
			const responseData = response?.data || [];
			const [batchWiseNames, batchWiseAssessmentsCountData] =
				formatterForDashboard(responseData, 'name', 'asesmentCount');
			setNoOfAssessmentsBatchwise({
				names: batchWiseNames || [],
				asesmentCount: batchWiseAssessmentsCountData || [],
			});
		} catch (error) {
			toast.error('Error fetching batchwise assessments list:', error);
		}
	};

	// Resource Count
	const getResourceCount = async () => {
		try {
			const DashboardServices = new DashboardService();
			const response = await DashboardServices.getResourceCount(
				quarter.label.length ? ifQuarter : ifYear
			);
			setResourceCount(response?.data);
		} catch (error) {
			toast.error('Error fetching resource count:', error);
		}
	};

	useEffect(() => {
		setTheCurrentYear(DASHBOARDYEARDROPDOWN, setYear);
	}, []);

	useEffect(() => {
		if (year?.year) {
			getEmployeeSpectrumInfoDetails();
			getBatchWiseAssessmentsList();
			getTechWiseBatchList();
			getResourceCount();
		}
	}, [year?.year, quarter?.label]);

	return (
		<>
			<div className="row">
				<div className="row p-2">
					<div className="col d-flex">
						<div className="text-center d-flex justify-content-start">
							<h6 className="year text-center mt-1 mx-1">Select Year : </h6>
							<div>
								<DropdownButton
									onSelect={(eventKey) => selectHandler(eventKey, true)}
									title={year?.year}
									className="text-center mt-1"
									defaultActiveFirstItem
								>
									{DASHBOARDYEARDROPDOWN.map((key, i) => (
										<Dropdown.Item
											eventKey={key?.year}
											key={i}
											className={year?.year === key.year ? 'active ' : ''}
										>
											{key.year}
										</Dropdown.Item>
									))}
								</DropdownButton>
							</div>
						</div>
						<div className="text-center d-flex justify-content-start">
							<h5 className="year text-center mt-1 mx-1">Select Quarter : </h5>
							<div>
								<DropdownButton
									onSelect={(eventKey) => selectHandler(eventKey, false)}
									title={quarter?.label || 'Select'}
									className="text-center mt-1 dropdownStyle"
								>
									{DASHBOARDQUARTERDROPDOWN.map((key, i) => (
										<Dropdown.Item
											eventKey={key?.label}
											key={i}
											className={quarter?.label === key.label ? 'active ' : ''}
										>
											{key.label}
										</Dropdown.Item>
									))}
								</DropdownButton>
							</div>
						</div>
					</div>
				</div>
				<Row>
					<Col sm={8}>
						<EmployeeSpectrumForYear
							names={employee?.domainsList?.length ? employee?.domainsList : []}
							percentages={
								employee?.domainPercentages?.length
									? employee?.domainPercentages
									: []
							}
							totalTrainee={
								employee?.totalTrainee?.length ? employee?.totalTrainee : []
							}
						/>
					</Col>
					<Col sm={4}>
						<Info
							employeeCount={resourceCount?.TotalEmployee}
							duration={resourceCount?.duration}
							totalCourseCount={resourceCount?.TotalCourse_count}
							totalBatches={resourceCount?.batchCount}
						/>
					</Col>
				</Row>
				<Row className="mt-3">
					<Col sm={8}>
						{year?.year && (
							<>
								<BatchSpectrum quarter={quarter} year={year} />
								<DashboardAssessmentsGraph quarter={quarter} year={year} />
							</>
						)}
					</Col>
					<Col sm={4}>
						{year?.year && (
							<>
								<TopFive
									title={'Employees Scoreboard'}
									quarter={quarter}
									year={year}
								/>
								<TopFiveBatchesTechwise quarter={quarter} year={year} />
							</>
						)}
					</Col>
				</Row>
				<Row className="mt-1 mb-4">
					<Col sm={6}>
						<NoOfAssessmentsAndBatches
							title={'No of Assessments Batchwise'}
							names={noOfAssessmentsBatchwise?.names}
							scores={noOfAssessmentsBatchwise?.asesmentCount}
							hoverText="No of Assessments "
						/>
					</Col>
					<Col sm={6}>
						<NoOfAssessmentsAndBatches
							title={'No of Batches Techwise'}
							names={noOfBatchesTechwise?.names}
							scores={noOfBatchesTechwise?.batchCount}
							hoverText="No of Batches "
						/>
					</Col>
				</Row>
			</div>
		</>
	);
}
