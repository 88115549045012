import React from 'react';
import { useNavigate } from 'react-router';
import DataTable from '../../common-components/DataTable/DataTable';

export const TrainerCoursesTable = ({
	columns,
	trainerCourses,
	selectedCourse,
	handleSearch,
	onSize,
	onPageNum,
	pageSize,
	pageNum,
	totalNoOfRecords,
	searchedRecords,
	searchValue,
}) => {
	const navigate = useNavigate();

	const cellClickedListener = (event) => {
		const modalTrue =
			event.colDef.field === 'action' || event.colDef.field === 'batchCount';

		if (!modalTrue && event?.data?._id) {
			navigate(
				`/trainerCourses/trainerCourseDetails/${event?.data?._id || ''}`,
				{ state: event?.data?._id }
			);
		}
	};

	const handleEvent = () => {
		const path = `/course/${'addCourse'}`;
		navigate(path, { state: 'trainerCourses' });
	};

	const paginationChange = (updatedPageNumber, updatedPageSize) => {
		onSize(updatedPageSize);
		onPageNum(updatedPageNumber);
	};
	return (
		<div>
			<DataTable
				addButton={selectedCourse === 'myCourse'}
				addButtonListner={handleEvent}
				height={500}
				columns={columns}
				rows={trainerCourses}
				tableTitle={'Trainer Courses'}
				pagination={false}
				customPagination={selectedCourse === 'AllCourses'}
				enableGlobalSearch={selectedCourse === 'AllCourses'}
				cellClickedListener={cellClickedListener}
				handleSearch={handleSearch}
				paginationChange={paginationChange}
				currentPage={pageNum}
				size={pageSize}
				totalNoOfRecords={totalNoOfRecords}
				searchedRecords={searchedRecords}
				searchValue={searchValue}
			/>
		</div>
	);
};
