import React from 'react';
import Card from '../Card/Card';
import { EMPLOYEE } from '../../constants/AppConstants';
// import "./Profile.css";

export const Skills = (props) => (
	<Card className="pe-5">
		<div className="text-start px-4 py-4">
			<h5 className="mb-4">{EMPLOYEE.skills}</h5>
			<ul className="list-unstyled">
				{props?.skills?.length ? (
					props?.skills.map((item) => (
						<li
							className="d-inline mx-1 chip fw-bold px-3 py-2 me-2 text-white"
							key={item._id}
						>
							{item ? item.skillName : 'No Data'}
						</li>
					))
				) : (
					<li>No Data</li>
				)}
			</ul>
		</div>
	</Card>
);
