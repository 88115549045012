import React, { useState } from 'react';
import './NavbarTabs.css';

function NavBarTabs(props) {
	const [activeClass, setActiveClass] = useState(props?.activeValue);

	const changeHandler = (course) => {
		setActiveClass(course);
		props.onCourseChange(course);
	};

	return (
		<ul className={'d-flex mt-2 ps-0'}>
			{props?.tabs.map((tab) => (
				<li
					key={tab.id}
					className={`listStyles ${activeClass === tab.id && 'active-tab'}`}
					onClick={() => changeHandler(tab.id)}
				>
					{tab.label}
				</li>
			))}
		</ul>
	);
}

export default NavBarTabs;
