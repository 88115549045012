export const getHeaders = (headerType) => {
	switch (headerType) {
		case 'auth':
			return {
				//   contentType: "application/json",
				Authorization: `Bearer ${localStorage.getItem('headerToken')}`,
				// Accept: "application/json",
				// 'Access-Control-Allow-Origin': '*',
				// 'Cache-Control': 'no-cache',
			};
		case 'FormData':
			return {
				Authorization: `Bearer ${localStorage.getItem('headerToken')}`,
				'Content-Type': ['multipart/form-data', 'application/JSON'],
			};
		default:
			return {
				// contentType: 'application/json',
				// 'Access-Control-Allow-Origin': '*',
				// 'Cache-Control': 'no-cache',
			};
	}
};
