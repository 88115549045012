import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import {
	EMPLOYEE,
	DROPDOWNITEMSFORTRAININGS,
} from '../../constants/AppConstants';
import DataTable from '../../common-components/DataTable/DataTable';
import EmployeeServices from '../../services/employeeService';
import TrainerServices from '../../services/trainerService';
// import Status from './status/Status';
import { BreadCrumb } from '../../common-components/BreadCrumb/BreadCrumb';
import { Profile } from '../../common-components/Profile/Profile';
import './Styles.css';

const EmployeeProfile = () => {
	const [state, setState] = useState([]);
	const [trainer, setTrainer] = useState({
		selectedTrainer: {},
		trainerAsTrainee: [],
		trainerAsTrainer: [],
		assessments: [],
	});
	const [modal, setModal] = useState(false);
	const [traineeBatchStatus, setTraineeBatchStatus] = useState(
		Object.values(DROPDOWNITEMSFORTRAININGS)[0]
	);
	const [trainerBatchStatus, setTrainerBatchStatus] = useState(
		Object.values(DROPDOWNITEMSFORTRAININGS)[0]
	);
	const traineeBatchSelectHandler = (key, event) => {
		setTraineeBatchStatus({ status: key, label: event.target.textContent });
	};
	const trainerBatchSelectHandler = (key, event) => {
		setTrainerBatchStatus({ status: key, label: event.target.textContent });
	};
	const [employeeProfile, setEmployeeProfile] = useState();

	const location = useLocation();
	const employeeId = location?.state?._id;
	const trainerId = location.state?._id;

	useEffect(() => {
		setState([
			{
				name: 'Employees',
				route: '/employees',
				isLink: true,
				id: 1,
			},
			{
				name: employeeProfile?.name,
				route: `/employees/${employeeProfile?.name}`,
				isLink: false,
				id: 2,
			},
		]);
	}, [employeeProfile?.name]);

	const fetchEmployeeById = async (selectedTrainerId) => {
		try {
			const obj = new EmployeeServices();
			const res = await obj.getEmployeeById(selectedTrainerId);
			const responseData = res?.data;
			const formattedTrainer = {
				...responseData,
				doj: moment(responseData.doj).format('DD-MM-yyyy'),
			};
			setTrainer((prevState) => ({
				...prevState,
				selectedTrainer: formattedTrainer,
			}));
		} catch (error) {
			toast.error('Error fetching employee by ID:', error);
		}
	};

	const fetchTrainerAsTrainee = async (
		loggedInEmployeeId,
		loggedInTaineeBatchStatus
	) => {
		try {
			const status = {
				status: loggedInTaineeBatchStatus.status,
			};
			const obj = new TrainerServices();
			const res = await obj.trainerAsTrainee(loggedInEmployeeId, status);
			const trainerRes = res?.data;
			const formattedTrainer = trainerRes?.map((eachTrainer) => ({
				...eachTrainer,
				startDate: moment(eachTrainer.startDate).format('D MMM YYYY'),
				endDate: moment(eachTrainer.endDate).format('D MMM YYYY'),
				assementaveragedetails:
					eachTrainer.assementaveragedetails === 'null'
						? 'No Data'
						: eachTrainer.assementaveragedetails,
			}));
			setTrainer((prevState) => ({
				...prevState,
				trainerAsTrainee: formattedTrainer || [],
			}));
		} catch (error) {
			toast.error('Error fetching trainer as trainee:', error);
		}
	};

	const fetchEmployeeProfileById = async () => {
		try {
			const obj = new EmployeeServices();
			const response = await obj.getEmployeeById(employeeId);
			const responseData = response?.data;
			setEmployeeProfile({ ...responseData });
		} catch (error) {
			toast.error('Error fetching employee profile by ID:', error);
		}
	};

	const fetchTrainerAsTrainer = async (
		loggedInTrainerId,
		loggedInTrainerBatchStatus
	) => {
		try {
			const status = {
				status: loggedInTrainerBatchStatus.status,
			};
			const obj = new TrainerServices();
			const res = await obj.trainerAsTrainer(loggedInTrainerId, status);
			const trainerResponse = res?.data;
			const formattedTrainer = trainerResponse?.map((trainerData) => ({
				...trainerData,
				startDate: moment(trainerData.startDate).format('D MMM YYYY'),
				endDate: moment(trainerData.endDate).format('D MMM YYYY'),
			}));
			setTrainer((prevState) => ({
				...prevState,
				trainerAsTrainer: formattedTrainer || [],
			}));
		} catch (error) {
			toast.error('Error fetching trainer as trainer:', error);
		}
	};

	useEffect(() => {
		fetchEmployeeById(trainerId);
	}, [trainerId]);

	useEffect(() => {
		fetchTrainerAsTrainee(employeeId, traineeBatchStatus);
	}, [employeeId, traineeBatchStatus]);

	useEffect(() => {
		fetchEmployeeProfileById(employeeId);
	}, [employeeId]);

	useEffect(() => {
		fetchTrainerAsTrainer(trainerId, trainerBatchStatus);
	}, [trainerId, trainerBatchStatus]);

	const trainerAsTrainee = [
		{
			field: 'name',
			headerName: ' Name',
		},
		{
			field: 'startDate',
			headerName: 'Start Date',
		},
		{
			field: 'endDate',
			headerName: 'End Date',
		},
		{
			field: 'progress',
			headerName: 'Progress',
			width: 10,
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					<span>{params.value ? `${params.value}%` : 'No Data'}</span>
				</>
			),
		},
		{
			field: 'assesmentcount',
			headerName: 'Assessments',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => {
				const openModal = () => {
					const formattedTrainer = params?.data?.assesmentdata.map((obj) => ({
						...obj,
						startDate: moment(obj.startDate).format('D MMM YYYY'),
						deadline: moment(obj.deadline).format('D MMM YYYY'),
						type: obj?.type === 'Final' ? 'Final' : 'By Weekly',
					}));
					setTrainer({ ...trainer, assessments: formattedTrainer });
					setModal(true);
				};
				return (
					<>
						<u
							className={params?.value > 0 ? 'average' : ''}
							onClick={params?.value > 0 ? openModal : null}
						>{`${params.value}`}</u>
					</>
				);
			},
		},
		{
			field: 'assementaveragedetails',
			headerName: 'Average',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					<span>{params.value ? `${params.value}` : 'No Data'}</span>
				</>
			),
		},
	];

	const trainerAsTrainer = [
		{
			field: 'name',
			headerName: ' Name',
		},
		{
			field: 'startDate',
			headerName: 'Start Date',
		},
		{
			field: 'endDate',
			headerName: 'End Date',
		},
		{
			field: 'progress',
			headerName: 'Progress',
			width: 10,
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					<span>{`${params.value}%`}</span>
				</>
			),
		},
	];

	const Assessment = [
		{
			field: 'name',
			headerName: 'Name',
		},
		{
			field: 'startDate',
			headerName: 'Start Date',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'deadline',
			headerName: 'End Date',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
		},
		{
			field: 'score',
			headerName: 'Score',
			headerClass: 'my-header-class',
			cellStyle: { textAlign: 'center' },
			cellRenderer: (params) => (
				<>
					<span>{params?.value ? params.value : 0}</span>
				</>
			),
		},
		{
			field: 'type',
			headerName: 'Type',
		},
	];

	const onClosePopup = () => {
		setModal(false);
	};

	return (
		<div className="pb-4">
			<BreadCrumb data={state} />
			<div className="d-flex gap-5">
				{Object.keys(trainer?.selectedTrainer)?.length > 0 && (
					<Profile employee={trainer.selectedTrainer} title={EMPLOYEE.title} />
				)}

				<div className="size">
					{/* <Status
						profile={employeeProfile}
						training={
							trainer?.trainerAsTrainee?.length ? trainer?.trainerAsTrainee : []
						}
					/> */}
					<div className="d-flex justify-content-end mt-3">
						<DropdownButton
							style={{ top: '44px' }}
							onSelect={traineeBatchSelectHandler}
							title={traineeBatchStatus?.label || traineeBatchStatus}
						>
							{DROPDOWNITEMSFORTRAININGS.map((key, i) => (
								<Dropdown.Item eventKey={key.status} key={i}>
									{key.label}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</div>
					<DataTable
						height={5 * 54}
						columns={trainerAsTrainee}
						rows={
							trainer?.trainerAsTrainee?.length ? trainer?.trainerAsTrainee : []
						}
						tableTitle="As Trainee"
						pagination={false}
						enableGlobalSearch={false}
						enableDropdown={true}
					/>
					{trainer?.trainerAsTrainer ? (
						<>
							<div className="d-flex justify-content-end mt-3">
								<DropdownButton
									style={{ top: '44px' }}
									onSelect={trainerBatchSelectHandler}
									title={trainerBatchStatus?.label || trainerBatchStatus}
								>
									{DROPDOWNITEMSFORTRAININGS.map((key, i) => (
										<Dropdown.Item eventKey={key.status} key={i}>
											{key.label}
										</Dropdown.Item>
									))}
								</DropdownButton>
							</div>
							<DataTable
								height={5 * 54}
								columns={trainerAsTrainer}
								rows={
									trainer?.trainerAsTrainer?.length
										? trainer.trainerAsTrainer
										: []
								}
								tableTitle="As Trainer"
								pagination={false}
								enableGlobalSearch={false}
								enableDropdown={true}
								dropdownOptions={['Ongoing Trainings', 'Completed Trainings']}
							/>
						</>
					) : (
						[]
					)}
				</div>
			</div>
			<Modal show={modal} onHide={onClosePopup} centered size="lg">
				<Modal.Header closeButton>
					<Modal.Title className="mx-3">Assessment List</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text-center p-2 m-2">
						<DataTable
							height={300}
							columns={Assessment}
							rows={trainer?.assessments?.length ? trainer.assessments : []}
							pagination={false}
							enableGlobalSearch={false}
							enableDropdown={true}
						/>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};
export default EmployeeProfile;
