import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import jwtDecode from 'jwt-decode';
import {
	FaUserAlt,
	FaSignOutAlt,
	FaUserCog,
	FaUserCircle,
} from 'react-icons/fa'; // Use FaSignInAlt instead of FaSign
import { getPathnameByRole, setLocalStorageItem } from '../../utils/helper';
import { NAVBAR } from '../../constants/AppConstants';
import fissionLogo from '../../assets/fission-logo.svg';
import { logOutUser } from '../../Pages/Auth/Login';
import UserLoginServices from '../../services/userLoginServices';
import './Header.css';

const Header = () => {
	const [user, setUser] = useState(localStorage.getItem('username'));
	const [userRoles, setUserRoles] = useState();
	const [userId, setUserId] = useState(localStorage.getItem('userId'));
	const loggedInAs = localStorage.getItem('role');
	const [activeRole, setActiveRole] = useState(localStorage.getItem('role')); // Default active role
	const logInServices = new UserLoginServices();
	const navigate = useNavigate();

	const getAllRoles = () => {
		const jwtDecodedToken = jwtDecode(localStorage.getItem('headerToken'));
		setUserRoles(jwtDecodedToken?.userRoles);
	};

	useEffect(() => {
		setUser(localStorage.getItem('username'));
		setUserId(localStorage.getItem('userId'));
		getAllRoles();
	}, [activeRole]);

	const switchRole = async (role) => {
		try {
			const data = {
				_id: userId,
				roleType: role || activeRole,
			};
			const res = await logInServices.switchRole(data);
			const jwtDecodedToken = jwtDecode(res?.token);
			setLocalStorageItem('headerToken', res?.token);
			setLocalStorageItem('role', jwtDecodedToken?.userRole);
			const newPathname = getPathnameByRole(jwtDecodedToken?.userRole);
			toast.success(`Switched To ${role} Role`, { autoClose: 1000 });
			// Delay page reload for 2 seconds
			setTimeout(() => {
				window.location.reload();
				window.location.href = newPathname;
			}, 2000);
		} catch (error) {
			toast.error(error?.details);
		}
	};

	// Handle role selection
	const handleRoleSelect = (role) => {
		setActiveRole(role);
		if (loggedInAs === role) {
			toast.warn('Select Another Role', { autoClose: 1000 });
			return;
		}
		switchRole(role);
	};

	return (
		<Navbar className="shadow-sm p-3 navbar" style={{ height: '8vh' }}>
			<Navbar.Brand>
				<img src={fissionLogo} alt="#" className="fission-logo ms-2" />
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse
				id="basic-navbar-nav"
				className="justify-content-end me-5"
			>
				<Nav>
					<FaUserCog className="fs-4 mt-2" />
					<NavDropdown
						className="fs-6 me-4"
						title={activeRole}
						id="switch-nav-dropdown"
					>
						{userRoles?.map((role) => (
							<NavDropdown.Item
								key={role}
								active={activeRole === role}
								onClick={() => handleRoleSelect(role)}
							>
								{role}
							</NavDropdown.Item>
						))}
					</NavDropdown>
				</Nav>
				<Nav>
					<FaUserCircle className="fs-4 mt-2" />
					<NavDropdown title={user} id="basic-nav-dropdown">
						<NavDropdown.Item
							onClick={() =>
								navigate(`/employees/employeeProfile/${userId}`, {
									state: { _id: userId },
								})
							}
						>
							<FaUserAlt className="fs-6 me-2" />
							{NAVBAR.myProfile}
						</NavDropdown.Item>
						<NavDropdown.Item onClick={() => logOutUser()}>
							<FaSignOutAlt className="fs-6 me-2" />
							{NAVBAR.logOut}
						</NavDropdown.Item>
					</NavDropdown>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default Header;
