import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
	createFeedback,
	editFeedbackFormForAdmin,
} from '../../../services/feedbackService';
import {
	FEEDBACKFORMVERSIONS,
	FEEDBACKFORM,
	CREATEFEEDBACKFORM,
	CREATEFEEDBACKFORMQUESTIONTABLEHEADER,
	CREATEFEEDBACKFORMFEEDBACKONOPTIONS,
	MOCK_QUESTIONS,
} from '../../../constants/AppConstants';
import CustomModal from '../../../common-components/CustomModal/CustomModal';
import CustomTable from '../../../common-components/CustomTable/CustomTable';
import './FeedbackForm.css';

function FeedbackForm({
	modalState,
	updateModalState,
	getAllFeedbacksList,
	selectedFeedbackFormToEdit,
	updateSelectedFeedbackFormToEdit,
}) {
	const [formState, setFormState] = useState({
		formName: {
			value: '',
			error: false,
			errorMsg: '',
		},
		formVersion: {
			value: 1,
			error: false,
			errorMsg: '',
		},
		feedbackOn: {
			value: '',
			error: false,
			errorMsg: '',
		},
		questions: {
			value: [],
			error: false,
			errorMsg: '',
		},
		newQuestion: {
			value: '',
			error: false,
			errorMsg: '',
		},
	});
	const [isEdit, setIsEdit] = useState(false);
	const updateField = (name, key, value) => {
		const copy = { ...formState[key] };
		const error = !value?.toString()?.length;
		const errorMsg = value?.toString()?.length ? '' : `${name} is required`;
		return { ...copy, value, error, errorMsg };
	};

	const handleChange = (event) => {
		const { id, value, name, checked } = event.target;
		setFormState((prevState) => ({
			...prevState,
			[id]: updateField(name, id, checked ? name : value),
		}));
	};

	const addQuestion = () => {
		const newQuestionValue = formState.newQuestion.value.trim();
		if (newQuestionValue !== '') {
			// Check if the question already exists in the questions array
			const isQuestionExists = formState.questions.value.some(
				(questionObj) => questionObj.que === newQuestionValue
			);
			if (!isQuestionExists) {
				const newQuestion = {
					que_No: formState.questions.value.length + 1,
					que: newQuestionValue,
					error: false,
				};
				setFormState((prevState) => ({
					...prevState,
					questions: {
						...prevState.questions,
						value: [...prevState.questions.value, newQuestion],
					},
					newQuestion: {
						...prevState.newQuestion,
						value: '',
					},
				}));
			} else {
				// Handle error when the question already exists
				setFormState((prevState) => ({
					...prevState,
					newQuestion: {
						...prevState.newQuestion,
						error: true,
						errorMsg: 'This question already exists!',
					},
				}));
			}
		} else {
			setFormState((prevState) => ({
				...prevState,
				newQuestion: updateField(
					'New Question',
					'newQuestion',
					newQuestionValue
				),
			}));
		}
	};

	useEffect(() => {
		if (
			selectedFeedbackFormToEdit?.feedbackOn !== formState?.feedbackOn?.value
		) {
			// if (!isEdit) {
			// Filter the questions based on the selected category
			const filteredQuestions = MOCK_QUESTIONS.filter(
				(item) => item.type === formState?.feedbackOn?.value
			);
			setFormState((prevState) => ({
				...prevState,
				questions: {
					...prevState.questions,
					value: filteredQuestions[0]?.questions,
				},
				newQuestion: {
					...prevState.newQuestion,
					value: '',
					error: false,
					errorMsg: '',
				},
			}));
		} else {
			setFormState((prevFormState) => ({
				...prevFormState,
				questions: {
					value: selectedFeedbackFormToEdit?.question
						? selectedFeedbackFormToEdit.question
						: [],
					error: false,
					errorMsg: '',
				},
			}));
		}
		// }
	}, [formState?.feedbackOn?.value]);

	// Function to reset the form state to its initial values
	const resetFormState = (selectedForm) => {
		setFormState({
			formName: {
				value: selectedForm?.formName?.length ? selectedForm.formName : '',
				error: false,
				errorMsg: '',
			},
			formVersion: {
				value: selectedForm?.formVersion?.length ? selectedForm.formVersion : 0,
				error: false,
				errorMsg: '',
			},
			questions: {
				value: selectedForm?.question ? selectedForm.question : [],
				error: false,
				errorMsg: '',
			},
			feedbackOn: {
				value: selectedForm?.feedbackOn ? selectedForm.feedbackOn : '',
				error: false,
				errorMsg: '',
			},
			newQuestion: {
				value: '',
				error: false,
				errorMsg: '',
			},
		});
	};

	useEffect(() => {
		if (modalState?.edit) {
			resetFormState(selectedFeedbackFormToEdit);
			setIsEdit(true);
		}
	}, [modalState?.edit, selectedFeedbackFormToEdit]);

	useEffect(() => {
		resetFormState();
	}, [modalState?.create]);

	const deleteQuestion = (index) => {
		const updatedQuestions = [...formState.questions.value];
		updatedQuestions.splice(index, 1);

		// Reassign question numbers after deletion
		const updatedQuestionsWithNumbers = updatedQuestions.map(
			(question, idx) => ({
				...question,
				que_No: idx + 1,
			})
		);
		setFormState((prevState) => ({
			...prevState,
			questions: {
				...prevState.questions,
				value: updatedQuestionsWithNumbers,
			},
		}));
	};

	const closeModalHandler = () => {
		updateModalState({ create: false, edit: false });
		getAllFeedbacksList();
		updateSelectedFeedbackFormToEdit({});
	};

	const handleQuestionChange = (event, index) => {
		const { value } = event.target;
		const updatedQuestions = [...formState.questions.value];
		// Check if the value is empty and update the error state
		const error = value.trim() === '';
		// Update the question in the questions array
		updatedQuestions[index].que = value;
		updatedQuestions[index].error = error;

		setFormState((prevState) => ({
			...prevState,
			questions: {
				...prevState.questions,
				value: updatedQuestions,
			},
		}));
	};

	const onValidation = () => {
		let isValid = true;
		const updatedFormState = { ...formState };
		// Validate
		Object.keys(updatedFormState).forEach((key) => {
			// Exclude specific keys
			if (key !== 'newQuestion') {
				updatedFormState[key] = updateField(
					CREATEFEEDBACKFORM[key],
					key,
					updatedFormState[key].value
				);
				if (updatedFormState[key]?.error) isValid = false;
			}
		});
		// Check if any object in questions array has error: true
		const hasError = updatedFormState.questions.value.some(
			(question) => question.error
		);
		if (hasError) {
			isValid = true; // If any question has an error, set isValid to false
		}
		setFormState(updatedFormState);
		return isValid;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const isValid = onValidation();
		if (isValid) {
			const updatedQuestionsList = formState?.questions?.value.map(
				({ ...rest }) => rest
			);
			const userId = localStorage.getItem('userId');
			let data = {
				formName: formState?.formName?.value,
				formVersion: formState?.formVersion?.value,
				question: updatedQuestionsList,
				feedbackOn: formState?.feedbackOn?.value,
			};
			if (!isEdit) {
				createFeedback(userId, data)
					.then((res) => {
						if (res?.statusCode === 200) {
							toast.success(FEEDBACKFORM.success);
						}
						resetFormState({});
						getAllFeedbacksList();
						updateModalState({ create: false, edit: false });
					})
					.catch((err) => {
						toast.error(err?.details);
					});
			} else {
				data = {
					...data,
					createdBy: userId,
				};
				editFeedbackFormForAdmin(selectedFeedbackFormToEdit?._id, data)
					.then((res) => {
						if (res?.statusCode === 200) {
							toast.success(FEEDBACKFORM.success);
						}
						resetFormState({});
						updateSelectedFeedbackFormToEdit({});
						getAllFeedbacksList();
						updateModalState({ create: false, edit: false });
					})
					.catch((err) => {
						toast.error(err?.details);
					});
			}
		}
	};

	const header = CREATEFEEDBACKFORMQUESTIONTABLEHEADER?.map((head, index) => (
		<th className="text-secondary" key={index}>
			{head}
		</th>
	));
	return (
		<>
			<CustomModal
				modalState={modalState?.create || modalState?.edit}
				closeModalHandler={closeModalHandler}
				size={'lg'}
				modalHeader={'Feedback Form'}
			>
				<div className="col-12 row">
					<div className="col-6 mb-3">
						<label className="inputLabel">
							{CREATEFEEDBACKFORM.formName}
							<span className="error">*</span>
						</label>
						<input
							type="text"
							className={`styled-input ${
								formState?.formName?.error ? 'error' : ''
							}`}
							placeholder="Enter Form Name"
							name="Form Name"
							id="formName"
							required
							value={formState.formName.value}
							onChange={handleChange}
							autoComplete="off"
						/>
						<p className="errorText">
							{formState.formName.error ? formState.formName.errorMsg : ''}
						</p>
					</div>
					<div className="col-6 mb-3">
						<label className="inputLabel">
							{CREATEFEEDBACKFORM.formVersion}
							<span className="error">*</span>
						</label>
						<select
							className={`styled-input ${
								formState?.formVersion?.error ? 'error' : ''
							}`}
							name="Form Version"
							id="formVersion"
							required
							value={formState?.formVersion?.value}
							onChange={handleChange}
						>
							<>
								<option disabled value={''}>
									Select
								</option>
								{FEEDBACKFORMVERSIONS.map((version) => (
									<option key={version} value={version}>
										{version}
									</option>
								))}
							</>
						</select>
						<p className="errorText">
							{formState?.formVersion?.error
								? formState.formVersion.errorMsg
								: ''}
						</p>
					</div>
					<div className="mb-3">
						<Form.Label className="inputLabel">
							{CREATEFEEDBACKFORM.feedbackOn}
							<span className="error">*</span>
						</Form.Label>
						<div className="d-flex justify-content-evenly">
							{CREATEFEEDBACKFORMFEEDBACKONOPTIONS.map((option) => (
								<Form.Check
									key={option.value}
									inline
									type="radio"
									className={`${formState?.feedbackOn?.error ? 'error' : ''}`}
									label={option.label}
									name={option?.label}
									id="feedbackOn"
									checked={formState?.feedbackOn?.value === option?.label}
									onChange={handleChange}
								/>
							))}
						</div>
						<p className="errorText">
							{formState?.feedbackOn?.error
								? formState?.feedbackOn?.errorMsg
								: ''}
						</p>
					</div>
				</div>
				<div className="mb-3 d-flex justify-content-start gap-3">
					<div className="col-6">
						<label className="inputLabel">
							{CREATEFEEDBACKFORM.questions}
							<span className="error">*</span>
						</label>
						<input
							type="text"
							className={`styled-input ${
								formState?.newQuestion?.error ? 'error' : ''
							}`}
							placeholder="Enter A Question"
							name="New Question"
							id="newQuestion"
							required
							value={formState.newQuestion.value}
							onChange={handleChange}
							autoComplete="off"
						/>
						<p className="errorText">
							{formState.newQuestion.error
								? formState.newQuestion.errorMsg
								: ''}
						</p>
						<p className="errorText">
							{formState.questions.error ? formState.questions.errorMsg : ''}
						</p>
					</div>
					<div>
						<button className="btnStyle p-2 mt-4" onClick={addQuestion}>
							Add Question
						</button>
					</div>
				</div>
				<CustomTable header={header} className={'w-100'}>
					{formState?.questions?.value?.length === 0 ? (
						<tr>
							<td colSpan={3} className="text-center text-muted">
								(No questions have been added. Click &quot;Add Question&quot; to
								include a question.)
							</td>
						</tr>
					) : (
						formState?.questions?.value?.map((question, index) => (
							<>
								<tr key={index} className={question?.error ? 'rowError' : ''}>
									<td className="text-center">{question?.que_No}.</td>
									<td>
										<Form.Control
											type="text"
											placeholder={'Enter some value'}
											value={question?.que}
											onChange={(event) => handleQuestionChange(event, index)}
										/>
									</td>
									<td className="text-center">
										<Button
											variant="outline-danger"
											size="sm"
											onClick={() => deleteQuestion(index)}
										>
											Delete
										</Button>
									</td>
								</tr>
							</>
						))
					)}
				</CustomTable>
				<div className="d-flex mt-4 justify-content-end">
					<button
						className="positiveButton"
						type="submit"
						onClick={handleSubmit}
					>
						Submit
					</button>
					<button className="negativeButton mx-2" onClick={closeModalHandler}>
						Cancel
					</button>
				</div>
			</CustomModal>
		</>
	);
}

export default FeedbackForm;
