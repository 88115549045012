import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserContext from './userContext';

const UserState = ({ children }) => {
	const [userLoggedIn, setUserLoggedIn] = useState(
		JSON.parse(
			localStorage.getItem('accessToken') || localStorage.getItem('loginnormal')
		)
	);

	const [userRole, setUserRole] = useState(localStorage.getItem('role'));
	return (
		<UserContext.Provider
			value={{ userLoggedIn, setUserLoggedIn, userRole, setUserRole }}
		>
			{children}
		</UserContext.Provider>
	);
};

UserState.propTypes = {
	children: PropTypes.node.isRequired,
};

export default UserState;
